export const GET_TEMPLATE_QUERY = `
query getTemplate($input: String!) {
  getTemplate(id: $input) {
    id
    name
    description
    goal
    language
    imageId
    actions {
      id
      name
      days
    }
    templateImage {
      id
      path
    }
      streamType
  }
}
`;
