import uuid from "react-uuid";

let db;
export const indexDB = async () => {
  function indexedDBSupport() {
    return "indexedDB" in window;
  }

  if (!indexedDBSupport())
    throw new Error("Your browser doesn't support IndexedBD");

  const request = window.indexedDB.open("RequestDatabase", 1);

  // Event handling
  request.onerror = (e) => {
    // console.error(`IndexedDB error: ${request.errorCode}`);
  };

  request.onsuccess = (e) => {
    db = request.result;
  };

  request.onupgradeneeded = (e) => {
    const db = request.result;
    const objectStore = db.createObjectStore("request", { keyPath: "id" });

    // Indexes
    objectStore.createIndex("id", "id", { unique: true });
    objectStore.createIndex("date", "date", { unique: false });
    objectStore.createIndex("path", "path", { unique: false });
    objectStore.createIndex("time", "time", { unique: false });

    // Transaction completed
    objectStore.transaction.oncompleted = (e) => {
      console.log('Object store "request" created');
    };
  };
};

export function getAllErrors(tableName) {
  return db.transaction(tableName).objectStore(tableName).getAll();
}

export function addRequest(req, time) {
  const transaction = db.transaction("request", "readwrite");

  transaction.oncomplete = function (event) {
    // console.log("All the req added successfully");
  };

  transaction.onerror = function (event) {
    //...
  };
  const objectStore = transaction.objectStore("request");
  const countRequest = objectStore.count();

  countRequest.onsuccess = function () {
    if (countRequest?.result > 49) {
      const errors = getAllErrors("request");
      errors.onsuccess = () => {
        emptyStoreAfterFifty(errors.result[errors.result.length - 1].id);
      };
    }
  };

  objectStore.add({
    id: uuid(),
    date: new Date(),
    path: req.request.responseURL,
    time,
  });
}

function emptyStoreAfterFifty(id) {
  const transaction = db.transaction("request", "readwrite");
  transaction.objectStore("request").delete(id);
}

export function emptyStore(storeName) {
  const request = db
    .transaction(storeName, "readwrite")
    .objectStore(storeName)
    .clear();

  request.onsuccess = () => {
    // console.log(`Object Store "${storeName}" emptied`);
  };

  request.onerror = (err) => {
    console.error(`Error to empty Object Store: ${storeName}`);
  };
}
