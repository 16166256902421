export const GET_INITIATIVES_GRID_QUERY = `query getInitiativeGrid($input: GetInitiativeGridInput!, $filter: GetInitiativeFilterInput!) {
  getInitiativeGrid(GetInitiativeGridInput: $input, filter: $filter) {
    initiatives {
      id
      name
      description
      year
      status
      createdAt
      owner {
        emailAddress
        color
        username
        userID
      }
      people {
        emailAddress
        color
        userID
        username
      }
    }
  }
}
`;
