export const GET_IDEATION_QUERY = `query getIdeation($input: String!) {
    getIdeation(id: $input) {
        id
        createdAt
        updatedAt
        introduction
        name
        participants
        votesPerParticipant
        allowedActions
        status
        logo
        actions
        allowIdeas
        allowIdeasComments
        allowDefaultIdeas
        questions {
            id
            question
            ideas {
                id
                text
            }
        }
        url
        actionList {
            id
        }
    }
}
`;
