import React, { useState } from "react";
import { ReactComponent as UnLock } from "../../../../Assets/Icons/Unlock.svg";
import { ReactComponent as RightArrow } from "../../../../Assets/Icons/RightArrow.svg";
import ProductSwitcherMenuHoveredItem from "../menu-hovered-item/ProductSwitcherMenuHoveredItem";
import { productSwitcherColors } from "../../../../Constants/ProductSwitcherColors";

const ProductSwitcherMenuItem = ({ menuItem }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <li
      className={"product-switcher--item"}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        className={"product-switcher--item--content"}
        style={
          hovered
            ? { borderColor: productSwitcherColors[menuItem?.name] }
            : { borderColor: "transparent" }
        }
      >
        <div className={"product-switcher--item--content--block"}>
          <div>
            <img
              src={menuItem?.logo}
              alt={menuItem?.name}
              width={40}
              height={40}
            />
          </div>
          <div className={"product-switcher--item--content--name-desc"}>
            <p className={"product-switcher--item--name"}>{menuItem?.name}</p>
            <p className={"product-switcher--item--desc"}>{menuItem?.desc}</p>
          </div>
        </div>
        {menuItem?.active ? (
          <RightArrow width={6} className={"product-switcher--item--arrow"} />
        ) : (
          <UnLock />
        )}
      </div>

      {menuItem?.active && (
        <ul className={"product-switcher--hover--item"}>
          {menuItem?.products.map((item, key) => {
            return (
              <ProductSwitcherMenuHoveredItem
                parentItem={menuItem}
                item={item}
                key={key}
              />
            );
          })}
        </ul>
      )}
    </li>
  );
};

export default ProductSwitcherMenuItem;
