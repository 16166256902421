import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { strongCopyData } from "../../../Utils";
import { addRequest } from "../../../IndexDB/IndexDB";
import { PerPageThen } from "../../../Constants/PerPage";

const initialState = {
  tasks: [],
  tasksCount: 0,
  tasksPage: 1,
  tasksLoading: true,
  error: {},
};

export const getTaskAsync = createAsyncThunk(
  "task/get",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const lang = state.getState().user?.selectedLanguage;
    const startTime = new Date().getTime();
    const response = await axios.get(
      `${BASE_URL}/tasks/${action.initID}/initiative?page=${action.page}&perPage=${PerPageThen}`,
      {
        headers: {
          "x-workforce": workforceId,
          "x-lang": lang,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const bulkCreateTaskAsync = createAsyncThunk(
  "bulkTask/create",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const lang = state.getState().user?.selectedLanguage;
    const startTime = new Date().getTime();
    const response = await axios.post(
      `${BASE_URL}/tasks/bulk-create`,
      { ...action },
      {
        headers: {
          "x-workforce": workforceId,
          "x-lang": lang,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);
export const createTaskAsync = createAsyncThunk(
  "task/create",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const lang = state.getState().user?.selectedLanguage;
    const startTime = new Date().getTime();
    const response = await axios.post(
      `${BASE_URL}/tasks`,
      { ...action },
      {
        headers: {
          "x-workforce": workforceId,
          "x-lang": lang,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);
export const updateTaskAsync = createAsyncThunk(
  "task/update",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const lang = state.getState().user?.selectedLanguage;
    const startTime = new Date().getTime();
    const response = await axios.patch(
      `${BASE_URL}/tasks/${action.id}`,
      { ...action.task },
      {
        headers: {
          "x-workforce": workforceId,
          "x-lang": lang,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);
export const deleteTaskAsync = createAsyncThunk(
  "task/delete",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const lang = state.getState().user?.selectedLanguage;
    const startTime = new Date().getTime();
    const response = await axios.post(
      `${BASE_URL}/tasks/empty-tasks`,
      {
        ids: action.ids,
      },
      {
        headers: {
          "x-workforce": workforceId,
          "x-lang": lang,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const changeTaskStatus = createAsyncThunk(
  "taskStatus/post",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.patch(
      `${BASE_URL}/tasks/status/${action.taskID}`,
      {
        status: action.status,
      },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const dragTaskAsync = createAsyncThunk(
  "dragTask/patch",
  async (action, state) => {
    const startTime = new Date().getTime();
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const response = await axios.patch(
      `${BASE_URL}/tasks/priorityIndex/${action.id}`,
      action,
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    resetTask: () => initialState,
    updateCurrentTask: (state, action) => {
      const index = state.tasks.findIndex(
        (item) => item.id === action.payload.id
      );
      state.tasks[index] = action.payload;
    },
    dragTaskList: (state, action) => {
      state.tasks = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaskAsync.pending, (state, action) => {
        state.error = {};
        state.tasksLoading = true;
      })
      .addCase(getTaskAsync.fulfilled, (state, action) => {
        state.tasks = action.payload?.tasks;
        state.tasksCount = action.payload?.count;
        state.tasksPage = action.payload?.page;
        state.tasksLoading = false;
      })
      .addCase(getTaskAsync.rejected, (state, action) => {
        state.tasksLoading = false;
        state.error.tasks = "Something went wrong";
      })

      .addCase(createTaskAsync.fulfilled, (state, action) => {
        state.tasks = [action.payload.data, ...state.tasks];
        if (state.tasks.length > PerPageThen) {
          state.tasks.pop();
        }
        state.tasksCount += 1;
      })
      .addCase(bulkCreateTaskAsync.fulfilled, (state, action) => {
        state.tasks = [...action.payload.data, ...state.tasks];
        if (state.tasks.length > PerPageThen) {
          state.tasks.slice(0, -action.payload.data.length);
        }
        state.tasksCount += action.payload.data.length;
      })

      .addCase(changeTaskStatus.fulfilled, (state, action) => {
        const index = state.tasks.findIndex(
          (task) => task.id === action.payload.data.id
        );
        state.tasks[index] = action.payload.data;
      })
      .addCase(deleteTaskAsync.fulfilled, (state, action) => {
        let tasksCopy = strongCopyData(state.tasks);

        state.tasks = tasksCopy.filter(
          (task) => task.id !== action.payload.ids.find((el) => el === task.id)
        );
        state.tasksCount -= 1;
      });
  },
});

export const { resetTask, updateCurrentTask, dragTaskList } =
  tasksSlice.actions;
//Selectors
export const getTasks = (state) => state.tasks.tasks;

export const getTaskPage = (state) => state.tasks.tasksPage;
export const getTasksCount = (state) => state.tasks.tasksCount;
export const getTasksLoading = (state) => state.tasks.tasksLoading;
export const getTaskError = (state) => state.tasks.error;

export default tasksSlice.reducer;
