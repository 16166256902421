export const TEAMSVIEW_GET_USERINITIATIVES_QUERY = `query userInitiatives($input: TeamsViewInput!) {
  userInitiatives(input: $input) {
    initiatives {
      id
      name
      status
      createdAt
      taskCount
      owner {
        userID
        username
        color
        emailAddress
      }
      people {
        userID
        username
        color
        emailAddress
      }
    }
    count {
      status
      total
    }
    totalInitiativeCount
  }
}
`;
