export const GET_ME_QUERY = `

query getMe {
  getMe {
    panelId
    userId
    companyName
    firstname
    lastname
    emailAddress
    language
    panelTheme
    companyName
    isAdminMember
    initials
    profilePic
    languageCode
    userId
    primaryUserAPIKey
    orgID
    userAPIKey
    username
    workforces {
        workforceID
        workforceName
        surveyCount
        shortURL
    }
    lastVisitedWorkforceID
    emailDisabled
    workforceName
  }
}
`;
