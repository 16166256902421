import React, { useState } from "react";
import "./IdeationResultsComments.scss";
import { useTranslation } from "react-i18next";

const IdeationResultsComments = ({ idea }) => {
  const { t } = useTranslation();
  const [isOpenComments, setIsOpenComments] = useState(false);

  return (
    <div className={"ideation--comments"}>
      <button
        className={`ideation--comments--btn ${
          isOpenComments
            ? "ideation--comments--btn--open"
            : "ideation--comments--btn--close"
        }`}
        onClick={() =>
          idea?.comments.length > 0 ? setIsOpenComments(!isOpenComments) : {}
        }
      >
        {idea?.comments.length}{" "}
        {idea?.comments.length > 1
          ? `${t("comments", { ns: ["ideation"] })}`
          : `${t("comment", { ns: ["ideation"] })}`}
      </button>

      <div
        className={`ideation--comments--comments-block ${
          isOpenComments
            ? "ideation--comments--comments-block--open"
            : "ideation--comments--comments-block--close"
        }`}
      >
        {idea?.comments.map((comment) => (
          <p key={comment.id} className={"ideation--comments--comment"}>
            {comment.comment}
          </p>
        ))}
      </div>
    </div>
  );
};

export default IdeationResultsComments;
