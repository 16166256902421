import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import { ReactComponent as NotFound } from "../../Assets/Icons/NotFound.svg";
import { useTranslation } from "react-i18next";

const NotfoundPage = ({ error }) => {
  const { t } = useTranslation();

  return (
    <div className={"not-found"} data-testid="not-found-block">
      <NotFound />
      <p className={"not-found--title"}>
        {error || t("somethingWentWrong", { ns: ["global"] })}
      </p>
      <p className={"not-found--sub-title"}>{t("404", { ns: ["global"] })}</p>
      <Link
        className={"not-found--link"}
        to="/"
        onClick={() => localStorage.removeItem("pathname")}
      >
        <p>{t("goHome", { ns: ["global"] })}</p>
      </Link>
    </div>
  );
};

export default NotfoundPage;
