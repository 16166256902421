import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { strongCopyData } from "../../../Utils";
import { PerPageThen } from "../../../Constants/PerPage";
import deepcopy from "deepcopy";
import { GET_HUDDLES_QUERY } from "../../../gql/graphqlQuery/get-huddles.generated";
import { CREATE_HUDDLE_MUTATION } from "../../../gql/graphqlQuery/create-huddle.generated";
import { UPDATE_HUDDLE_MUTATION } from "../../../gql/graphqlQuery/update-huddle.generated";
import { DELETE_HUDDLE_MUTATION } from "../../../gql/graphqlQuery/delete-huddle.generated";
import graphqlRequest from "../../../graphqlClient";

const initialState = {
  huddles: [],
  huddleLoading: true,
  selectHuddle: null,
  huddlesCount: 0,
  newHuddle: false,
  searchValue: "",
  edit: false,
  clearEditor: true,
  isRequestSuccesses: null,
  huddlesError: "",
};

export const huddleSortAsync = createAsyncThunk(
  "huddle/huddleSort",
  async (action, state) => {
    const variables = {
      input: {
        initiativeId: action.id,
        limit: PerPageThen,
        offset: (action.page - 1) * PerPageThen,
        filterInput: { sort: action?.sort },
      },
    };
    const response = await graphqlRequest(GET_HUDDLES_QUERY, variables);
    return response.getHuddles;
  }
);

export const huddleSearchAsync = createAsyncThunk(
  "huddle/huddleSearch",
  async (action, state) => {
    const variables = {
      input: {
        initiativeId: action.id,
        limit: PerPageThen,
        offset: (action.page - 1) * PerPageThen,
        filterInput: { sort: action?.sort, search: action.searchTerm },
      },
    };
    const response = await graphqlRequest(GET_HUDDLES_QUERY, variables);
    return response.getHuddles;
  }
);

export const createHuddleAsync = createAsyncThunk(
  "huddle/createByNewHuddle",
  async (action, state) => {
    const variables = {
      input: { ...action.initialHuddle },
    };
    const response = await graphqlRequest(CREATE_HUDDLE_MUTATION, variables);
    return {
      data: response.createHuddle,
    };
  }
);

export const updateHuddleAsync = createAsyncThunk(
  "huddle/update",
  async (_, state) => {
    var huddle = { ...state.getState().huddle.selectHuddle };
    delete huddle.createdAt;
    delete huddle.updatedAt;
    const variables = {
      input: { ...huddle },
    };
    if (huddle.id) {
      const response = await graphqlRequest(UPDATE_HUDDLE_MUTATION, variables);
      return {
        data: response.updateHuddle,
        // type,
      };
    }
    return null;
  }
);

export const deleteHuddleAsync = createAsyncThunk(
  "huddle/delete",
  async (action, state) => {
    const variables = {
      input: {
        id: action.id,
      },
    };
    const response = await graphqlRequest(DELETE_HUDDLE_MUTATION, variables);
    return response.deleteHuddle;
  }
);

const huddleSlice = createSlice({
  name: "huddles",
  initialState,
  reducers: {
    resetHuddles: () => initialState,

    addNewHuddle(state, action) {
      state.clearEditor = !state.clearEditor;
      state.selectHuddle = action.payload;
      state.newHuddle = true;
    },
    removeNewHuddle(state, action) {
      const selectNewHuddle = deepcopy(state.selectHuddle);
      if (selectNewHuddle && action.payload) {
        const newHuddles = strongCopyData(state.huddles).slice(0, 9);
        state.huddles = [selectNewHuddle, ...newHuddles];
        state.huddlesCount += 1;
      }
      state.newHuddle = false;
      state.selectHuddle = null;
    },
    selectCurrentHuddle(state, action) {
      state.selectHuddle = action.payload;
    },
    selectHuddlePeople(state, action) {
      state.selectHuddle.people = action.payload;
    },
    selectHuddleText(state, action) {
      if (state.selectHuddle) {
        state.selectHuddle.descriptionHTML = action.payload;
      }
    },
    changeSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    emptyIsRequestSuccesses(state) {
      state.isRequestSuccesses = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(huddleSortAsync.pending, (state, action) => {
        state.huddleLoading = true;
        state.huddlesError = "";
      })
      .addCase(huddleSortAsync.fulfilled, (state, action) => {
        state.huddlesCount = action.payload?.count;
        state.huddles = action.payload?.huddles;
        state.huddleLoading = false;
      })
      .addCase(huddleSortAsync.rejected, (state, action) => {
        state.huddleLoading = false;
        state.huddlesError = "Something went wrong";
      })

      .addCase(huddleSearchAsync.pending, (state, action) => {
        state.huddleLoading = true;
      })
      .addCase(huddleSearchAsync.fulfilled, (state, action) => {
        state.huddlesCount = action.payload?.count;
        state.huddles = action.payload?.huddles;
        state.huddleLoading = false;
      })
      .addCase(huddleSearchAsync.rejected, (state, action) => {
        state.huddleLoading = false;
      })
      .addCase(createHuddleAsync.fulfilled, (state, action) => {
        state.selectHuddle = action.payload?.data;
      })
      .addCase(updateHuddleAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const index = state.huddles.findIndex(
            (item) => item.id === action.payload?.data.id
          );
          state.huddles[index] = action.payload?.data;
        }
      })
      .addCase(deleteHuddleAsync.fulfilled, (state, action) => {
        const huddles = deepcopy(state.huddles);
        state.huddles = huddles.filter(
          (huddle) => huddle.id !== action.meta.arg.id
        );
        state.isRequestSuccesses = true;
      })
      .addCase(deleteHuddleAsync.rejected, (state, action) => {
        state.isRequestSuccesses = false;
      });
  },
});

export const {
  resetHuddles,
  addNewHuddle,
  removeNewHuddle,
  selectCurrentHuddle,
  selectHuddlePeople,
  selectHuddleText,
  changeSearchValue,
  emptyIsRequestSuccesses,
} = huddleSlice.actions;

//Selectors
// export const getHuddles = (state) => state.huddle;
export const getHuddles = (state) => state.huddle.huddles;

export const getHuddleLoading = (state) => state.huddle.huddleLoading;

export const getHuddlesAllCount = (state) => state.huddle.huddlesCount;

export const getNewHuddle = (state) => state.huddle.newHuddle;

export const getSelectHuddle = (state) => state.huddle.selectHuddle;

export const getClearEditor = (state) => state.huddle.clearEditor;

export const getSearchValue = (state) => state.huddle.searchValue;

export const getIsRequestSuccesses = (state) => state.huddle.isRequestSuccesses;

export const getHuddlesError = (state) => state.huddle.huddlesError;

export default huddleSlice.reducer;
