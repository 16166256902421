import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET_ORGANIZATION_SETTINGS_QUERY } from "../../../gql/graphqlQuery/get-organization-settings.generated";
import { CREATE_GENERAL_GOAL_MUTATION } from "../../../gql/graphqlQuery/create-general-goal.generated";
import { UPDATE_GENERAL_GOAL_MUTATION } from "../../../gql/graphqlQuery/update-general-goal.generated";
import { DELETE_GENERAL_GOAL_MUTATION } from "../../../gql/graphqlQuery/delete-general-goal.generated";
import { UPDATE_ORGANIZATION_SETTINGS_MUTATION } from "../../../gql/graphqlQuery/update-organization-settings.generated";
import graphqlRequest from "../../../graphqlClient";

const initialState = {
  settings: {},
  settingsLoading: true,
  settingsError: "",

  goals: [],
  goalsLoading: true,
  goalsCount: 0,
  goalsError: "",
};

export const getSettingsAsync = createAsyncThunk(
  "setting/get",
  async (action, state) => {
    try {
      const response = await graphqlRequest(GET_ORGANIZATION_SETTINGS_QUERY);
      return response.getOrganizationSettings;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const updateSettingsAsync = createAsyncThunk(
  "setting/post",
  async (payload, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const orgID = state.getState().user.user.orgID;
    const variables = {
      data: { workforceID: workforceId, orgID, ...payload },
    };
    const response = await graphqlRequest(
      UPDATE_ORGANIZATION_SETTINGS_MUTATION,
      variables
    );
    return response.createOrUpdateSettings;
  }
);

export const createGoalAsync = createAsyncThunk(
  "createGoal/post",
  async (action, state) => {
    try {
      const orgID =
        (!action?.generalGoal && state.getState().user.user.orgID) || null;
      const variables = {
        data: {
          goal: action.goal,
          orgID,
        },
      };
      const response = await graphqlRequest(
        CREATE_GENERAL_GOAL_MUTATION,
        variables
      );

      return response.createGoal;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const deleteGoalAsync = createAsyncThunk(
  "deleteGoal/delete",
  async (action, state) => {
    const variables = {
      id: action.goalID,
    };
    const response = await graphqlRequest(
      DELETE_GENERAL_GOAL_MUTATION,
      variables
    );
    return response.removeGoal;
  }
);

export const updateGoalAsync = createAsyncThunk(
  "updateGoal/patch",
  async (action, state) => {
    const variables = {
      data: {
        id: action.goalID,
        goal: action.goal,
      },
    };
    const response = await graphqlRequest(
      UPDATE_GENERAL_GOAL_MUTATION,
      variables
    );
    return response.data;
  }
);

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    resetSettings: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSettingsAsync.pending, (state, action) => {
        state.goalsError = "";
        state.goalsLoading = true;
        state.settingsLoading = true;
      })
      .addCase(getSettingsAsync.fulfilled, (state, action) => {
        state.settings = {
          errorPeriod: action?.payload?.errorPeriod,
          warningPeriod: action?.payload?.warningPeriod,
        };
        state.goals = action.payload.goals;
        state.goalsLoading = false;
        state.settingsLoading = false;
      })
      .addCase(getSettingsAsync.rejected, (state, action) => {
        state.goalsError = `${action?.payload?.statusCode} - ${action?.payload?.message}`;
        state.settingsError = `${action?.payload?.statusCode} - ${action?.payload?.message}`;
        state.goalsLoading = false;
        state.settingsLoading = false;
      })
      .addCase(createGoalAsync.fulfilled, (state, action) => {
        state.goals = [action.payload, ...state.goals];
      })
      .addCase(deleteGoalAsync.fulfilled, (state, action) => {
        state.goals = state.goals.filter(
          (goal) => goal.id !== action.payload.id
        );
      });
  },
});

export const { resetSettings } = settingSlice.actions;

//Selectors
export const getSettings = (state) => state.setting.settings;
export const getSettingsLoading = (state) => state.setting.settingsLoading;
export const getSettingsError = (state) => state.setting.settingsError;

export const getGoals = (state) => state.setting.goals;
export const getGoalsLoading = (state) => state.setting.goalsLoading;
export const getGoalsError = (state) => state.setting.goalsError;

export const getTags = (state) => state.setting.tags;
export const getTagsLoading = (state) => state.setting.tagsLoading;
export const getTagsError = (state) => state.setting.tagsError;

export default settingSlice.reducer;
