import Evaporate from "evaporate";
import AWS from "aws-sdk";
import { AWS_BUCKET, AWS_KEY, AWS_REGION, AWS_SINGER } from "../Constants/Api";

async function uploadDocumentWithSignerToAWS(options = {}) {
  const { filePrefix, withUserFullName, callback } = options;
  let { file } = options;
  const config = {
    signerUrl: `${AWS_SINGER}`,
    aws_key: `${AWS_KEY}`,
    bucket: `${AWS_BUCKET}`,
    awsRegion: `${AWS_REGION}`,
    cloudfront: true,
    computeContentMd5: true,
    cryptoMd5Method: (data) => AWS.util.crypto.md5(data, "base64"),
    cryptoHexEncodedHash256: (data) => AWS.util.crypto.sha256(data, "hex"),
  };
  const fileNameParams = file.name.split(".");
  const extension = fileNameParams.pop();
  const timestamp = Date.now();

  let fileName = `${
    filePrefix ? `${filePrefix}-` : "file-"
  }${timestamp}.${extension}`;

  if (withUserFullName) {
    fileName = `${
      filePrefix ? `${filePrefix}-` : ""
    }${withUserFullName}.${extension}`;
  }

  let type = "application/" + file.type.split("/").slice(-1)[0];
  if (type === "application/pdf") {
    type = "octet-stream";
  }

  if (type === "application/svg+xml") {
    type = "image/svg+xml";
  }

  const properties = {
    name: `${fileName}`,
    file,
    contentType: `${type}`,
    xAmzHeadersAtInitiate: { "x-amz-acl": "public-read" },
  };

  const evaporate = await Evaporate.create(config);
  await evaporate.add({
    ...properties,
    complete: (_xhr) => {
      const { responseURL } = _xhr;
      const [URL] = responseURL.split("?");
      if (typeof callback === "function") {
        callback(URL);
      }
    },
    error: (err) => {
      console.log("ERROR >>> ", err);
    },
    warn: (err) => {
      console.log("WARNING >>> ", err);
    },
    // progress: (progressValue, stats) => {
    //   const progress = Math.round(
    //     ((stats.fileSize - stats.remainingSize) * 100) / stats.fileSize
    //   );
    //   uploadProgress({ progress, name: file.name });
    // },
  });
}

export async function uploadDocumentToAWS(options = {}) {
  const { uploadProgress, ...rest } = options;
  let dir = [];

  dir = dir.join("/");
  return new Promise((resolve) => {
    const OPTIONS = {
      ...rest,
      dir,
      callback: (url) => {
        const fileName = url.split("/").pop();
        resolve({ url, fileName });
      },
    };

    uploadDocumentWithSignerToAWS(OPTIONS, uploadProgress);
  });
}
