export const UPDATE_TEMPLATE_MUTATION = `mutation updateTemplate($input: UpdateTemplateInput!) { 
    updateTemplate(updateTemplateInput: $input) { 
        id 
        language 
        name 
        orgID 
        goal 
        imageId
        description
        streamType
    } 
}
`;
