import React, { useMemo } from "react";
import "./InitiativeDesktopBar.scss";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getCurrentUser } from "../../../Store/slices/user";
import { ReactComponent as Home } from "../../../Assets/Icons/Home.svg";
import { ReactComponent as Analytics } from "../../../Assets/Icons/Analytics.svg";
import { ReactComponent as Admin } from "../../../Assets/Icons/Admin.svg";
import { ReactComponent as Setting } from "../../../Assets/Icons/Setting.svg";
import { ReactComponent as Initiatives } from "../../../Assets/Icons/Initiatives.svg";
import { useTranslation } from "react-i18next";

const InitiativeDesktopBar = () => {
  const user = useSelector(getCurrentUser);
  const { t } = useTranslation();

  const topTabs = useMemo(
    () => [
      {
        title: `${t("home", { ns: ["global"] })}`,
        to: `/home`,
        icon: <Home width={16} height={16} className={"panel-icon"} />,
      },
      {
        title: `${t("initiatives", { ns: ["global"] })}`,
        to: `/initiatives`,
        icon: <Initiatives width={16} height={16} className={"panel-icon"} />,
      },
      {
        title: `${t("analytics", { ns: ["global"] })}`,
        to: `/analytics`,
        icon: <Analytics width={16} height={16} className={"panel-icon"} />,
      },
    ],
    [t]
  );

  const bottomTabs = useMemo(
    () => [
      {
        title: `${t("admin", { ns: ["global"] })}`,
        to: `/admin/?templates`,
        icon: <Admin width={16} height={16} className={"panel-icon"} />,
        isHidden: !user.isAdmin,
      },

      {
        title: `${t("settings", { ns: ["global"] })}`,
        to: `/settings?settings`,
        icon: <Setting width={16} height={16} className={"panel-icon"} />,
        isHidden: false,
      },
    ],
    [t, user.isAdmin]
  );

  return (
    <div className="initiative-bar-container">
      <div>
        {topTabs.map((tab) => (
          <NavLink
            key={tab.to}
            className={`initiative-bar-container-navLink`}
            to={tab.to}
          >
            <div className="initiative-bar-container-icon">{tab.icon}</div>
            <div className="initiative-bar-container-title">{tab.title}</div>
          </NavLink>
        ))}
      </div>

      <div>
        {bottomTabs.map((tab) => {
          if (tab.isHidden) {
            return null;
          }
          return (
            <NavLink
              key={tab.to}
              className={`initiative-bar-container-navLink`}
              to={tab.to}
            >
              <div className="initiative-bar-container-icon">{tab.icon}</div>
              <div className="initiative-bar-container-title">{tab.title}</div>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default InitiativeDesktopBar;
