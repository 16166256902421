export const GET_HUDDLES_QUERY = `query getHuddles($input: GetHuddlesInput!) {
    getHuddles(getHuddlesInput: $input) {
        huddles {
            id
            createdAt
            updatedAt
            descriptionHTML
            description
            people {
                emailAddress
                color
                userID
                username
            }
        }
        count
    }
}
`;
