import React, { useEffect, useState } from "react";
import "./style.scss";
import { Dropdown, DropdownOption } from "@questionprodev/shared-components";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../../Assets/Icons/Close.svg";

export const DropdownComponent = ({
  value,
  data,
  select,
  status,
  isBorderBottom = true,
  isIcon = true,
  multiCount = false,
  disabled = false,
  disabledSelect = false,
  id = "dropdown",
  onReset,
  children,
  childrenHasValue = false,
  defaultValue,
  testId = "",
  extraProp = false,
  index,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(defaultValue);

  const huddleClick = (item) => {
    if (extraProp) select(item, index);
    else select(item);
    if (!disabled && !disabledSelect) {
      setCurrentValue(
        item?.title ||
          item?.name ||
          item?.value ||
          item?.workforceName ||
          item?.goal ||
          item?.status ||
          item?.type
      );
    }
    setIsOpen(false);
  };

  const resetClose = () => {
    onReset();
    setIsOpen(false);
    if (!disabled && !disabledSelect) {
      setCurrentValue();
    }
  };

  const openDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    if (childrenHasValue) {
      setIsOpen(false);
    }
  }, [childrenHasValue, value]);

  useEffect(() => {
    if (!defaultValue) {
      setCurrentValue("");
    }
  }, [defaultValue]);

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div
        data-testid={testId}
        id={id}
        className={
          isBorderBottom
            ? "dropdown-content"
            : "dropdown-content borderBottom-none"
        }
      >
        <Dropdown
          value={[value || currentValue]}
          open={isOpen}
          isIcon={isIcon}
          onClick={openDropdown}
          className={children ? "custom-child" : ""}
        >
          {data?.length ? (
            data.map((item, index) => {
              return item?.NPS === null ? null : (
                <DropdownOption
                  key={index}
                  onClick={() => huddleClick(item)}
                  id={item.value?.toString().toLowerCase()}
                >
                  {status === "init-status" ? (
                    <div className={"dropdown-status-block"}>
                      <div
                        className={`status-icon  ${item?.value?.toLowerCase()}-status`}
                      />
                      <span>{item?.name}</span>
                    </div>
                  ) : (
                    <>
                      {multiCount ? (
                        <div className={"dropdown-content--multi-count"}>
                          <span>
                            {item?.name}
                            {`(${item?.combination})`}
                          </span>
                          <span>{item?.count}</span>
                        </div>
                      ) : (
                        <div>
                          {item?.firstName ? (
                            <>{`${item?.username || item?.emailAddress}`}</>
                          ) : (
                            <>
                              {item?.title ||
                                item?.name ||
                                item?.value ||
                                item?.goal ||
                                item?.username ||
                                item?.emailAddress ||
                                item?.workforceName ||
                                item?.type ||
                                (item?.date &&
                                  moment(item?.date).format("MMMM YYYY"))}
                            </>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </DropdownOption>
              );
            })
          ) : (
            <DropdownOption>
              {children || <span>{t("noData", { ns: ["global"] })}</span>}
            </DropdownOption>
          )}
        </Dropdown>
        {onReset && (currentValue || childrenHasValue) && (
          <CloseIcon
            width={10}
            height={10}
            className={"close"}
            onClick={resetClose}
          />
        )}
      </div>
    </OutsideClickHandler>
  );
};
