import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/IndexDB";

const initialState = {
  statusSummary: null,
  statusSummaryLoading: false,
  statusSummaryError: "",
};

export const getStatusSummaryAsync = createAsyncThunk(
  "statusSummary/post",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const lang = state.getState().user?.selectedLanguage;
    const startTime = new Date().getTime();
    const response = await axios.post(
      `${BASE_URL}/workforces/status-summary`,
      {
        ...action,
      },
      {
        headers: {
          "x-workforce": workforceId,
          "x-lang": lang,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    resetAnalytics: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStatusSummaryAsync.pending, (state, action) => {
        state.statusSummaryLoading = true;
        state.statusSummaryError = "";
      })
      .addCase(getStatusSummaryAsync.fulfilled, (state, action) => {
        state.statusSummary = action.payload?.result;
        state.statusSummaryLoading = false;
      })
      .addCase(getStatusSummaryAsync.rejected, (state, action) => {
        state.statusSummaryLoading = false;
        state.statusSummaryError = "Something went wrong";
      });
  },
});

export const { resetAnalytics } = analyticsSlice.actions;
//Selectors
export const getStatusSummary = (state) => state.analytics.statusSummary;
export const getStatusSummaryLoading = (state) =>
  state.analytics.statusSummaryLoading;
export const getStatusSummaryError = (state) =>
  state.analytics.statusSummaryError;

export default analyticsSlice.reducer;
