import React, { useMemo, useState } from "react";
import "./UserBar.scss";
import { useSelector } from "react-redux";
import { Button } from "@questionprodev/shared-components";
import OutsideClickHandler from "react-outside-click-handler";
import { getCurrentUser } from "../../../Store/slices/user";
import { useTranslation } from "react-i18next";
import { abbreviation, logout } from "../../../Utils";

export const UserBar = () => {
  const { t } = useTranslation(["global"]);

  const user = useSelector(getCurrentUser);
  const initials = useMemo(
    () =>
      abbreviation(
        user?.firstname + " " + user?.lastname || user?.emailAddress
      ),
    [user]
  );
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const handleClose = () => {
    setIsOpenPopover(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpenPopover(false)}>
      <div className="user-bar-container">
        <div
          className="user-bar-container-button-content"
          onClick={() => setIsOpenPopover(!isOpenPopover)}
        >
          <div className="user-bar-container-button">{initials}</div>
        </div>

        <>
          {isOpenPopover ? (
            <div onClose={handleClose}>
              <div className="user-bar-container-popover">
                <h4 className="user-bar-container--title">
                  {user?.firstname} {user?.lastname}
                </h4>
                <h6 className="user-bar-container--mail">
                  {user?.emailAddress}
                </h6>
                <h6
                  className="user-bar-container--company"
                  style={{ marginBottom: "20px" }}
                >
                  {user?.companyName}
                </h6>
                <div className="user-bar-container--footer">
                  <Button
                    className="user-bar-container--btn"
                    label={t("logout", { ns: ["global"] })}
                    onClick={logout}
                  >
                    {t("logout")}
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </>
      </div>
    </OutsideClickHandler>
  );
};
