import "./CustomFileUploader.scss";
import { ReactComponent as UploadImage } from "../../../Assets/Icons/UploadImage.svg";
import { useTranslation } from "react-i18next";

const CustomFileUploader = ({ fileTypes }) => {
  const { t } = useTranslation();

  return (
    <div className={"file-uploader"}>
      <UploadImage width={40} height={40} />
      <div>
        {t("dragPlaceholder", { ns: ["ideation"] })}
        <span className={"file-uploader--browse"}>
          {t("browse", { ns: ["ideation"] })}
        </span>
      </div>
      <span>{fileTypes?.map((file) => `${file} `)}</span>
    </div>
  );
};

export default CustomFileUploader;
