export const CREATE_HUDDLE_MUTATION = `mutation createHuddle($input: CreateHuddleInput!) {
    createHuddle(createHuddleInput: $input) {
        id
        createdAt
        updatedAt
        descriptionHTML
        description
        people {
            emailAddress
            color
            userID
            username
        }
    }
}
`;
