import React, { useState } from "react";
import "./UserBar.scss";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as NotificationsActive } from "../../../Assets/Icons/NotificationsActive.svg";
import { ReactComponent as BugReport } from "../../../Assets/Icons/BugReport.svg";
import { Button } from "@questionpro/shared-components";
import OutsideClickHandler from "react-outside-click-handler";
import {
  updateLanguageOrEmailDisabledAsync,
  getCurrentUser,
} from "../../../Store/slices/user";
import CustomSwitch from "../../Reusable/CustomSwitch/CustomSwitch";
import { removeCookies } from "../../../Utils/cookies";
import { TOKEN_NAME } from "../../../Constants/Api";
import { useTranslation } from "react-i18next";

export const UserBar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["global"]);

  const user = useSelector(getCurrentUser);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [isChecked, setIsChecked] = useState(!user?.emailDisabled || false);

  const onSwitchChange = () => {
    dispatch(updateLanguageOrEmailDisabledAsync({ emailDisabled: isChecked }));
    setIsChecked(!isChecked);
  };

  const handleClose = () => {
    setIsOpenPopover(false);
  };

  const logout = () => {
    removeCookies(TOKEN_NAME);
    window.location.href = "https://www.questionpro.com/a/logout.do";
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpenPopover(false)}>
      <div className="user-bar-container">
        <div
          className="user-bar-container-button-content"
          onClick={() => setIsOpenPopover(!isOpenPopover)}
        >
          <div className="user-bar-container-button">{user.initials}</div>
        </div>

        <>
          {isOpenPopover ? (
            <div onClose={handleClose}>
              <div className="user-bar-container-popover">
                <div className="user-bar-container-email-box">
                  <NotificationsActive width={80} />
                  <div>
                    <p className="user-bar-container-text">{`${t(
                      "emailAddress"
                    )} - ${user.emailAddress}`}</p>
                    <p className="user-bar-container-text">{`${t(
                      "companyName"
                    )} - ${user.companyName}`}</p>
                  </div>
                </div>
                <div className="user-bar-container-org-box">
                  <BugReport width={80} />
                  <div>
                    <p className="user-bar-container-text">{`${t(
                      "organizationId"
                    )} - ${user.orgID}`}</p>
                    <p className="user-bar-container-text">{`${t(
                      "organizationApiKey"
                    )} - ${user.userAPIKey}`}</p>
                  </div>
                </div>
                <div className="user-bar-container-footer">
                  <CustomSwitch
                    isChecked={isChecked}
                    handleChange={onSwitchChange}
                    label={t("emailSubscribed")}
                  />

                  <Button variant="contained" onClick={logout}>
                    {t("logout")}
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </>
      </div>
    </OutsideClickHandler>
  );
};
