export const GET_ORGANIZATION_SETTINGS_QUERY = `
query {
  getOrganizationSettings {
    surveyID
    warningPeriod
    errorPeriod
    workforceID
    orgID
    goals {
      goal
    }
  }
}
`;
