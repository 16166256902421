export const GET_TEMPLATES_QUERY = `
    query getTemplates {
  getTemplates {
    templates {
      id
      name
      description
      goal
      actions {
        id
        name
        days
      }
      templateImage {
        id
        path
      }
      isHomeTemplate
      streamType
    }
    count
  }
}
`;
