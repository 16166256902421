export const GET_STATS_QUERY = `
query statistics($statisticsInput: DateIntervalInput!) {
  statistics(statisticsInput: $statisticsInput) {
    statistics {
      title
      total
    }
  }
}
`;
