export const GET_HOME_TEMPLATES_QUERY = `
    query homeTemplates {
  getHomeTemplates {
    templates {
      id
      name
      templateImage {
        path
      }
    }
  }
}
`;
