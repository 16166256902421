export const GET_USER_SESSION_LIST_QUERY = `query getUserSessionList($data: UserSessionListInput!) {
  getUserSessionList(data: $data) {
    sessions {
      id
      userID
      emailAddress
      emailDisabled
      username
      workforces
      language
      createdAt
      updatedAt
      average_seconds
      initiatives {
        completedInitiatives
        createdInitiatives
      }
      tasks {
        completedTasksCount
        createdTasksCount
      }
    }
  }
}
`;
