export const GET_USERS_QUERY = `
query getUsers($input: PaginationInput!) {
  getUsers(paginationInput: $input) {
    userID
    emailAddress
    username
    color
  }
}
`;
