export const GET_DASHBOARD_ANALYTICS_QUERY = `query getDashboardAnalytic($data: DateRangeInput!) {
  getDashboardAnalytic(data: $data) {
      initiatives {
        initiativesOpenedCount
        initiativesClosedCount
      }
      tasks {
        tasksOpenedCount
        tasksCompletedCount
        tasksClosedCount
      }
      startDate
      endDate
    }
}
`;
