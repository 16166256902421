import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { strongCopyData } from "../../../Utils";
import { addRequest } from "../../../IndexDB/IndexDB";
import { PerPageThen } from "../../../Constants/PerPage";
import deepcopy from "deepcopy";

const initialState = {
  huddles: [],
  huddleLoading: true,
  selectHuddle: null,
  huddlesCount: 0,
  newHuddle: false,
  searchValue: "",
  edit: false,
  clearEditor: true,
  isRequestSuccesses: null,
  huddlesError: "",
};

export const huddleSortAsync = createAsyncThunk(
  "huddle/huddleSort",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.get(
      `${BASE_URL}/huddles/sort/${action?.sort}/${action?.id}?page=${action?.page}&perPage=${PerPageThen}`,
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const huddleSearchAsync = createAsyncThunk(
  "huddle/huddleSearch",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.get(
      `${BASE_URL}/huddles/search/${action.initiativeId}?search_query=${action.searchTerm}&page=${action.activePage}`,
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const createHuddleAsync = createAsyncThunk(
  "huddle/createByNewHuddle",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.post(
      `${BASE_URL}/huddles`,
      { ...action.initialHuddle },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return {
      data: response.data,
    };
  }
);

export const updateHuddleAsync = createAsyncThunk(
  "huddle/update",
  async (_, state) => {
    const startTime = new Date().getTime();
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const huddle = { ...state.getState().huddle.selectHuddle };
    if (huddle.id) {
      const response = await axios.patch(
        `${BASE_URL}/huddles/${huddle.id}`,
        { ...huddle },
        {
          headers: {
            "x-workforce": workforceId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return {
        data: response.data,
        // type,
      };
    }
    return null;
  }
);

export const deleteHuddleAsync = createAsyncThunk(
  "huddle/delete",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const lang = state.getState().user?.selectedLanguage;
    const startTime = new Date().getTime();
    const response = await axios.delete(`${BASE_URL}/huddles/${action.id}`, {
      headers: {
        "x-workforce": workforceId,
        "x-lang": lang,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const huddleSlice = createSlice({
  name: "huddles",
  initialState,
  reducers: {
    resetHuddles: () => initialState,

    addNewHuddle(state, action) {
      state.clearEditor = !state.clearEditor;
      state.selectHuddle = action.payload;
      state.newHuddle = true;
    },
    removeNewHuddle(state, action) {
      const selectNewHuddle = deepcopy(state.selectHuddle);
      if (selectNewHuddle && action.payload) {
        const newHuddles = strongCopyData(state.huddles).slice(0, 9);
        state.huddles = [selectNewHuddle, ...newHuddles];
        state.huddlesCount += 1;
      }
      state.newHuddle = false;
      state.selectHuddle = null;
    },
    selectCurrentHuddle(state, action) {
      state.selectHuddle = action.payload;
    },
    selectHuddlePeople(state, action) {
      state.selectHuddle.people = action.payload;
    },
    selectHuddleText(state, action) {
      if (state.selectHuddle) {
        state.selectHuddle.description_HTML = action.payload;
      }
    },
    changeSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    emptyIsRequestSuccesses(state) {
      state.isRequestSuccesses = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(huddleSortAsync.pending, (state, action) => {
        state.huddleLoading = true;
        state.huddlesError = "";
      })
      .addCase(huddleSortAsync.fulfilled, (state, action) => {
        state.huddlesCount = action.payload?.count;
        state.huddles = action.payload?.huddles;
        state.huddleLoading = false;
      })
      .addCase(huddleSortAsync.rejected, (state, action) => {
        state.huddleLoading = false;
        state.huddlesError = "Something went wrong";
      })

      .addCase(huddleSearchAsync.pending, (state, action) => {
        state.huddleLoading = true;
      })
      .addCase(huddleSearchAsync.fulfilled, (state, action) => {
        state.huddlesCount = action.payload?.count;
        state.huddles = action.payload?.huddles;
        state.huddleLoading = false;
      })
      .addCase(huddleSearchAsync.rejected, (state, action) => {
        state.huddleLoading = false;
      })
      .addCase(createHuddleAsync.fulfilled, (state, action) => {
        state.selectHuddle = action.payload?.data.data;
      })
      .addCase(updateHuddleAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const index = state.huddles.findIndex(
            (item) => item.id === action.payload?.data.data.id
          );
          state.huddles[index] = action.payload?.data.data;
        }
      })
      .addCase(deleteHuddleAsync.fulfilled, (state, action) => {
        const huddles = deepcopy(state.huddles);
        state.huddles = huddles.filter(
          (huddle) => huddle.id !== action.payload?.id
        );
        state.isRequestSuccesses = true;
      })
      .addCase(deleteHuddleAsync.rejected, (state, action) => {
        state.isRequestSuccesses = false;
      });
  },
});

export const {
  resetHuddles,
  addNewHuddle,
  removeNewHuddle,
  selectCurrentHuddle,
  selectHuddlePeople,
  selectHuddleText,
  changeSearchValue,
  emptyIsRequestSuccesses,
} = huddleSlice.actions;

//Selectors
// export const getHuddles = (state) => state.huddle;
export const getHuddles = (state) => state.huddle.huddles;

export const getHuddleLoading = (state) => state.huddle.huddleLoading;

export const getHuddlesAllCount = (state) => state.huddle.huddlesCount;

export const getNewHuddle = (state) => state.huddle.newHuddle;

export const getSelectHuddle = (state) => state.huddle.selectHuddle;

export const getClearEditor = (state) => state.huddle.clearEditor;

export const getSearchValue = (state) => state.huddle.searchValue;

export const getIsRequestSuccesses = (state) => state.huddle.isRequestSuccesses;

export const getHuddlesError = (state) => state.huddle.huddlesError;

export default huddleSlice.reducer;
