import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import globalEnglish from "./English/global.json";
import globalSpanish from "./Spanish/global.json";
import globalGerman from "./German/global.json";
import globalArabic from "./Arabic/global.json";

import initiativesEnglish from "./English/initiatives.json";
import initiativesSpanish from "./Spanish/initiatives.json";
import initiativesGerman from "./German/initiatives.json";
import initiativesArabic from "./Arabic/initiatives.json";

import initiativeEnglish from "./English/initiative.json";
import initiativeSpanish from "./Spanish/initiative.json";
import initiativeGerman from "./German/initiative.json";
import initiativeArabic from "./Arabic/initiative.json";

import taskEnglish from "./English/task.json";
import taskSpanish from "./Spanish/task.json";
import taskGerman from "./German/task.json";
import taskArabic from "./Arabic/task.json";

import huddleEnglish from "./English/huddle.json";
import huddleSpanish from "./Spanish/huddle.json";
import huddleGerman from "./German/huddle.json";
import huddleArabic from "./Arabic/huddle.json";

import ideationEnglish from "./English/ideation.json";
import ideationSpanish from "./Spanish/ideation.json";
import ideationGerman from "./German/ideation.json";
import ideationArabic from "./Arabic/ideation.json";

import analyticsEnglish from "./English/analytics.json";
import analyticsSpanish from "./Spanish/analytics.json";
import analyticsGerman from "./German/analytics.json";
import analyticsArabic from "./Arabic/analytics.json";

import settingsEnglish from "./English/settings.json";
import settingsSpanish from "./Spanish/settings.json";
import settingsGerman from "./German/settings.json";
import settingsArabic from "./Arabic/settings.json";

import homeEnglish from "./English/home.json";
import homeSpanish from "./Spanish/home.json";
import homeGerman from "./German/home.json";
import homeArabic from "./Arabic/home.json";

import adminEnglish from "./English/admin.json";
import adminSpanish from "./Spanish/admin.json";
import adminGerman from "./German/admin.json";
import adminArabic from "./Arabic/admin.json";

const resources = {
  en: {
    global: globalEnglish,
    initiatives: initiativesEnglish,
    initiative: initiativeEnglish,
    task: taskEnglish,
    huddle: huddleEnglish,
    ideation: ideationEnglish,
    analytics: analyticsEnglish,
    settings: settingsEnglish,
    home: homeEnglish,
    admin: adminEnglish,
  },
  es: {
    global: globalSpanish,
    initiatives: initiativesSpanish,
    initiative: initiativeSpanish,
    task: taskSpanish,
    huddle: huddleSpanish,
    ideation: ideationSpanish,
    analytics: analyticsSpanish,
    settings: settingsSpanish,
    home: homeSpanish,
    admin: adminSpanish,
  },
  de: {
    global: globalGerman,
    initiatives: initiativesGerman,
    initiative: initiativeGerman,
    task: taskGerman,
    huddle: huddleGerman,
    ideation: ideationGerman,
    analytics: analyticsGerman,
    settings: settingsGerman,
    home: homeGerman,
    admin: adminGerman,
  },
  ar: {
    global: globalArabic,
    initiatives: initiativesArabic,
    initiative: initiativeArabic,
    task: taskArabic,
    huddle: huddleArabic,
    ideation: ideationArabic,
    analytics: analyticsArabic,
    settings: settingsArabic,
    home: homeArabic,
    admin: adminArabic,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", //default language
    keySeparator: false,
  })
  .then((r) => {});

export default i18n;
