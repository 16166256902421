// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.borderBottom-none .qp-dropdown {
  border-bottom: none;
}

.open-popover {
  max-height: 200px !important;
  height: auto !important;
  border: none !important;
  box-shadow: 0 8px 12px -4px rgba(0, 0, 0, 0.2);
}

.custom-child {
  max-height: unset !important;
}
.custom-child .dropdown-option-children {
  text-align: center;
}
.custom-child .dropdown-option:hover {
  background-color: transparent !important;
}

.dropdown-content {
  position: relative;
}
.dropdown-content--multi-count {
  display: flex;
  justify-content: space-between;
}
.dropdown-content .close {
  position: absolute;
  right: 18px;
  top: 12px;
}

.dropdown-status-block {
  display: flex;
  align-items: center;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Reusable/DropdownComponent/style.scss"],"names":[],"mappings":"AAGE;EACE,mBAAA;AAFJ;;AAMA;EACE,4BAAA;EACA,uBAAA;EACA,uBAAA;EACA,8CAAA;AAHF;;AAMA;EACE,4BAAA;AAHF;AAIE;EACE,kBAAA;AAFJ;AAKI;EACE,wCAAA;AAHN;;AAQA;EACE,kBAAA;AALF;AAME;EACE,aAAA;EACA,8BAAA;AAJJ;AAME;EACE,kBAAA;EACA,WAAA;EACA,SAAA;AAJJ;;AAQA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AALF","sourcesContent":["@import \"src/Assets/styles/mixins\";\n\n.borderBottom-none {\n  .qp-dropdown {\n    border-bottom: none;\n  }\n}\n\n.open-popover {\n  max-height: 200px !important;\n  height: auto !important;\n  border: none !important;\n  box-shadow: 0 8px 12px -4px rgba(0, 0, 0, 0.2);\n}\n\n.custom-child {\n  max-height: unset !important;\n  .dropdown-option-children {\n    text-align: center;\n  }\n  .dropdown-option {\n    &:hover {\n      background-color: transparent !important;\n    }\n  }\n}\n\n.dropdown-content {\n  position: relative;\n  &--multi-count {\n    display: flex;\n    justify-content: space-between;\n  }\n  .close {\n    position: absolute;\n    right: 18px;\n    top: 12px;\n  }\n}\n\n.dropdown-status-block {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
