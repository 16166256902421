import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/IndexDB";
import { deleteInitiativeAsync } from "../initiatives";

const initialState = {
  statistics: null,
  statisticsLoading: false,
  statisticsError: "",

  topGoals: null,
  topGoalsLoading: false,
  topGoalsError: "",

  topContributors: null,
  topContributorsLoading: false,
  topContributorsError: "",

  topIdeas: null,
  topIdeasLoading: false,
  topIdeasError: "",

  filters: {
    startDate: null,
    endDate: null,
    status: "all",
    type: "initiatives",
    goal: "all",
  },
};

export const getInitiativeStatisticsAsync = createAsyncThunk(
  "initiativeAnalytics/post",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const lang = state.getState().user?.selectedLanguage;
    const startTime = new Date().getTime();
    const response = await axios.post(
      `${BASE_URL}/workforces/statistics`,
      {
        ...action,
      },
      {
        headers: {
          "x-workforce": workforceId,
          "x-lang": lang,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getInitiativeTopGoalsAsync = createAsyncThunk(
  "initiativeTopGoals/post",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.post(
      `${BASE_URL}/workforces/top-goals?status=${action.status}`,
      {
        ...action,
      },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getTopContributorsAsync = createAsyncThunk(
  "topContributors/post",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.post(
      `${BASE_URL}/workforces/top-contributors`,
      {
        ...action,
      },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getPopularIdeasAsync = createAsyncThunk(
  "topIdeasAsync/post",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const lang = state.getState().user?.selectedLanguage;
    const startTime = new Date().getTime();
    const response = await axios.post(
      `${BASE_URL}/workforces/top-ideas`,
      {
        ...action,
      },
      {
        headers: {
          "x-workforce": workforceId,
          "x-lang": lang,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const exportAnalyticsXLSXAsync = createAsyncThunk(
  "analyticsXLSX/get",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const filters = state.getState().initiativeAnalytics?.filters;
    const startTime = new Date().getTime();
    const response = await axios.get(
      `${BASE_URL}/workforces/export?startDate=${
        filters.startDate
          ? new Date(filters.startDate)
          : new Date(new Date().setMonth(new Date().getMonth() - 12))
      }&endDate=${new Date()}&goalStatus=${filters.status}`,
      {
        headers: {
          "x-workforce": workforceId,
        },
        responseType: "blob",
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    const href = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "file.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }
);

const initiativeAnalyticsSlice = createSlice({
  name: "initiativeAnalytics",
  initialState,
  reducers: {
    resetInitiativeAnalytics: () => initialState,
    changeFilters: (state, action) => {
      const key = action.payload.type;
      state.filters = {
        ...state.filters,
        [key]: action.payload.value,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInitiativeStatisticsAsync.pending, (state, action) => {
      state.statisticsLoading = true;
      state.statisticsError = "";
    });
    builder.addCase(getInitiativeStatisticsAsync.fulfilled, (state, action) => {
      state.statistics = action.payload?.statistics;
      state.statisticsLoading = false;
    });
    builder.addCase(getInitiativeStatisticsAsync.rejected, (state, action) => {
      state.statisticsLoading = false;
      state.statisticsError = "Something went wrong";
    });
    builder.addCase(deleteInitiativeAsync.fulfilled, (state, action) => {
      const newStatistics = state.statistics;
      if (newStatistics) {
        newStatistics[0] = {
          title: "Active Initiatives",
          total: action.payload?.counts?.activeCount,
        };
      }
      state.statistics = newStatistics;
    });
    builder.addCase(getInitiativeTopGoalsAsync.pending, (state, action) => {
      state.topGoalsLoading = true;
      state.topGoalsError = "";
    });
    builder.addCase(getInitiativeTopGoalsAsync.fulfilled, (state, action) => {
      state.topGoals = action.payload?.topGoals;
      state.topGoalsLoading = false;
    });
    builder.addCase(getInitiativeTopGoalsAsync.rejected, (state, action) => {
      state.topGoalsLoading = false;
      state.topGoalsError = "Something went wrong";
    });
    builder.addCase(getTopContributorsAsync.pending, (state, action) => {
      state.topContributorsLoading = true;
      state.topContributorsError = "";
    });
    builder.addCase(getTopContributorsAsync.fulfilled, (state, action) => {
      state.topContributors = action.payload?.topContributors;
      state.topContributorsLoading = false;
    });
    builder.addCase(getTopContributorsAsync.rejected, (state, action) => {
      state.topContributorsLoading = false;
      state.topContributorsError = "Something went wrong";
    });
    builder.addCase(getPopularIdeasAsync.pending, (state, action) => {
      state.topIdeasLoading = true;
      state.topIdeasError = "";
    });
    builder.addCase(getPopularIdeasAsync.fulfilled, (state, action) => {
      state.topIdeas = action.payload?.topIdeas;
      state.topIdeasLoading = false;
    });
    builder.addCase(getPopularIdeasAsync.rejected, (state, action) => {
      state.topIdeasLoading = false;
      state.topIdeasError = "Something went wrong";
    });
  },
});

export const { resetInitiativeAnalytics, changeFilters } =
  initiativeAnalyticsSlice.actions;

//Selectors
export const getStatistics = (state) => state.initiativeAnalytics.statistics;
export const getStatisticsLoading = (state) =>
  state.initiativeAnalytics.statisticsLoading;
export const getStatisticsError = (state) =>
  state.initiativeAnalytics.statisticsError;
export const getTopGoals = (state) => state.initiativeAnalytics.topGoals;
export const getTopGoalsLoading = (state) =>
  state.initiativeAnalytics.topGoalsLoading;
export const getTopGoalsError = (state) =>
  state.initiativeAnalytics.topGoalsError;

export const getTopContributors = (state) =>
  state.initiativeAnalytics.topContributors;
export const getTopContributorsLoading = (state) =>
  state.initiativeAnalytics.topContributorsLoading;
export const getTopContributorsError = (state) =>
  state.initiativeAnalytics.topContributorsError;

export const getTopIdeas = (state) => state.initiativeAnalytics.topIdeas;
export const getTopIdeasLoading = (state) =>
  state.initiativeAnalytics.topIdeasLoading;
export const getTopIdeasError = (state) =>
  state.initiativeAnalytics.topIdeasError;

export const getFilters = (state) => state.initiativeAnalytics.filters;

export default initiativeAnalyticsSlice.reducer;
