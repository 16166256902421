export const GET_INITIATIVE_OWNERS_QUERY = `
    query initiativeOwners {
  getInitiativeOwners {
    owners {
      owner {
        username
        emailAddress
        color
        userID
      }
      initiativesCounts
      tasksCount
    }
  }
}
`;
