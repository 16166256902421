export const TEAMSVIEW_SAVE_FILTERS_MUTATION = `mutation saveFilter($id: String!, $data: SaveFilters!) {
  saveFilter(id: $id, data: $data) {
    id
    userId
    userEmail
    workforceID
    type
    value
  }
}
`;
