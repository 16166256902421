export const GET_TASKS_QUERY = `
    query getTasks($input: GetTasksInput!) {
  getTasks(getTasksInput: $input) {
    tasks {
      id
      status
      dueDate
      name
      description
      initiativeId
      completed_at
      people {
        emailAddress
        color
        userID
        username
      }
      responsible {
        emailAddress
        color
        userID
        username
      }
    }
    count
  }
}
`;
