export const GET_TOP_IDEAS_QUERY = `
    query topIdeas($topIdeasInput: DateIntervalInput!) {
  topIdeas(topIdeasInput: $topIdeasInput) {
    topIdeas {
      ideation {
        id
        name
        votesPerParticipant
      }
      id
      color
      text
      votesCount
    }
  }
}
`;
