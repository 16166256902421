export const productSwitcherColors = {
  "Survey Software": "#C7E2FA",
  "Research Suite": "#1b87e6",
  "Customer Experience": "#C5EBD7",
  Workforce: "#E7D2FA",
  "Engagement Tools": "#FFBEE7",
};

export const productSwitcherHoverColors = {
  "Survey Software": "#1B87E6",
  "Research Suite": "#1b3480",
  "Customer Experience": "#17C266",
  Workforce: "#A554EB",
  "Engagement Tools": "#ED22A1",
};
