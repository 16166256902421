import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/IndexDB";
import { PerPageThen } from "../../../Constants/PerPage";

const initialState = {
  homeTemplates: [],
  homeTemplatesLoading: true,
  error: {},
  upcomingTasks: [],
  upcomingTasksLoading: false,
};

export const getHomeTemplatesAsync = createAsyncThunk(
  "home/templates",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    try {
      const response = await axios.get(`${BASE_URL}/templates/home-templates`, {
        headers: {
          "x-workforce": workforceId,
        },
      });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getUpcomingTasksAsync = createAsyncThunk(
  "home/upcomingTasks",
  async (action, thankApi) => {
    const startTime = new Date().getTime();
    const workforceId = thankApi.getState().user?.selectedWorkforce.workforceID;

    try {
      const response = await axios.get(
        `${BASE_URL}/initiative/upcoming-tasks`,
        {
          params: { offset: action?.offset || 0, limit: PerPageThen },
          headers: {
            "x-workforce": workforceId,
          },
        }
      );

      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      console.log(err);
      return Promise.reject(err.response.data);
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHomeTemplatesAsync.pending, (state) => {
        state.error = {};
        state.homeTemplatesLoading = true;
      })
      .addCase(getHomeTemplatesAsync.fulfilled, (state, action) => {
        state.homeTemplates = action.payload.templates;
        state.homeTemplatesLoading = false;
      })
      .addCase(getHomeTemplatesAsync.rejected, (state, action) => {
        state.homeTemplates = action.payload;
        state.error.homeTemplates = `${action?.payload?.statusCode} - ${action?.payload?.message}`;
      })

      .addCase(getUpcomingTasksAsync.pending, (state, action) => {
        const offset = action.meta.arg?.offset;
        if (!offset) state.upcomingTasksLoading = true;
      })
      .addCase(getUpcomingTasksAsync.fulfilled, (state, action) => {
        const offset = action.meta.arg?.offset;
        state.upcomingTasksLoading = false;
        state.upcomingTasks = !offset
          ? [[...action.payload.initiatives]]
          : [...state.upcomingTasks, [...action.payload.initiatives]];
      })
      .addCase(getUpcomingTasksAsync.rejected, (state, action) => {
        state.upcomingTasksLoading = false;
      });
  },
});

export const getHomeTemplates = (state) => state.home.homeTemplates;
export const getHomeTemplatesLoading = (state) =>
  state.home.homeTemplatesLoading;
export const getUpcomingTasks = (state) => state.home.upcomingTasks;
export const getUpcomingTasksLoading = (state) =>
  state.home.upcomingTasksLoading;

export default homeSlice.reducer;
