export const UPDATE_INITIATIVE_MUTATION = `
    mutation updateInitiative($input: UpdateInitiativeInput!) {
  updateInitiative(updateInitiativeInput: $input) {
    name
    name
    description
    status
    orgID
    owner {
      emailAddress
      color
      username
      userID
    }
    people {
      emailAddress
      color
      userID
      username
    }
    deleted
    streamType
  }
}
`;
