// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ideation--comments--btn {
  margin-top: 4px;
  font-size: 12px;
  cursor: pointer;
  color: #1b87e6;
}
.ideation--comments--btn--open {
  color: #1b3380;
}
.ideation--comments--comments-block {
  margin-top: 12px;
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
  color: #545e6b;
}
.ideation--comments--comments-block--open {
  margin-right: 10px;
  max-height: 100px;
  transition: 0.3s;
  overflow: auto;
}
.ideation--comments--comment {
  padding: 10px;
  margin-bottom: 8px;
  margin-right: 20px;
  font-size: 12px;
  background-color: #f5f5f5;
}`, "",{"version":3,"sources":["webpack://./src/Components/IdeationSettingsExploreWrapper/IdeationResultsComments/IdeationResultsComments.scss","webpack://./src/Assets/styles/variables.scss"],"names":[],"mappings":"AAIE;EACE,eAAA;EACA,eAAA;EACA,eAAA;EACA,cCNc;ADGlB;AAME;EACE,cAAA;AAJJ;AAOE;EACE,gBAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,cCpBc;ADelB;AAOI;EACE,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AALN;AASE;EACE,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,eCrBa;EDsBb,yBC/BY;ADwBhB","sourcesContent":["@import \"src/Assets/styles/variables\";\n@import \"src/Assets/styles/mixins\";\n\n.ideation--comments {\n  &--btn {\n    margin-top: 4px;\n    font-size: 12px;\n    cursor: pointer;\n    color: $BASE-BLUE-COLOR;\n  }\n\n  &--btn--open {\n    color: #1b3380;\n  }\n\n  &--comments-block {\n    margin-top: 12px;\n    max-height: 0;\n    overflow: hidden;\n    transition: 0.3s;\n    color: $BASE-GREY-COLOR;\n\n    &--open {\n      margin-right: 10px;\n      max-height: 100px;\n      transition: 0.3s;\n      overflow: auto;\n    }\n  }\n\n  &--comment {\n    padding: 10px;\n    margin-bottom: 8px;\n    margin-right: 20px;\n    font-size: $BASE-FONT-SIZE;\n    background-color: $BASE-BG-COLOR;\n  }\n}\n","$BASE-GREY-COLOR: #545e6b;\n$BASE-LIGHT-GREY-COLOR: #9b9b9b;\n$BASE-BLUE-COLOR: #1b87e6;\n$BASE-DARK-BLUE-COLOR: #54a5ec;\n$BASE-BG-COLOR: #f5f5f5;\n$BASE-WHITE-COLOR: #ffffff;\n$BASE-BORDER-COLOR: #d8d8d8;\n$BASE-HEADER-HEIGHT: 48px;\n$BASE-MOBILE-HEADER-HEIGHT: 100px;\n$BASE-NAVBAR-WIDTH: 168px;\n$BASE-LARGE-SIZE: 24px;\n$MODAL-MIDDLE-SIZE: 16px;\n$MODAL-Regular-SIZE: 18px;\n$BASE-FONT-SIZE: 12px;\n$BASE-ERROR-COLOR: #e53251;\n$BASE-SUCCESS-COLOR: #3fca5a;\n$BASE-NORMAL-COLOR: #f0b400;\n$MAIN-GRAY-COLOR: #454545;\n$MAIN-BLUE-COLOR: #1b3380;\n$MAIN-BORDER-COLOR: #ddd;\n\n$large-l: 1440px;\n$large: 1024px;\n$medium: 768px;\n$small-L: 590px;\n$small: 425px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
