export const GET_TOP_IDEAS_IDEATION_QUERY = `query topIdeasInIdeation($topIdeasInIdeationInput: String!) {
    topIdeasInIdeation(ideationId: $topIdeasInIdeationInput) {
        topIdeas {
            id
            text
            votesCount
        }

        defaultIdeas {
            id
            text
            votesCount
            comments {
                id
                comment
            }
        }

        questions {
            id
            ideas {
                id
                text
                votesCount
                comments {
                    id
                    comment
                }
            }
        }

        participants
        comments
        ideasCounts
    }
}
`;
