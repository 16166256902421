export const CREATE_TEMPLATE_MUTATION = `mutation createTemplate($input: CreateTemplateInput!) { 
    createTemplate(createTemplateInput: $input) { 
        id 
        language 
        name 
        orgID 
        goal 
        imageId 
        type 
        created_by 
        description 
        updatedAt 
        createdAt
        streamType
    } 
}`;
