export const CREATE_IDEATION_MUTATION = `mutation createIdeation($input: CreateIdeationInput!) {
    createIdeation(createIdeationInput: $input) {
        id
        createdAt
        updatedAt
        introduction
        name
        participants
        votesPerParticipant
        status
        logo
        actions
        allowIdeas
        allowIdeasComments
        allowDefaultIdeas
        allowedActions
        questions {
            id
            question
        }
        url
        actionList{
            id
        }
    }
}
`;
