// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-layout {
  display: flex;
  min-height: calc(100vh - 48px);
  background-color: #ffffff;
}
.main-layout--children {
  position: relative;
  width: 100%;
  max-width: 1920px;
  padding-left: 50px;
  margin: 0 auto;
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/Layout/MainLayout/MainLayout.scss","webpack://./src/Assets/styles/variables.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,8BAAA;EACA,yBCDiB;ADDnB;AAIE;EACE,kBAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,6BAAA;AAFJ","sourcesContent":["@import \"src/Assets/styles/variables\";\n@import \"src/Assets/styles/mixins\";\n\n.main-layout {\n  display: flex;\n  min-height: calc(100vh - $BASE-HEADER-HEIGHT);\n  background-color: $BASE-WHITE-COLOR;\n\n  &--children {\n    position: relative;\n    width: 100%;\n    max-width: 1920px;\n    padding-left: 50px;\n    margin: 0 auto;\n    background-color: transparent;\n  }\n}\n","$BASE-GREY-COLOR: #545e6b;\n$BASE-LIGHT-GREY-COLOR: #9b9b9b;\n$BASE-BLUE-COLOR: #1b87e6;\n$BASE-DARK-BLUE-COLOR: #54a5ec;\n$BASE-BG-COLOR: #f5f5f5;\n$BASE-WHITE-COLOR: #ffffff;\n$BASE-BORDER-COLOR: #d8d8d8;\n$BASE-HEADER-HEIGHT: 48px;\n$BASE-MOBILE-HEADER-HEIGHT: 100px;\n$BASE-NAVBAR-WIDTH: 168px;\n$BASE-LARGE-SIZE: 24px;\n$MODAL-MIDDLE-SIZE: 16px;\n$MODAL-Regular-SIZE: 18px;\n$BASE-FONT-SIZE: 12px;\n$BASE-ERROR-COLOR: #e53251;\n$BASE-SUCCESS-COLOR: #3fca5a;\n$BASE-NORMAL-COLOR: #f0b400;\n$MAIN-GRAY-COLOR: #454545;\n$MAIN-BLUE-COLOR: #1b3380;\n$MAIN-BORDER-COLOR: #ddd;\n\n$large-l: 1440px;\n$large: 1024px;\n$medium: 768px;\n$small-L: 590px;\n$small: 425px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
