export const GET_INITIATIVES_QUERY = `
    query getInitiatives($input: PaginationInput!, $filter: GetInitiativeFilterInput!) {
  getInitiativeTable(paginationInput: $input, filter: $filter) {
    initiatives {
      id
      name
      description
      createdAt
      year
      status
      dueDate
      owner {
        emailAddress
        color
        username
        userID
      }
      people {
        emailAddress
        color
        userID
        username
      }
    }
    total
  }
}
`;
