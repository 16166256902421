export const decodeBase64 = (encodedString) => {
  try {
    // Remove 'Bearer ' prefix if it exists
    if (encodedString.startsWith("Bearer "))
      encodedString = encodedString.substring(7);

    const parts = encodedString.split(".");
    if (parts.length !== 3) throw new Error("Invalid token format");

    const base64 = parts[0];
    return JSON.parse(atob(base64));
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
};
