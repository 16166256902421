import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/IndexDB";
import { updateInitiativeAsync } from "../initiatives";

const initialState = {
  initiative: null,
  initiativeLoading: true,
  initiativeError: "",

  initiativeTags: [],
  initiativeTagsLoading: false,
  initiativeTagsError: "",
};

export const getInitiativeAsync = createAsyncThunk(
  "initiative/get",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const lang = state.getState().user?.selectedLanguage;
    const startTime = new Date().getTime();
    try {
      const response = await axios.get(
        `${BASE_URL}/initiative/${action.initID}`,
        {
          headers: {
            "x-workforce": workforceId,
            "x-lang": lang,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getInitiativeTagAsync = createAsyncThunk(
  "getInitiativeTagAsync/get",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.get(
      `${BASE_URL}/tags/initiative/${action.initID}`,
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const updateInitiativeStatusHealthAsync = createAsyncThunk(
  "initiativeHealthStatus/update",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.patch(
      `${BASE_URL}/initiative/${action.initID}`,
      { ...action.initiativeData },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const createInitTagsAsync = createAsyncThunk(
  "initiativeTag/patch",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.patch(
      `${BASE_URL}/tags/initiative/${action.initID}`,
      { tags: action.tags },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const initiativesSlice = createSlice({
  name: "initiative",
  initialState,
  reducers: {
    resetInitiative: () => initialState,
    emptyRequestMessage(state) {
      state.isOpenPopup = false;
      state.requestSuccessesMessage = "";
      state.requestErrorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitiativeAsync.pending, (state, action) => {
        state.initiativeLoading = true;
      })
      .addCase(getInitiativeAsync.fulfilled, (state, action) => {
        state.initiative = action.payload;
        state.initiativeLoading = false;
      })
      .addCase(getInitiativeAsync.rejected, (state, action) => {
        state.initiativeError = action.payload?.message;
        state.initiativeLoading = false;
      })
      .addCase(getInitiativeTagAsync.pending, (state, action) => {
        state.initiativeTagsLoading = true;
      })
      .addCase(getInitiativeTagAsync.fulfilled, (state, action) => {
        state.initiativeTags = action.payload?.tags || [];
        state.initiativeTagsLoading = false;
      })
      .addCase(getInitiativeTagAsync.rejected, (state, action) => {
        state.initiativeTagsError = "Something went wrong";
        state.initiativeTagsLoading = false;
      })
      .addCase(updateInitiativeStatusHealthAsync.fulfilled, (state, action) => {
        state.initiative.healthStatus = action.payload.healthStatus;
        state.requestSuccessesMessage =
          "InitiativeCard healthStatus successfully updated";
        state.isOpenPopup = true;
      })
      .addCase(updateInitiativeStatusHealthAsync.rejected, (state, action) => {
        state.requestErrorMessage = "Something went wrong";
        state.isOpenPopup = true;
      })
      .addCase(createInitTagsAsync.fulfilled, (state, action) => {
        state.initiativeTags = action.payload.tags;
      })
      .addCase(createInitTagsAsync.rejected, (state, action) => {
        state.initiativeTagsError = "Something went wrong";
        state.initiativeTagsLoading = false;
      })
      .addCase(updateInitiativeAsync.fulfilled, (state, action) => {
        state.initiative = action.payload?.data;
      });
  },
});

export const { resetInitiative, emptyRequestMessage } =
  initiativesSlice.actions;

//Selectors
export const getInitiative = (state) => state.initiative.initiative;
export const getInitiativeLoading = (state) =>
  state.initiative.initiativeLoading;
export const getInitiativeError = (state) => state.initiative.initiativeError;

export const getInitiativeTags = (state) => state.initiative.initiativeTags;
export const getInitiativeTagsLoading = (state) =>
  state.initiative.initiativeTagsLoading;
export const getInitiativeTagsError = (state) =>
  state.initiative.initiativeTagsError;

export default initiativesSlice.reducer;
