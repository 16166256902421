import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PerPageThen } from "../../../Constants/PerPage";
import { GET_UPCOMING_TASKS_QUERY } from "../../../gql/graphqlQuery/get-upcoming-tasks.generated";
import { GET_HOME_TEMPLATES_QUERY } from "../../../gql/graphqlQuery/get-home-templates.generated";
import graphqlRequest from "../../../graphqlClient";

const initialState = {
  homeTemplates: [],
  homeTemplatesLoading: true,
  error: {},
  upcomingTasks: [],
  upcomingTasksLoading: false,
};

export const getHomeTemplatesAsync = createAsyncThunk(
  "home/templates",
  async (action, state) => {
    try {
      const response = await graphqlRequest(GET_HOME_TEMPLATES_QUERY);
      return response.getHomeTemplates;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getUpcomingTasksAsync = createAsyncThunk(
  "home/upcomingTasks",
  async (action, thankApi) => {
    const variables = {
      args: {
        limit: PerPageThen,
        offset: action?.offset || 0,
      },
    };
    try {
      const response = await graphqlRequest(
        GET_UPCOMING_TASKS_QUERY,
        variables
      );
      return response.getUpcomingTasks;
    } catch (err) {
      console.log(err);
      return Promise.reject(err.response.data);
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHomeTemplatesAsync.pending, (state) => {
        state.error = {};
        state.homeTemplatesLoading = true;
      })
      .addCase(getHomeTemplatesAsync.fulfilled, (state, action) => {
        state.homeTemplates = action.payload.templates;
        state.homeTemplatesLoading = false;
      })
      .addCase(getHomeTemplatesAsync.rejected, (state, action) => {
        state.homeTemplates = action.payload;
        state.error.homeTemplates = `${action?.payload?.statusCode} - ${action?.payload?.message}`;
      })

      .addCase(getUpcomingTasksAsync.pending, (state, action) => {
        const offset = action.meta.arg?.offset;
        if (!offset) state.upcomingTasksLoading = true;
      })
      .addCase(getUpcomingTasksAsync.fulfilled, (state, action) => {
        const offset = action.meta.arg?.offset;
        state.upcomingTasksLoading = false;
        state.upcomingTasks = !offset
          ? [[...action.payload.initiatives]]
          : [...state.upcomingTasks, [...action.payload.initiatives]];
      })
      .addCase(getUpcomingTasksAsync.rejected, (state, action) => {
        state.upcomingTasksLoading = false;
      });
  },
});

export const getHomeTemplates = (state) => state.home.homeTemplates;
export const getHomeTemplatesLoading = (state) =>
  state.home.homeTemplatesLoading;
export const getUpcomingTasks = (state) => state.home.upcomingTasks;
export const getUpcomingTasksLoading = (state) =>
  state.home.upcomingTasksLoading;

export default homeSlice.reducer;
