import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { strongCopyData } from "../../../Utils";
import { addRequest } from "../../../IndexDB/IndexDB";
import { PerPageThen } from "../../../Constants/PerPage";
import { DELETE_TASKS_MUTATION } from "../../../gql/graphqlQuery/delete-tasks.generated";
import { GET_TASKS_QUERY } from "../../../gql/graphqlQuery/get-tasks.generated";
import { CREATE_TASK_MUTATION } from "../../../gql/graphqlQuery/create-task.generated";
import { BULK_CREATE_TASKS_MUTATION } from "../../../gql/graphqlQuery/bulk-create-tasks.generated";
import { UPDATE_TASK_MUTATION } from "../../../gql/graphqlQuery/update-task.generated";
import { UPDATE_TASK_STATUS_MUTATION } from "../../../gql/graphqlQuery/update-task-status.generated";
import graphqlRequest from "../../../graphqlClient";

const initialState = {
  tasks: [],
  tasksCount: 0,
  tasksPage: 1,
  tasksLoading: true,
  error: {},
};

export const getTaskAsync = createAsyncThunk(
  "task/get",
  async (action, state) => {
    const variables = {
      input: {
        id: action.initID,
        limit: PerPageThen,
        offset: (action.page - 1) * PerPageThen,
      },
    };
    const response = await graphqlRequest(GET_TASKS_QUERY, variables);
    return response.getTasks;
  }
);

export const bulkCreateTaskAsync = createAsyncThunk(
  "bulkTask/create",
  async (action, state) => {
    const variables = {
      createBulkTaskInput: { ...action },
    };
    const response = await graphqlRequest(
      BULK_CREATE_TASKS_MUTATION,
      variables
    );
    return response.bulkCreateTasks;
  }
);
export const createTaskAsync = createAsyncThunk(
  "task/create",
  async (action, state) => {
    const variables = {
      input: { ...action },
    };
    const response = await graphqlRequest(CREATE_TASK_MUTATION, variables);
    return response.createTask;
  }
);
export const updateTaskAsync = createAsyncThunk(
  "task/update",
  async (action, state) => {
    const variables = {
      input: { id: action.id, ...action.task },
    }; //request fields error
    const response = await graphqlRequest(UPDATE_TASK_MUTATION, variables);

    return response.updateTask;
  }
);
export const deleteTaskAsync = createAsyncThunk(
  "task/delete",
  async (action, state) => {
    const response = await graphqlRequest(DELETE_TASKS_MUTATION, {
      input: action.ids,
    });
    return response;
  }
);

export const changeTaskStatus = createAsyncThunk(
  "taskStatus/post",
  async (action, state) => {
    const variables = {
      input: {
        id: action.taskID,
        status: action.status.toUpperCase().replace(/\s+/g, ""),
      },
    };
    const response = await graphqlRequest(
      UPDATE_TASK_STATUS_MUTATION,
      variables
    );
    return response.updateTaskStatus;
  }
);

export const dragTaskAsync = createAsyncThunk(
  "dragTask/patch",
  async (action, state) => {
    const startTime = new Date().getTime();
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const response = await axios.patch(
      `${BASE_URL}/tasks/priorityIndex/${action.id}`,
      action,
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    resetTask: () => initialState,
    updateCurrentTask: (state, action) => {
      const index = state.tasks.findIndex(
        (item) => item.id === action.payload.id
      );
      state.tasks[index] = action.payload;
    },
    dragTaskList: (state, action) => {
      state.tasks = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaskAsync.pending, (state, action) => {
        state.error = {};
        state.tasksLoading = true;
      })
      .addCase(getTaskAsync.fulfilled, (state, action) => {
        state.tasks = action.payload?.tasks;
        state.tasksCount = action.payload?.count;
        state.tasksPage = action.payload?.page;
        state.tasksLoading = false;
      })
      .addCase(getTaskAsync.rejected, (state, action) => {
        state.tasksLoading = false;
        state.error.tasks = "Something went wrong";
      })

      .addCase(createTaskAsync.fulfilled, (state, action) => {
        state.tasks = [action.payload, ...state.tasks];
        if (state.tasks.length > PerPageThen) {
          state.tasks.pop();
        }
        state.tasksCount += 1;
      })
      .addCase(bulkCreateTaskAsync.fulfilled, (state, action) => {
        state.tasks = [...action.payload, ...state.tasks];
        if (state.tasks.length > PerPageThen) {
          state.tasks.slice(0, -action.payload.length);
        }
        state.tasksCount += action.payload.length;
      })

      .addCase(changeTaskStatus.fulfilled, (state, action) => {
        const index = state.tasks.findIndex(
          (task) => task.id === action.payload.id
        );
        state.tasks[index] = action.payload;
      })
      .addCase(deleteTaskAsync.fulfilled, (state, action) => {
        //structure change from portal
        let tasksCopy = strongCopyData(state.tasks);
        const deletedId = action.payload?.deleteTask;

        state.tasks = tasksCopy.filter((task) => task.id !== deletedId);
        state.tasksCount -= 1;
      });
  },
});

export const { resetTask, updateCurrentTask, dragTaskList } =
  tasksSlice.actions;
//Selectors
export const getTasks = (state) => state.tasks.tasks;

export const getTaskPage = (state) => state.tasks.tasksPage;
export const getTasksCount = (state) => state.tasks.tasksCount;
export const getTasksLoading = (state) => state.tasks.tasksLoading;
export const getTaskError = (state) => state.tasks.error;

export default tasksSlice.reducer;
