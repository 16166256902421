import React from "react";
import "./ProductSwitcher.scss";
import ProductSwitcherMenuItem from "./menu-item/ProductSwitcherMenuItem";
import { getProductSwitcher } from "../../../Store/slices/user";
import { useSelector } from "react-redux";

const ProductSwitcher = ({ isOpen }) => {
  const productSwitcher = useSelector(getProductSwitcher);

  return (
    <ul
      className={`
        product-switcher ${isOpen ? "product-switcher-open" : ""}
    `}
    >
      {productSwitcher?.map((menuItem, index) => {
        return <ProductSwitcherMenuItem menuItem={menuItem} key={index} />;
      })}
    </ul>
  );
};

export default ProductSwitcher;
