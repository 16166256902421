export const UPDATE_TASK_STATUS_MUTATION = `mutation updateTaskStatus($input: UpdateTaskStatusInput!) {
  updateTaskStatus(updateTaskStatusInput: $input) {
    id
    initiativeId
    name
    description
    dueDate
    completed_at
    status
    people {
      emailAddress
      color
    }
    responsible {
      emailAddress
      color
    }
  }
}`;
