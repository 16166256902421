import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/IndexDB";
import { PerPageThen } from "../../../Constants/PerPage";
import { GET_INITIATIVES_QUERY } from "../../../gql/graphqlQuery/get-initiatives.generated";
import { DELETE_INITIATIVE_MUTATION } from "../../../gql/graphqlQuery/delete-initiative.generated";
import { GET_INITIATIVE_OWNERS_QUERY } from "../../../gql/graphqlQuery/get-initiative-owners.generated";
import { GET_INITIATIVE_FILTERS_QUERY } from "../../../gql/graphqlQuery/get-initiative-filters.generated";
import { CREATE_INITIATIVE_MUTATION } from "../../../gql/graphqlQuery/create-initiative.generated";
import { UPDATE_INITIATIVE_MUTATION } from "../../../gql/graphqlQuery/update-initiative.generated";
import { GET_INITIATIVES_GRID_QUERY } from "../../../gql/graphqlQuery/get-initiatives-grid.generated";
import graphqlRequest from "../../../graphqlClient";

let controller;

export const getInitiativesTableAsync = createAsyncThunk(
  "initiatives/table",
  async ({ page, ...rest }, state) => {
    controller = new AbortController();
    var filters = { statuses: [], goals: [], dates: [], owners: [] };

    const variables = {
      input: {
        offset: (page - 1) * PerPageThen,
        limit: PerPageThen,
      },
      filter: { ...filters, ...rest },
    };
    //  filter flexible from backend if empty
    const response = await graphqlRequest(GET_INITIATIVES_QUERY, variables);

    return response.getInitiativeTable;
  }
);

export const getInitiativesOwnersAsync = createAsyncThunk(
  "initiatives/owners",
  async (action, thunkApi) => {
    controller = new AbortController();
    const response = await graphqlRequest(GET_INITIATIVE_OWNERS_QUERY);
    return response.getInitiativeOwners;
  }
);

export const getOwnerInitiative = createAsyncThunk(
  "owner/initiatives",
  async (action, thunkApi) => {
    controller = new AbortController();
    const variables = {
      input: {
        ownerEmailAddress: action.ownerEmailAddress,
        offset: action.offset,
        limit: action.limit,
      },
      filter: {
        statuses: action.statuses || [],
        goals: action.goals || [],
        dates: action.dates || [],
      },
    };
    const response = await graphqlRequest(
      GET_INITIATIVES_GRID_QUERY,
      variables
    );
    return response.getInitiativeGrid;
  }
);

export const getDeletedInitiatives = createAsyncThunk(
  "initiativeCard/getDeleted",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.get(`${BASE_URL}/initiative/recycle-bin`, {
      headers: {
        "x-workforce": workforceId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const deleteAllDeletedInitiativesAsync = createAsyncThunk(
  "recycleBinInitiatives/delete",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.delete(
      `${BASE_URL}/initiative/recycle/empty-recycle-bin`,
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const createInitiativeAsync = createAsyncThunk(
  "initiative/create",
  async (action, state) => {
    const variables = {
      input: { ...action },
    };
    const response = await graphqlRequest(
      CREATE_INITIATIVE_MUTATION,
      variables
    );
    return response.createInitiative;
  }
);

export const updateInitiativeAsync = createAsyncThunk(
  "initiative/update",
  async (action, state) => {
    const variables = {
      input: { ...action },
    };
    const response = await graphqlRequest(
      UPDATE_INITIATIVE_MUTATION,
      variables
    );
    return response.updateInitiative;
  }
);

export const deleteInitiativeAsync = createAsyncThunk(
  "initiative/delete",
  async (action, state) => {
    const response = await graphqlRequest(DELETE_INITIATIVE_MUTATION, {
      input: { id: action.initiativeId },
    });
    return response;
  }
);

export const restoreInitiativeAsync = createAsyncThunk(
  "initiative/recover",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.patch(
      `${BASE_URL}/initiative/recover/${action.initiativeId}`,
      {},
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getInitiativeFiltersAsync = createAsyncThunk(
  "filters/get",
  async (action, { getState }) => {
    const response = await graphqlRequest(GET_INITIATIVE_FILTERS_QUERY);
    return response.getInitiativeFilters;
  }
);

const initialState = {
  initiativesLoading: false,

  initiativeFilters: {},
  initiativeFiltersLoading: false,

  initiativesOwners: [],
  initiativesOwnersLoading: false,
  initiativesGridPage: 1,
  initiativesByOwnerEmail: {},
  initiativesLoadingByOwnerEmail: {},

  initiativesTable: {},

  initiativesTableLoading: false,
  initiativesCounts: 0,
  initiatives: [],
  deletedInitiativesLoading: false,

  initFilterTags: [],
  initFilterGoals: [],

  error: {},
};

const initiativesSlice = createSlice({
  name: "initiatives",
  initialState,
  reducers: {
    resetInitiatives(state) {
      state.initiativesOwners = [];
      state.initiativesOwnersLoading = false;
      state.initiativesByOwnerEmail = {};
      state.initiativesLoadingByOwnerEmail = {};
      state.initiativesTable = {};
      state.initiativesTableLoading = false;
      state.initiativesCounts = 0;
      state.initiativeFilters = {};
    },
    sortInitiatives: (state, action) => {
      const { data, page } = action.payload;
      state.initiativesTable[page] = data;
    },
    addFilterTag(state, action) {
      state.initFilterTags = action.payload;
    },
    addFilterGoal(state, action) {
      state.initFilterGoals = action.payload;
    },
    abortRequest() {
      if (controller) {
        controller.abort();
      }
    },
    setInitiativeCount(state, action) {
      state.initiativesCounts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitiativesTableAsync.pending, (state, action) => {
        state.error = {};
        state.initiativesTableLoading = true;
      })
      .addCase(getInitiativesTableAsync.fulfilled, (state, action) => {
        const page = action.meta.arg.page;
        state.error = {};
        state.initiativesTableLoading = false;
        state.initiativesCounts = action.payload?.total;

        if (action.payload?.initiatives) {
          state.initiativesTable = {
            [page]: action.payload?.initiatives,
          };
        }
      })
      .addCase(getInitiativesTableAsync.rejected, (state, action) => {
        if (action.error.message !== "canceled") {
          state.initiativesTableLoading = false;

          state.error.initiativesTable =
            action.error.message || `Something went wrong`;
        }
      })
      .addCase(getInitiativesOwnersAsync.pending, (state, action) => {
        state.initiativesOwnersLoading = true;
      })
      .addCase(getInitiativesOwnersAsync.fulfilled, (state, action) => {
        state.initiativesOwnersLoading = false;
        state.initiativesOwners = [...action.payload?.owners];
      })
      .addCase(getInitiativesOwnersAsync.rejected, (state, action) => {
        state.initiativesOwnersLoading = false;
        state.error.initiativesOwners =
          action.error.message || `Something went wrong`;
      })
      .addCase(getOwnerInitiative.pending, (state, action) => {
        const email = action.meta.arg.ownerEmailAddress;

        state.initiativesLoadingByOwnerEmail[email] = true;
      })
      .addCase(getOwnerInitiative.rejected, (state, action) => {
        const email = action.meta.arg.ownerEmailAddress;
        state.initiativesLoadingByOwnerEmail[email] = false;
      })
      .addCase(getOwnerInitiative.fulfilled, (state, action) => {
        const email = action.meta.arg.ownerEmailAddress;
        const offset = action.meta.arg?.offset || 0;

        state.initiativesByOwnerEmail[email] = !offset
          ? [action.payload.initiatives]
          : [
              ...state.initiativesByOwnerEmail[email],
              action.payload.initiatives,
            ];
        state.initiativesLoadingByOwnerEmail[email] = false;
      })

      .addCase(getDeletedInitiatives.pending, (state, action) => {
        state.error = {};
        state.initiativesLoading = true;
      })
      .addCase(getDeletedInitiatives.fulfilled, (state, action) => {
        state.initiatives = action.payload.initiatives;
        state.initiativesStatusCount = action.payload.counts;
        state.initiativesLoading = false;
      })
      .addCase(getDeletedInitiatives.rejected, (state, action) => {
        state.initiativesLoading = false;
        state.error.initiatives = `Something went wrong`;
      })
      .addCase(deleteAllDeletedInitiativesAsync.pending, (state, action) => {
        state.error = {};
        state.deletedInitiativesLoading = true;
      })
      .addCase(deleteAllDeletedInitiativesAsync.fulfilled, (state, action) => {
        state.initiatives = [];
        state.initiativesStatusCount.deletedCount = 0;
        state.deletedInitiativesLoading = false;
      })
      .addCase(deleteAllDeletedInitiativesAsync.rejected, (state, action) => {
        state.deletedInitiativesLoading = false;
        state.error.initiatives = `Something went wrong`;
      })
      .addCase(updateInitiativeAsync.fulfilled, (state, action) => {
        // const index = state.initiatives.findIndex(
        //   (item) => item.id === action.payload.data.id
        // );
        // state.initiatives[index] = action.payload.data;
        const index = state.initiatives.findIndex(
          (item) => item.id === action.meta.arg.id
        );
        state.initiatives[index] = {
          id: action.meta.arg.id,
          ...state.initiatives[index],
          ...action.payload,
        }; // fetch from backend
      })
      .addCase(deleteInitiativeAsync.fulfilled, (state, action) => {
        const data = action.payload.deleteInitiative;
        state.initiatives = state.initiatives.filter(
          (initiative) => initiative.id !== data.id
        );
        //state.initiativesStatusCount = data.counts;
      })
      .addCase(restoreInitiativeAsync.fulfilled, (state, action) => {
        state.initiatives = state.initiatives.filter(
          (initiative) => initiative.id !== action.payload.id
        );
        state.initiativesStatusCount = action.payload.counts;
      })
      .addCase(getInitiativeFiltersAsync.pending, (state, action) => {
        state.initiativeFiltersLoading = true;
      })
      .addCase(getInitiativeFiltersAsync.fulfilled, (state, action) => {
        state.initiativeFilters = action.payload;
        state.initiativeFiltersLoading = false;
      })
      .addCase(getInitiativeFiltersAsync.rejected, (state, action) => {
        state.initiativeFiltersLoading = false;
      });
  },
});

export const {
  resetInitiatives,
  sortInitiatives,
  addFilterTag,
  addFilterGoal,
  abortRequest,
  setInitiativeCount,
} = initiativesSlice.actions;

//Selectors
//Grid view
export const getInitiativesOwners = (state) =>
  state.initiatives.initiativesOwners;
export const getInitiativesOwnersLoading = (state) =>
  state.initiatives.initiativesOwnersLoading;
//table view
export const getInitiativesCount = (state) =>
  state.initiatives.initiativesCounts;
export const getInitiativesTable = (state) =>
  state.initiatives.initiativesTable;
export const getInitiativesTableLoading = (state) =>
  state.initiatives.initiativesTableLoading;
export const getInitiativesTablePage = (state) =>
  state.initiatives.initiativesTablePage;
export const getInitiativesTableError = (state) =>
  state.initiatives.error.initiativesTable;
//grid view
export const getInitiativesByOwnerEmail = (state) =>
  state.initiatives.initiativesByOwnerEmail;
export const getInitiativesLoadingByOwnerEmail = (state) =>
  state.initiatives.initiativesLoadingByOwnerEmail;

export const getInitiativesLoading = (state) =>
  state.initiatives.initiativesLoading;
export const getDeletedInitiativesLoading = (state) =>
  state.initiatives.deletedInitiativesLoading;

export const getInitiativeFilters = (state) =>
  state.initiatives.initiativeFilters;
export const getInitiativeFiltersLoading = (state) =>
  state.initiatives.initiativeFiltersLoading;

export const getInitFilterTags = (state) => state.initiatives.initFilterTags;
export const getInitFilterGoals = (state) => state.initiatives.initFilterGoals;

export const getInitiativesError = (state) => state.initiatives.error;

export default initiativesSlice.reducer;
