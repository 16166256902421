import "./ErrorLabel.scss";
import { useTranslation } from "react-i18next";
const ErrorLabel = ({ title }) => {
  const { t } = useTranslation();

  return (
    <label className={"error-label"}>
      {title} {t("required", { ns: ["global"] })}
    </label>
  );
};

export default ErrorLabel;
