import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/IndexDB";
import { GET_USERS_QUERY } from "../../../gql/graphqlQuery/get-users.generated";
import { GET_INITIATIVE_PEOPLE_QUERY } from "../../../gql/graphqlQuery/get-initiative-people.generated";
import graphqlRequest from "../../../graphqlClient";

const initialState = {
  peoples: [],
  initiativePeoples: [],
  employeeUsers: [],
  employeeUsersTotal: 0,
  employeeLoading: true,
  peoplesLoading: true,
  initiativesPeoples: [],
  initFilterCount: 0,
  tagFilterData: [],
  ownerFilterData: [],
  selectInitTags: [],
  selectFilterTags: [],
  filterTags: [],
  filterOwners: [],
  filterActivity: "",
  filterTagsCount: 0,

  errors: {},
};

export const getPeoplesAsync = createAsyncThunk(
  "peoples/get",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.get(
      `${BASE_URL}/organizations/users?page=1&perPage=100`,
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getInitiativePeoplesAsync = createAsyncThunk(
  "initiativePeoples/get",
  async (action, state) => {
    const response = await graphqlRequest(GET_INITIATIVE_PEOPLE_QUERY, {
      initiativeId: action.initID,
    });
    return response.getInitiativePeople;
  }
);

export const getInitiativesPeoplesAsync = createAsyncThunk(
  "initiativesPeoples/get",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.get(
      `${BASE_URL}/initiative/filters/owners/${action.initID}`,
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getEmployeeUsersAsync = createAsyncThunk(
  "employeeUsers/get",
  async (params, state) => {
    const variables = {
      input: {
        limit: params.perPage,
        offset: (params.page - 1) * params.perPage,
      },
    };
    const response = await graphqlRequest(GET_USERS_QUERY,variables);
    return response.getUsers;
  }
);

const empowerSlice = createSlice({
  name: "empower",
  initialState,
  reducers: {
    emptyEmployeeUsers: (state) => {
      state.employeeUsers = [];
      state.employeeUsersTotal = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPeoplesAsync.pending, (state, action) => {
        state.peoplesLoading = true;
      })
      .addCase(getPeoplesAsync.fulfilled, (state, action) => {
        state.peoplesLoading = false;
        state.peoples = action.payload;
      })
      .addCase(getPeoplesAsync.rejected, (state, action) => {
        state.peoplesLoading = false;
        state.errors.people = `Something went wrong`;
      })
      .addCase(getEmployeeUsersAsync.pending, (state, action) => {
        state.employeeLoading = true;
      })
      .addCase(getEmployeeUsersAsync.fulfilled, (state, action) => {
        state.employeeLoading = false;
        state.employeeUsers =
          action.meta.arg.page === 1
            ? action.payload
            : [...state.employeeUsers, ...action.payload];
        state.employeeUsersTotal = action.payload.length;
      })
      .addCase(getEmployeeUsersAsync.rejected, (state, action) => {
        state.employeeLoading = false;
        state.errors.employeeUsers = `Something went wrong`;
      })

      .addCase(getInitiativePeoplesAsync.pending, (state, action) => {
        state.peoplesLoading = true;
      })
      .addCase(getInitiativePeoplesAsync.fulfilled, (state, action) => {
        state.peoplesLoading = false;
        state.initiativePeoples = action.payload;
      })
      .addCase(getInitiativePeoplesAsync.rejected, (state, action) => {
        state.peoplesLoading = false;
        state.errors.people = `Something went wrong`;
      })

      .addCase(getInitiativesPeoplesAsync.pending, (state, action) => {
        state.peoplesLoading = true;
      })
      .addCase(getInitiativesPeoplesAsync.fulfilled, (state, action) => {
        state.peoplesLoading = false;
        state.initiativesPeoples = action.payload.people;
      })
      .addCase(getInitiativesPeoplesAsync.rejected, (state, action) => {
        state.peoplesLoading = false;
        state.errors.people = `Something went wrong`;
      });
  },
});

export const { emptyEmployeeUsers } = empowerSlice.actions;

//Selectors
export const getEmpower = (state) => state.empower;
export const getPeoples = (state) => state.empower.peoples;
export const getInitiativePeoples = (state) => state.empower.initiativePeoples;
export const getEmployeeUsers = (state) => state.empower.employeeUsers;
export const getEmployeeUsersTotal = (state) =>
  state.empower.employeeUsersTotal;
export const getEmployeeUsersLoading = (state) => state.empower.employeeLoading;
export const getEmployeeUsersErrors = (state) =>
  state.empower.errors.employeeUsers;
export const getPeoplesLoading = (state) => state.empower.peoplesLoading;
export const getInitiativesPeoples = (state) =>
  state.empower.initiativesPeoples;
export const getEmpowerErrors = (state) => state.empower.errors;
export default empowerSlice.reducer;
