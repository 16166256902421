import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/IndexDB";
import { convertToTitleCase } from "../../../Utils";

const initialState = {
  userList: [],
  userListLoading: false,
  taskData: {},
  initiativeData: {},
  taskStatus: {},
  initiativeStatus: {},
  taskFilters: { value: { goals: [], status: [] } },
  initiativeFilters: { value: { goals: [], status: [] } },
  loading: false,
  error: {},
  dataLoadingByUserEmail: {},
  customFields: [],
  customFieldNames: [],
  employeeUsers: [],
  employeeUsersTotal: 0,
  employeeLoading: true,
  customFiltersRows: 0,
  filterChangesCount: 0,
  customFieldChanged: 0,
  taskCustomFields: {},
  initiativeCustomFields: {},
  taskUsersCount: 0,
  initiativeUsersCount: 0,
  savedCustomFilters: 0,
};

export const getUsers = createAsyncThunk(
  "teamsView/users",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.get(`${BASE_URL}/teams-view/users`, {
      headers: {
        "x-workforce": workforceId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getUserTasks = createAsyncThunk(
  "teamsView/tasks3",
  async (action, state) => {
    const startTime = new Date().getTime();
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;

    try {
      const response = await axios.post(
        `${BASE_URL}/teams-view/user-tasks`,
        {
          ownerEmailAddress: action?.email,
          offset: action?.offset || 0,
          limit: 100,
          filter: state.getState().teamView.taskFilters.value,
        },
        {
          headers: {
            "x-workforce": workforceId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      console.log(err);
      return Promise.reject(err.response.data);
    }
  }
);

export const getUserInitiatives = createAsyncThunk(
  "teamsView/initiatives",
  async (action, state) => {
    const startTime = new Date().getTime();
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;

    try {
      const response = await axios.post(
        `${BASE_URL}/teams-view/user-initiatives`,
        {
          ownerEmailAddress: action?.email,
          offset: action?.offset || 0,
          limit: 100,
          filter: state.getState().teamView.initiativeFilters.value,
        },
        {
          headers: {
            "x-workforce": workforceId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      console.log(err);
      return Promise.reject(err.response.data);
    }
  }
);

export const getFilters = createAsyncThunk(
  "teamsView/filters",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const type = action.isTaskView ? "task" : "initiative";
    const response = await axios.get(
      `${BASE_URL}/teams-view/filters?type=${type}`,
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const saveFilters = createAsyncThunk(
  "teamsView/saveFilters",
  async (action, state) => {
    console.log(
      "IN state.getState().teamView.taskFilters",
      state.getState().teamView.taskFilters
    );

    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const filterData = action.isTaskView
      ? state.getState().teamView.taskFilters
      : state.getState().teamView.initiativeFilters;
    const data = { ...filterData.value };
    data["customFields"] = action.isTaskView
      ? state.getState().teamView.taskCustomFields
      : state.getState().teamView.initiativeCustomFields;

    const response = await axios.patch(
      `${BASE_URL}/teams-view/filter/${filterData.id}`,
      {
        type: action.isTaskView ? "task" : "initiative",
        filters: action.clearValues ? {} : data,
      },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getCustomFields = createAsyncThunk(
  "teamsView/customFields",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.get(`${BASE_URL}/teams-view/custom-fields`, {
      headers: {
        "x-workforce": workforceId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getGoalFilters = createAsyncThunk(
  "filters/get",
  async (action, { getState }) => {
    const workforceId = getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.get(`${BASE_URL}/initiative/filters`, {
      headers: {
        "x-workforce": workforceId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getEmployeeUsersAsync = createAsyncThunk(
  "employeeUsers/get",
  async (params, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.get(`${BASE_URL}/employees/users`, {
      headers: {
        "x-workforce": workforceId,
      },
      params,
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const teamViewSlice = createSlice({
  name: "teamsView",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      var props = action.payload;

      if (props.isTaskView) {
        state.taskFilters.value[props.key] = state.taskFilters.value[props.key]
          ? [...state.taskFilters.value[props.key], props.value]
          : [props.value];
      } else {
        state.initiativeFilters.value[props.key] = state.initiativeFilters
          .value[props.key]
          ? [...state.initiativeFilters.value[props.key], props.value]
          : [props.value];
      }
      if (props.key != "people")
        state.filterChangesCount = state.filterChangesCount + 1;
      else state.customFieldChanged = state.customFieldChanged + 1;
    },
    removeFilter: (state, action) => {
      var props = action.payload;
      if (props.isTaskView) {
        state.taskFilters.value[props.key] = state.taskFilters.value[
          props.key
        ].filter((filterValue) => props.value != filterValue);
      } else {
        state.initiativeFilters.value[props.key] =
          state.initiativeFilters.value[props.key].filter(
            (filterValue) => props.value != filterValue
          );
      }
      if (props.key != "people")
        state.filterChangesCount = state.filterChangesCount + 1;
      else state.customFieldChanged = state.customFieldChanged + 1;
    },
    removePeopleFilter: (state, action) => {
      var props = action.payload;
      if (props.isTaskView) {
        state.taskFilters.value[props.key] = state.taskFilters.value[
          props.key
        ].filter(
          (filterValue) =>
            props.value[props.itemKey] != filterValue[props.itemKey]
        );
      } else {
        state.initiativeFilters.value[props.key] =
          state.initiativeFilters.value[props.key].filter(
            (filterValue) =>
              props.value[props.itemKey] != filterValue[props.itemKey]
          );
      }
      state.filterChangesCount = state.filterChangesCount + 1;
    },

    setDateFilter: (state, action) => {
      var props = action.payload;
      if (props.isTaskView) {
        state.taskFilters.value[props.key] = new Date(
          props.value
        ).toISOString();
      } else {
        state.initiativeFilters.value[props.key] = new Date(
          props.value
        ).toISOString();
      }
      state.filterChangesCount = state.filterChangesCount + 1;
    },
    addCustomField: (state, action) => {
      var props = action.payload;
      if (props.isTaskView) {
        state.taskCustomFields[props.key] = state.taskCustomFields[props.key]
          ? [...state.taskCustomFields[props.key], props.value]
          : [props.value];
      } else {
        state.initiativeCustomFields[props.key] = state.initiativeCustomFields[
          props.key
        ]
          ? [...state.initiativeCustomFields[props.key], props.value]
          : [props.value];
      }
      state.customFieldChanged = state.customFieldChanged + 1;
    },
    removeCustomField: (state, action) => {
      var props = action.payload;
      var updatedFilters = [];

      if (props.isTaskView) {
        updatedFilters =
          state.taskCustomFields[props.key] &&
          state.taskCustomFields[props.key].filter(
            (filterValue) => props.value.name != filterValue.name
          );
        if (props.deleteField || updatedFilters.length == 0) {
          delete state.taskCustomFields[props.key];
          state.filterChangesCount = state.filterChangesCount + 1;
        } else state.taskCustomFields[props.key] = updatedFilters;
      } else {
        updatedFilters =
          state.initiativeCustomFields[props.key] &&
          state.initiativeCustomFields[props.key].filter(
            (filterValue) => props.value.name != filterValue.name
          );
        if (props.deleteField || updatedFilters.length == 0) {
          delete state.initiativeCustomFields[props.key];
          state.filterChangesCount = state.filterChangesCount + 1;
        } else state.initiativeCustomFields[props.key] = updatedFilters;
      }
      state.customFieldChanged = state.customFieldChanged - 1;
    },
    resetUserDataCount: (state) => {
      state.taskUsersCount = 0;
      state.initiativeUsersCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserTasks.pending, (state, action) => {
        const offset = action.meta.arg?.offset;
        if (!offset) state.upcomingTasksLoading = true;
        const email = action.meta.arg.email;
        state.dataLoadingByUserEmail[email] = true;
      })
      .addCase(getUserTasks.fulfilled, (state, action) => {
        const offset = action.meta.arg?.offset;
        state.upcomingTasksLoading = false;
        const email = action.meta.arg.email;
        const data = action.payload;
        if (data && data.count) {
          data.count = data?.count?.map(elt => {
            elt.status = convertToTitleCase(elt.status)
            return elt;
          })
        }
        state.taskData[email] = !offset
          ? data
          : [...state.taskData[email], data];
        state.taskUsersCount =
          state.taskUsersCount + data["totalTaskCount"];
        state.dataLoadingByUserEmail[email] = false;
      })
      .addCase(getUserTasks.rejected, (state, action) => {
        state.upcomingTasksLoading = false;
        const email = action.meta.arg.email;
        state.dataLoadingByUserEmail[email] = false;
      })
      .addCase(getUserInitiatives.pending, (state, action) => {
        const offset = action.meta.arg?.offset;
        if (!offset) state.upcomingTasksLoading = true;
        const email = action.meta.arg.email;

        state.dataLoadingByUserEmail[email] = true;
      })
      .addCase(getUserInitiatives.fulfilled, (state, action) => {
        const offset = action.meta.arg?.offset;
        state.upcomingTasksLoading = false;
        const email = action.meta.arg.email;
        const data = action.payload;
        if (data && data.count) {
          data.count = data?.count?.map(elt => {
            elt.status = convertToTitleCase(elt.status)
            return elt;
          })
        }
        state.initiativeData[email] = !offset
          ? data
          : [...state.initiativeData[email], data];
        state.dataLoadingByUserEmail[email] = false;
        state.initiativeUsersCount =
          state.initiativeUsersCount + data["totalInitiativeCount"];
      })
      .addCase(getUserInitiatives.rejected, (state, action) => {
        state.upcomingTasksLoading = false;
        const email = action.meta.arg.email;
        state.dataLoadingByUserEmail[email] = false;
      })
      .addCase(getUsers.pending, (state, action) => {
        state.userListLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.userListLoading = false;
        state.userList = action.payload.users;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.userListLoading = false;
        state.errors = `Something went wrong`;
      })
      .addCase(getFilters.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getFilters.fulfilled, (state, action) => {
        const isTaskView = action.meta.arg?.isTaskView;
        if (isTaskView) {
          state.taskFilters = action.payload;
          state.taskCustomFields = action.payload.value["customFields"] || {};
        } else {
          state.initiativeFilters = action.payload;
          state.initiativeCustomFields =
            action.payload.value["customFields"] || {};
        }
        state.savedCustomFilters = state.savedCustomFilters + 1;
        state.loading = false;
      })
      .addCase(getFilters.rejected, (state, action) => {
        state.loading = false;
        state.errors = `Something went wrong`;
      })
      .addCase(saveFilters.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(saveFilters.fulfilled, (state, action) => {
        const isTaskView = action.meta.arg?.isTaskView;
        const clearValues = action.meta.arg?.clearValues;
        if (clearValues) {
          if (isTaskView) {
            state.taskFilters.value = {};
            state.taskCustomFields = {};
          } else {
            state.initiativeFilters.value = {};
            state.initiativeCustomFields = {};
          }
          state.filterChangesCount = state.filterChangesCount + 1;
        }
        state.loading = false;
      })
      .addCase(saveFilters.rejected, (state, action) => {
        state.loading = false;
        state.errors = `Something went wrong`;
      })
      .addCase(getCustomFields.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCustomFields.fulfilled, (state, action) => {
        state.loading = false;
        state.customFields = action.payload;
        state.customFieldNames = action.payload.map((elt) => elt.name);
      })
      .addCase(getCustomFields.rejected, (state, action) => {
        state.loading = false;
        state.errors = `Something went wrong`;
      })
      .addCase(getGoalFilters.pending, (state, action) => {
        state.initiativeFiltersLoading = true;
      })
      .addCase(getGoalFilters.fulfilled, (state, action) => {
        state.goalfilters = action.payload.goals;
        state.initiativeFiltersLoading = false;
      })
      .addCase(getGoalFilters.rejected, (state, action) => {
        state.initiativeFiltersLoading = false;
      })
      .addCase(getEmployeeUsersAsync.pending, (state, action) => {
        state.employeeLoading = true;
        state.userListLoading = true;
      })
      .addCase(getEmployeeUsersAsync.fulfilled, (state, action) => {
        state.employeeLoading = false;
        state.userListLoading = false;
        state.loading = true;

        state.employeeUsers =
          action.meta.arg.page === 1
            ? action.payload.data
            : [...state.employeeUsers, ...action.payload.data];
        state.employeeUsersTotal = action.payload.total;
      })
      .addCase(getEmployeeUsersAsync.rejected, (state, action) => {
        state.employeeLoading = false;
        state.userListLoading = false;

        state.errors.employeeUsers = `Something went wrong`;
      });
  },
});

export const {
  setFilter,
  removeFilter,
  removePeopleFilter,
  setDateFilter,
  addCustomField,
  removeCustomField,
  resetUserDataCount,
} = teamViewSlice.actions;

export const getUserList = (state) => state.teamView.userList;
export const getUserListLoading = (state) => state.teamView.userListLoading;
export const getUserTaskData = (state) => state.teamView.taskData;
export const getUserInitiativeData = (state) => state.teamView.initiativeData;
export const getDataLoadingByUserEmail = (state) =>
  state.teamView.dataLoadingByUserEmail;
export const getEmployeeUsers = (state) => state.teamView.employeeUsers;
export const getGoalOptions = (state) => state.teamView.goalfilters;
export const getCustomFieldValues = (state) => state.teamView.customFields;
export const getCustomFieldNames = (state) => state.teamView.customFieldNames;
export const getSavedTaskFilters = (state) => state.teamView.taskFilters;
export const getSavedInitiativeFilters = (state) =>
  state.teamView.initiativeFilters;
export const getAllEmployees = (state) => state.teamView.employeeUsers;
export const filterChanged = (state) => state.teamView.filterChangesCount;
export const getTaskCustomFields = (state) => state.teamView.taskCustomFields;
export const getInitiativeCustomFields = (state) =>
  state.teamView.initiativeCustomFields;
export const customFilterChanged = (state) => state.teamView.customFieldChanged;
export const getTaskUsersCount = (state) => state.teamView.taskUsersCount;
export const getInitiativeUsersCount = (state) =>
  state.teamView.initiativeUsersCount;
export const savedCustomFiltersSet = (state) =>
  state.teamView.savedCustomFilters;
export const isApiLoading = (state) => state.teamView.loading;

export default teamViewSlice.reducer;
