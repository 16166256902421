import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/IndexDB";
import { createGoalAsync, deleteGoalAsync } from "../settings";
import { PerPageThen } from "../../../Constants/PerPage";
import deepcopy from "deepcopy";
import { getHomeTemplatesAsync } from "../home";

const initialState = {
  systemErrors: [],
  systemErrorsCount: 0,
  systemErrorsLoading: true,

  goals: [],
  goalsCount: 0,
  goalsLoading: true,

  templates: [],
  templatesLoading: true,

  template: null,
  templateLoading: true,

  templateImages: [],
  templateImagesLoading: false,

  orgSessionList: {},
  orgSessionListLoading: true,

  useSessionList: [],
  useSessionListLoading: true,

  error: {},
};

export const getSystemErrorsAsync = createAsyncThunk(
  "systemErrors/get",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    try {
      const startTime = new Date().getTime();
      const response = await axios.get(
        `${BASE_URL}/error-logs?page=${action?.page}&perPage=${PerPageThen}`,
        {
          headers: {
            "x-workforce": workforceId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const deleteErrorsHistoryAsync = createAsyncThunk(
  "deleteErrorsHistory/delete",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.delete(`${BASE_URL}/error-logs`, {
      headers: {
        "x-workforce": workforceId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getGeneralGoalsAsync = createAsyncThunk(
  "goals/get",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    try {
      const orgID = state.getState().user.user.orgID;
      const startTime = new Date().getTime();
      const response = await axios.get(
        `${BASE_URL}/goals/?all=true&orgID=${orgID}`,
        {
          headers: {
            "x-workforce": workforceId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getTemplatesAsync = createAsyncThunk(
  "templates/get",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    try {
      const response = await axios.get(`${BASE_URL}/templates`, {
        headers: {
          "x-workforce": workforceId,
        },
      });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getTemplateAsync = createAsyncThunk(
  "template/get",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const response = await axios.get(
      `${BASE_URL}/templates/${action.templateId}`,
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    return response.data;
  }
);

export const createTemplateAsync = createAsyncThunk(
  "templates/createOne",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const response = await axios.post(
      `${BASE_URL}/templates`,
      { ...action },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    return response.data;
  }
);

export const bulkCreateTemplateAsync = createAsyncThunk(
  "templates/bulkCreate",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const response = await axios.post(
      `${BASE_URL}/templates/bulk`,
      { ...action },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    return response.data;
  }
);

export const updateTemplateAsync = createAsyncThunk(
  "templates/updateOne",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const response = await axios.patch(
      `${BASE_URL}/templates/${action.id}`,
      { ...action },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    return response.data;
  }
);

export const bulkUpdateTemplateAsync = createAsyncThunk(
  "templates/bulkUpdate",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const response = await axios.put(
      `${BASE_URL}/templates`,
      { ...action },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    return response.data;
  }
);

export const deleteTemplateAsync = createAsyncThunk(
  "template/delete",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const response = await axios.delete(
      `${BASE_URL}/templates/${action.templateId}`,
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    return response.data;
  }
);

export const getTemplateImagesAsync = createAsyncThunk(
  "templateImages/get",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.get(`${BASE_URL}/templates/images`, {
      headers: {
        "x-workforce": workforceId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const createTemplateImagesAsync = createAsyncThunk(
  "templateImages/post",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.post(
      `${BASE_URL}/templates/images`,
      { ...action },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const deleteTemplateImagesAsync = createAsyncThunk(
  "templateImage/delete",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.delete(
      `${BASE_URL}/templates/images/${action.id}`,
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getOrgSessionListAsync = createAsyncThunk(
  "orgSessionList/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;

    const response = await axios.post(
      `${BASE_URL}/dashboard-analytics`,
      {
        ...action,
      },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getUserSessionListAsync = createAsyncThunk(
  "userSessionList/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const response = await axios.post(
      `${BASE_URL}/dashboard-analytics/user-session-list`,
      {
        ...action,
      },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const setTemplateToHome = createAsyncThunk(
  "home/addTemplateToHome",
  async (action, state) => {
    const startTime = new Date().getTime();
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    try {
      const response = await axios.patch(
        `${BASE_URL}/templates/home/${action.id}`,
        { action: action.action },
        {
          headers: {
            "x-workforce": workforceId,
          },
        }
      );

      state.dispatch(getHomeTemplatesAsync());

      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      return Promise.reject(err.response.data);
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    resetAdmin: () => initialState,
    sortErrorLog: (state, action) => {
      state.errorLogs = action.payload;
    },
    emptyTemplate(state) {
      state.template = null;
      state.templateLoading = false;
      state.templateImages = [];
      state.templateImagesLoading = false;
      state.templatesLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSystemErrorsAsync.pending, (state, action) => {
        state.error = {};
        state.systemErrorsLoading = true;
      })
      .addCase(getSystemErrorsAsync.fulfilled, (state, action) => {
        state.systemErrors = action.payload?.errors;
        state.systemErrorsCount = action.payload?.count;
        state.systemErrorsLoading = false;
      })
      .addCase(getSystemErrorsAsync.rejected, (state, action) => {
        state.systemErrorsLoading = false;
        state.error.systemErrors = `${action?.payload?.statusCode} - ${action?.payload?.message}`;
      })
      .addCase(deleteErrorsHistoryAsync.fulfilled, (state, action) => {
        state.systemErrors = [];
        state.systemErrorsCount = 0;
      })
      .addCase(getGeneralGoalsAsync.pending, (state, action) => {
        state.error = {};
        state.goalsLoading = true;
      })
      .addCase(getGeneralGoalsAsync.fulfilled, (state, action) => {
        state.goals = action.payload;
        state.goalsLoading = false;
      })
      .addCase(getGeneralGoalsAsync.rejected, (state, action) => {
        state.error.goals = `${action?.payload?.statusCode} - ${action?.payload?.message}`;
        state.goalsLoading = false;
      })
      .addCase(getTemplatesAsync.pending, (state, action) => {
        state.error = {};
        state.templatesLoading = true;
      })
      .addCase(getTemplatesAsync.fulfilled, (state, action) => {
        state.templates = action.payload?.templates;
        state.templatesLoading = false;
      })
      .addCase(getTemplatesAsync.rejected, (state, action) => {
        state.templatesLoading = false;
        state.error.templates = `${action?.payload?.statusCode} - ${action?.payload?.message}`;
      })
      .addCase(getTemplateAsync.pending, (state, action) => {
        state.error = {};
        state.templateLoading = true;
      })
      .addCase(getTemplateAsync.fulfilled, (state, action) => {
        state.template = action.payload;
        state.templateLoading = false;
      })
      .addCase(getTemplateAsync.rejected, (state, action) => {
        state.templateLoading = false;
        state.error.template = "Something went wrong";
      })
      .addCase(setTemplateToHome.fulfilled, (state, action) => {})
      .addCase(setTemplateToHome.rejected, (state, action) => {
        const actionType = action.meta.arg.action;
        const id = action.meta.arg.id;
        const editedTemplates = state.templates.map((template) => {
          if (template.id === id) {
            return {
              ...template,
              isHomeTemplate: actionType !== "ADD",
            };
          }
          return template;
        });
        state.templates = editedTemplates;
      })
      .addCase(setTemplateToHome.pending, (state, action) => {
        const actionType = action.meta.arg.action;
        const id = action.meta.arg.id;
        const editedTemplates = state.templates.map((template) => {
          if (template.id === id) {
            return {
              ...template,
              isHomeTemplate: actionType === "ADD",
            };
          }
          return template;
        });
        state.templates = [...editedTemplates];
      })

      .addCase(deleteTemplateAsync.fulfilled, (state, action) => {
        const templates = deepcopy(state.templates);
        state.templates = templates.filter(
          (template) => template.id !== action.payload.id
        );
      })

      .addCase(getTemplateImagesAsync.pending, (state, action) => {
        state.error = {};
        state.templateImagesLoading = true;
      })
      .addCase(getTemplateImagesAsync.fulfilled, (state, action) => {
        state.templateImages = action.payload;
        state.templateImagesLoading = false;
      })
      .addCase(getTemplateImagesAsync.rejected, (state, action) => {
        state.templateImagesLoading = false;
        state.error.template = "Something went wrong";
      })
      .addCase(createTemplateImagesAsync.pending, (state, action) => {
        state.templateImagesLoading = true;
      })
      .addCase(createTemplateImagesAsync.fulfilled, (state, action) => {
        state.templateImages = [action.payload, ...state.templateImages];
        state.templateImagesLoading = false;
      })
      .addCase(createTemplateImagesAsync.rejected, (state, action) => {
        state.templateImagesLoading = false;
      })
      .addCase(deleteTemplateImagesAsync.pending, (state, action) => {
        state.templateImagesLoading = true;
      })
      .addCase(deleteTemplateImagesAsync.fulfilled, (state, action) => {
        const templateImages = deepcopy(state.templateImages);
        state.templateImages = templateImages.filter(
          (templateImage) => templateImage.id !== action.payload.id
        );
        state.templateImagesLoading = false;
      })
      .addCase(deleteTemplateImagesAsync.rejected, (state, action) => {
        state.templateImagesLoading = false;
      })

      .addCase(createGoalAsync.fulfilled, (state, action) => {
        state.goals = [action.payload.data, ...state.goals];
      })
      .addCase(deleteGoalAsync.fulfilled, (state, action) => {
        state.goals = state.goals.filter(
          (gaol) => gaol.id !== action.payload.id
        );
      })
      .addCase(getOrgSessionListAsync.pending, (state, action) => {
        state.orgSessionListLoading = true;
      })
      .addCase(getOrgSessionListAsync.fulfilled, (state, action) => {
        state.orgSessionList = action.payload;
        state.orgSessionListLoading = false;
      })
      .addCase(getOrgSessionListAsync.rejected, (state, action) => {
        state.orgSessionListLoading = false;
        state.error.orgSessionList = action.error.message;
      })
      .addCase(getUserSessionListAsync.pending, (state, action) => {
        state.useSessionListLoading = true;
      })
      .addCase(getUserSessionListAsync.fulfilled, (state, action) => {
        state.useSessionList = action.payload.sessions;
        state.useSessionListLoading = false;
      })
      .addCase(getUserSessionListAsync.rejected, (state, action) => {
        state.useSessionListLoading = false;
        state.error.userSessionList = action.error.message;
      });
  },
});

export const { resetAdmin, sortErrorLog, emptyTemplate } = adminSlice.actions;

//Selectors
export const getSystemErrors = (state) => state.admin.systemErrors;
export const getSystemErrorsCount = (state) => state.admin.systemErrorsCount;
export const getSystemErrorsLoading = (state) =>
  state.admin.systemErrorsLoading;

export const getGoals = (state) => state.admin.goals;
export const getGoalsCount = (state) => state.admin.goalsCount;
export const getGoalsLoading = (state) => state.admin.goalsLoading;

export const getTemplates = (state) => state.admin.templates;
export const getTemplatesLoading = (state) => state.admin.templatesLoading;

export const getTemplate = (state) => state.admin.template;
export const getTemplateLoading = (state) => state.admin.templateLoading;

export const getTemplateImages = (state) => state.admin.templateImages;
export const getTemplateImagesLoading = (state) =>
  state.admin.templateImagesLoading;

export const getUserSessionList = (state) => state.admin.useSessionList;
export const getUserSessionListLoading = (state) =>
  state.admin.useSessionListLoading;
export const getOrgSessionList = (state) => state.admin.orgSessionList;
export const getOrgSessionListLoading = (state) =>
  state.admin.orgSessionListLoading;

export const getAdminErrors = (state) => state.admin.error;

export default adminSlice.reducer;
