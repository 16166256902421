// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 25px;
  width: 128px;
  height: 80px;
  border: 2px dotted #1b87e6;
  font-size: 10px;
  cursor: pointer;
  color: #545e6b;
}
.file-uploader--browse {
  color: #1b87e6;
}`, "",{"version":3,"sources":["webpack://./src/Components/Reusable/CustomFileUplader/CustomFileUploader.scss","webpack://./src/Assets/styles/variables.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,YAAA;EACA,0BAAA;EACA,eAAA;EACA,eAAA;EACA,cCdgB;ADYlB;AAIE;EACE,cCfc;ADalB","sourcesContent":["@import \"src/Assets/styles/variables\";\n@import \"src/Assets/styles/mixins\";\n\n.file-uploader {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  line-height: 25px;\n  width: 128px;\n  height: 80px;\n  border: 2px dotted $BASE-BLUE-COLOR;\n  font-size: 10px;\n  cursor: pointer;\n  color: $BASE-GREY-COLOR;\n\n  &--browse {\n    color: $BASE-BLUE-COLOR;\n  }\n}\n","$BASE-GREY-COLOR: #545e6b;\n$BASE-LIGHT-GREY-COLOR: #9b9b9b;\n$BASE-BLUE-COLOR: #1b87e6;\n$BASE-DARK-BLUE-COLOR: #54a5ec;\n$BASE-BG-COLOR: #f5f5f5;\n$BASE-WHITE-COLOR: #ffffff;\n$BASE-BORDER-COLOR: #d8d8d8;\n$BASE-HEADER-HEIGHT: 48px;\n$BASE-MOBILE-HEADER-HEIGHT: 100px;\n$BASE-NAVBAR-WIDTH: 168px;\n$BASE-LARGE-SIZE: 24px;\n$MODAL-MIDDLE-SIZE: 16px;\n$MODAL-Regular-SIZE: 18px;\n$BASE-FONT-SIZE: 12px;\n$BASE-ERROR-COLOR: #e53251;\n$BASE-SUCCESS-COLOR: #3fca5a;\n$BASE-NORMAL-COLOR: #f0b400;\n$MAIN-GRAY-COLOR: #454545;\n$MAIN-BLUE-COLOR: #1b3380;\n$MAIN-BORDER-COLOR: #ddd;\n\n$large-l: 1440px;\n$large: 1024px;\n$medium: 768px;\n$small-L: 590px;\n$small: 425px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
