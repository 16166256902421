export const TEAMSVIEW_GET_USERTASKS_QUERY = `query UserTasks($input: TeamsViewInput!) {
  userTasks(input: $input) {
    tasks {
      initiativeId
      initiativeName
      taskCount
      task {
        id
        name
        status
        dueDate
        responsible {
          userID
          emailAddress
          color
          username
        }
        people {
          userID
          emailAddress
          color
          username
        }
      }
    }
    count {
      status
      total
    }
    totalTaskCount
  }
}
`;
