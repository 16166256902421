export const DELETE_HUDDLE_MUTATION = `
mutation deleteHuddle($input: HuddleIdInput!) {
  deleteHuddle(deleteHuddleInput: $input) {
    id
    success
    message
  }
}

`;
