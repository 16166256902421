export const CREATE_INITIATIVE_MUTATION = `
    mutation createInitiative($input: CreateInitiativeInput!) {
  createInitiative(createInitiativeInput: $input) {
    id
    name
    year
    id
    name
    description
    year
    status
    orgID
    createdAt
    owner {
      emailAddress
      color
      username
      userID
    }
    people {
      emailAddress
      color
      userID
      username
    }
    deleted
    completed_at
    streamType
  }
}
    `;
