import { useEffect, useState } from "react";
import "./IdeationResults.scss";
import { useSelector } from "react-redux";
import { ReactComponent as TopVote } from "../../../Assets/Icons/TopVote.svg";
import { getExplore, getIdeation } from "../../../Store/slices/ideation";
import { getClassNameByParam, strongCopyData, tableSort } from "../../../Utils";
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableRow,
} from "@questionprodev/shared-components";
import IdeationResultsDefaultsIdea from "../IdeationResultsDefaultsIdea/IdeationResultsDefaultsIdea";
import IdeationResultsCrowdsourcesIdea from "../IdeationResultsCrowdsourcesIdea/IdeationResultsCrowdsourcesIdea";
import { useTranslation } from "react-i18next";
import NoData from "../../Reusable/NoData/NoData";

const IdeationResults = () => {
  const explore = useSelector(getExplore);
  const ideation = useSelector(getIdeation);
  const { t } = useTranslation();

  const [isShowIdeas] = useState(true);
  const [crowdsourcesIdeas, setCrowdsourcesIdeas] = useState([]);

  const sortIdeas = (name, type) => {
    const errorLogsCopy = strongCopyData(crowdsourcesIdeas);
    const sort = tableSort(errorLogsCopy, name, type);
    setCrowdsourcesIdeas(sort);
  };

  useEffect(() => {
    const crowdsourcesIdeas = [];
    explore?.questions?.forEach((idea) => {
      crowdsourcesIdeas.push(...idea.ideas);
    });
    setCrowdsourcesIdeas(crowdsourcesIdeas);
  }, [explore]);

  return (
    <div className={"idea-flow-results"}>
      <div className={"idea-flow-results--header"}>
        <p className={"idea-flow-results--header--introduction"}>
          {ideation?.introduction}
        </p>
        <p className={"idea-flow-results--header--question"}>
          {ideation?.allowIdeas && ideation?.questions[0].question}
        </p>
      </div>
      <div className={"idea-flow-results--top-ideas-progress"}>
        <div className={"idea-flow-results--top-ideas"}>
          <div className={"idea-flow-results--top-ideas--title-block"}>
            <TopVote
              width={18}
              height={24}
              fill={"#1b87e6"}
              className={"idea-flow-results--top-ideas--icon"}
            />
            <p className={"idea-flow-results--top-ideas-progress--title"}>
              {t("topIdeas", { ns: ["ideation"] })}
            </p>
          </div>

          <div className={"idea-flow-results--top-ideas--content"}>
            {explore.topIdeas.map((topIdea, index) => (
              <div
                key={topIdea.id}
                className={"idea-flow-results--top-ideas--item"}
              >
                <div className={"idea-flow-results--top-ideas--item-block-1"}>
                  <span className={"idea-flow-results--top-ideas--item-index"}>
                    #{index + 1}
                  </span>
                  <span className={"idea-flow-results--top-ideas--item-name"}>
                    {topIdea?.text}
                  </span>
                </div>
                <div className={"idea-flow-results--top-ideas--item-block-2"}>
                  <span
                    className={"idea-flow-results--top-ideas--item-vote-count"}
                  >
                    {topIdea?.votesCount}{" "}
                    {topIdea?.votesCount > 1
                      ? `${t("votes", { ns: ["global"] })}`
                      : `${t("vote", { ns: ["global"] })}`}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={"idea-flow-results--progress"}>
          <p className={"idea-flow-results--top-ideas-progress--title"}>
            {t("engagement", { ns: ["ideation"] })}
          </p>

          <div className={"idea-flow-results--progress--content"}>
            <div className={"idea-flow-results--progress--block"}>
              <span className={"idea-flow-results--progress--result"}>
                {explore?.participants}
              </span>
              <span>
                {explore?.participants != 1
                  ? `${t("participants", { ns: ["ideation"] })}`
                  : `${t("participant", { ns: ["ideation"] })}`}
              </span>
            </div>
            <div className={"idea-flow-results--progress--block"}>
              <span className={"idea-flow-results--progress--result"}>
                {explore?.ideasCounts}
              </span>
              <span>
                {explore?.ideasCounts != 1
                  ? `${t("ideas", { ns: ["ideation"] })}`
                  : `${t("idea", { ns: ["ideation"] })}`}{" "}
              </span>
            </div>
            <div className={"idea-flow-results--progress--block"}>
              <span className={"idea-flow-results--progress--result"}>
                {explore?.comments}
              </span>
              <span>
                {explore?.comments != 1
                  ? `${t("comments", { ns: ["ideation"] })}`
                  : `${t("comment", { ns: ["ideation"] })}`}{" "}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`idea-flow-results--ideas ${getClassNameByParam(
          isShowIdeas,
          "open-ideas"
        )}`}
      >
        <div className={"idea-flow-results--ideas--header"}>
          <p className={"idea-flow-results--ideas--title"}>
            {t("promotedIdeas", { ns: ["ideation"] })}
          </p>
        </div>

        <div className={"idea-flow-results--ideas--block"}>
          {explore?.defaultIdeas?.length ? (
            explore.defaultIdeas.map((defaultIdea) => (
              <IdeationResultsDefaultsIdea
                key={defaultIdea.id}
                defaultIdea={defaultIdea}
              />
            ))
          ) : (
            <div className={"idea-flow-results--ideas--no-data"}>
              <NoData />
            </div>
          )}
        </div>

        <div className={"idea-flow-results--crowdsources-ideas"}>
          <Table>
            <TableHead>
              <TableRow>
                <TableData
                  th={true}
                  isSort={true}
                  sortBy={(e) => sortIdeas("text", e)}
                >
                  {t("ideas", { ns: ["ideation"] })}
                </TableData>

                <TableData
                  th={true}
                  isSort={true}
                  sortBy={(e) => sortIdeas("votesCount", e)}
                >
                  {t("votes", { ns: ["global"] })}
                </TableData>
              </TableRow>
            </TableHead>
            <TableBody>
              {crowdsourcesIdeas?.length ? (
                crowdsourcesIdeas?.map((crowdsourcesIdea) => (
                  <IdeationResultsCrowdsourcesIdea
                    crowdsourcesIdea={crowdsourcesIdea}
                    key={crowdsourcesIdea.id}
                  />
                ))
              ) : (
                <TableRow>
                  <TableData colSpan={2}>
                    <NoData />
                  </TableData>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default IdeationResults;
