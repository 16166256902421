import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/IndexDB";

const initialState = {
  user: [],
  userLoading: true,
  selectedLanguage: "en",

  productSwitcher: [],
  productSwitcherWorkforcePermission: true,
  productSwitcherEmpowerPermission: true,

  workforces: [],
  workforcesLoading: true,

  selectedWorkforceName: null,
  selectedWorkforce: {},
  emailDisabled: true,

  errors: {},
  prevPath: "/",
};

export const getUserAsync = createAsyncThunk(
  "user/get",
  async (action, state) => {
    try {
      const startTime = new Date().getTime();
      const response = await axios.get(`${BASE_URL}/oauth/users/me`);
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getProductSwitcherAccessAsync = createAsyncThunk(
  "/product-switcher/get",
  async (_, state) => {
    try {
      const startTime = new Date().getTime();
      const response = await axios.get(`${BASE_URL}/product-switcher`);
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getWorkforceAsync = createAsyncThunk(
  "/workforces/get",
  async (_, state) => {
    try {
      const startTime = new Date().getTime();
      const response = await axios.get(
        `${BASE_URL}/workforces?page=1&perPage=100`
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const updateLanguageOrEmailDisabledAsync = createAsyncThunk(
  "feedbacks/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const response = await axios.post(
      `${BASE_URL}/user-settings`,
      {
        ...action,
      },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const userSwitch = createAsyncThunk(
  "user/post",
  async (emailDisabled) => {
    const data = {
      emailDisabled: emailDisabled,
    };

    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      `${BASE_URL}/user-settings`,
      data,
      headers
    );
    return response.data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    changeWorkforceName: (state, action) => {
      state.selectedWorkforceName = action.payload;
    },
    sortWorkforces: (state, action) => {
      state.workforces = action.payload;
    },
    setSelectWorkforce: (state, action) => {
      state.selectedWorkforce = action.payload;
    },
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    setPrevpath: (state, action) => {
      state.prevPath = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAsync.pending, (state, action) => {
        state.userLoading = true;
        state.errors.user = null;
      })
      .addCase(getUserAsync.fulfilled, (state, action) => {
        // TODO for test/ remove later
        if (
          action.payload.data.emailAddress === "templates@questionpro.com" ||
          action.payload.data.emailAddress ===
            "heghine.mkrtchyan@questionpro.com"
        ) {
          state.user = { ...action.payload.data, isAdmin: true };
        } else {
          state.user = action.payload.data;
        }
        state.selectedLanguage = action.payload.data.language;
        state.userLoading = false;
      })
      .addCase(getUserAsync.rejected, (state, action) => {
        state.userLoading = false;
        state.errors.user = action.payload
          ? `${action.payload?.statusCode} - ${action.payload?.message}`
          : `Something went wrong`;
      })
      .addCase(getProductSwitcherAccessAsync.pending, (state, action) => {})
      .addCase(getProductSwitcherAccessAsync.fulfilled, (state, action) => {
        const permissions =
          action.payload.headerInfo[0] &&
          action.payload.headerInfo[0].productSwitcher?.categories;
        permissions?.forEach((products) => {
          if (products?.name === "Workforce" && !products?.active) {
            state.productSwitcherWorkforcePermission = false;
          }
          if (products?.name === "Customer Experience") {
            products?.product?.forEach((product) => {
              if (product.name === "Empower" && !product.active) {
                state.productSwitcherEmpowerPermission = false;
              }
            });
          }
        });
        state.productSwitcher = permissions;
      })
      .addCase(getWorkforceAsync.pending, (state, action) => {
        state.workforcesLoading = true;
        state.errors.workforce = null;
      })
      .addCase(getWorkforceAsync.fulfilled, (state, action) => {
        const workforces = action.payload.response;
        const workforce = workforces.find(
          (workforce) =>
            workforce?.workforceID === state.user.lastVisitedWorkforceID
        );
        state.selectedWorkforceName = workforce?.workforceName;
        state.workforces = workforces;
        state.selectedWorkforce = workforce;
        state.workforcesLoading = false;
      })
      .addCase(getWorkforceAsync.rejected, (state, action) => {
        state.workforcesLoading = false;

        if (action.payload.statusCode !== 403) {
          state.errors.workforce = action.payload.message;
        }
      });
  },
});

export const {
  changeWorkforceName,
  sortWorkforces,
  setSelectWorkforce,
  setSelectedLanguage,
  setPrevpath,
} = userSlice.actions;

//Selectors

export const getCurrentUser = (state) => state.user.user;
export const getSelectedLanguage = (state) => state.user.selectedLanguage;
export const getPrevPath = (state) => state.user.prevPath;

export const getUserLoading = (state) => state.user.userLoading;
export const getProductSwitcher = (state) => state.user.productSwitcher;
export const getProductSwitcherWorkforcePermission = (state) =>
  state.user.productSwitcherWorkforcePermission;
export const getProductSwitcherEmpowerPermission = (state) =>
  state.user.productSwitcherEmpowerPermission;
export const getWorkforces = (state) => state.user.workforces;
export const getSelectedWorkforceName = (state) =>
  state.user.selectedWorkforceName;
export const getWorkforcesLoading = (state) => state.user.workforcesLoading;
export const getUserErrors = (state) => state.user.errors;
export default userSlice.reducer;
