import axios from "axios";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ADMIN_TOKEN_NAME, BASE_URL, PORTAL_TOKEN_NAME, REFRESH_TOKEN, TOKEN_NAME } from "../../Constants/Api";
import { setCookies } from "../../Utils/cookies";
import { generateAdminTokenAsync, generateTokenAsync } from "../../Store/slices/user";
import { useDispatch } from "react-redux";
import { getIsAdminUI, isAdminUI } from "../../Utils";

function Auth() {
  let query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const code = query.get("code");

  const generateTokenPortal = useCallback(async () => {
    if (code) {
      const data = await dispatch(
        generateTokenAsync({ code: encodeURIComponent(code) })
      );

      if (data.payload?.generateToken?.access_token) {
        setCookies("token", data?.payload.generateToken.access_token);
        setCookies(PORTAL_TOKEN_NAME, data?.payload.generateToken.access_token);
        setCookies(TOKEN_NAME, data?.payload.generateToken.access_token);
        setCookies(REFRESH_TOKEN, data?.payload.generateToken.refresh_token);
        axios.defaults.headers.common.Authorization = `Bearer ${data?.payload.generateToken.access_token}`;
        window.location.href = `${window.location.origin}/initiatives`;
      }
    }
  }, []);

  const generateTokenAdmin =  useCallback(async () => {
    if (code) {
      const data = await dispatch(
        generateAdminTokenAsync({ code: encodeURIComponent(code) })
      );

      if (data.payload?.generateAdminToken?.access_token) {
        setCookies("token", data?.payload.generateAdminToken.access_token);
        setCookies(ADMIN_TOKEN_NAME, data?.payload.generateAdminToken.access_token);
        setCookies(TOKEN_NAME, data?.payload.generateAdminToken.access_token);
        setCookies(REFRESH_TOKEN, data?.payload.generateAdminToken.refresh_token);
        axios.defaults.headers.common.Authorization = `Bearer ${data?.payload.generateAdminToken.access_token}`;
        window.location.href = `${window.location.origin}/initiatives`;
      }
    }
  }, []);

  useEffect(() => {
    if (getIsAdminUI())
      generateTokenAdmin()
    else
      generateTokenPortal();
  }, [code]);

  return <div></div>;
}

export default Auth;
