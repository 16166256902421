export const GET_UPCOMING_TASKS_QUERY = `
    query upcomingTasks($args: PaginationInput!) {
  getUpcomingTasks(PaginationInput: $args) {
    initiatives {
      id
      name
      status
      tasks {
        id
        name
        status
        dueDate
        responsible {
          userID
          emailAddress
          color
          username
        }
        people {
          emailAddress
          color
          username
          userID
        } 
      }
    }
  }
}
`;
