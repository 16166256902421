export const GET_TOP_GOALS_QUERY = `
    query getTopGoals($topGoalsInput: TopGoalsInput!) {
  topGoals(topGoalsInput: $topGoalsInput) {
    topGoals {
      goal
      count
      percent
      color
    }
  }
}
`;
