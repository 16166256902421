export const CREATE_TASK_MUTATION = `
    mutation createTask($input: CreateTaskInput!) {
  createTask(createTaskInput: $input) {
    id
    initiativeId
    name
    description
    dueDate
    completed_at
    status
  }
}
    `;
