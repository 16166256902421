export const GET_INITIATIVE_QUERY = `
    query getInitiative($input: GetInitiativeInput!) {
  getInitiative(GetInitiativeInput: $input) {
    id
    name
    description
    year
    status
    orgID
    createdAt
    createdBy
    owner {
      emailAddress
      color
      username
      userID
    }
    people {
      emailAddress
      color
      userID
      username
    }
    deleted
    completed_at
    streamType
  }
}
`;
