export const UPDATE_HUDDLE_MUTATION = `mutation updateHuddle($input: UpdateHuddleInput!) {
    updateHuddle(updateHuddleInput: $input) {
        id
        createdAt
        updatedAt
        descriptionHTML
        description
        people {
            emailAddress
            color
            userID
            username
        }
    }
}
`;
