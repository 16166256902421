export const GET_IDEATIONS_QUERY = `query getIdeations($input: GetIdeationsInput!) {
    getIdeations(getIdeationsInput: $input) {
        ideations{
            id
            createdAt
            updatedAt
            introduction
            name
            participants
            votesPerParticipant
            allowedActions
            status
            logo
            actions
            allowIdeas
            allowIdeasComments
            allowDefaultIdeas
            createdAt
            updatedAt
            questions {
                id
                question
            }
            url
            actionList{
                id
            }
            commentsAndFeedbacksCount
        }
        count
    }
}
`;
