import Switch from "react-switch";
import "./CustomSwitch.scss";

const CustomSwitch = ({ isChecked, handleChange, disabled, label }) => {
  return (
    <label htmlFor={label} className="custom-switch">
      <span>{label}</span>
      <Switch
        disabled={disabled}
        checked={isChecked}
        onChange={handleChange}
        onColor="#86d3ff"
        onHandleColor="#2693e6"
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={10}
        width={30}
        className="react-switch"
        id={label}
      />
    </label>
  );
};

export default CustomSwitch;
