export const GET_TOP_CONTRIBUTOR_QUERY = `
    query getTopContributors($topContributorInput: DateIntervalInput!) {
  topContributors(topContributorInput: $topContributorInput) {
    topContributors {
      contributor {
        color
        emailAddress
        username
      }
      count
    }
  }
}
`;
