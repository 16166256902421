import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/IndexDB";
import { PerPageThen } from "../../../Constants/PerPage";
import { strongCopyData } from "../../../Utils";
import graphqlRequest from "../../../graphqlClient";
import { GET_IDEATIONS_QUERY } from "../../../gql/graphqlQuery/get-ideations.generated";
import { CREATE_IDEATION_MUTATION } from "../../../gql/graphqlQuery/create-ideation.generated";
import { GET_IDEATION_QUERY } from "../../../gql/graphqlQuery/get-ideation.generated";
import { GET_TOP_IDEAS_IDEATION_QUERY } from "../../../gql/graphqlQuery/get-top-ideas-in-ideation.generated";
import { UPDATE_IDEATION_MUTATION } from "../../../gql/graphqlQuery/update-ideation.generated";
import { DELETE_IDEATIONS_MUTATION } from "../../../gql/graphqlQuery/delete-ideations.generated";

const initialState = {
  ideations: [],
  ideationsLoading: true,
  ideationCount: 0,
  ideationPage: 1,

  ideation: null,
  ideationLoading: true,

  explore: null,
  exploreLoading: true,

  ideas: [],
  ideasVotes: [],
  createIdeaLoading: false,

  voteIdeaLoading: false,

  errors: {},
};

export const getIdeationsAsync = createAsyncThunk(
  "ideations/get",
  async (action, state) => {
    const variables = {
      input: {
        initiativeId: action.initID,
        limit: PerPageThen,
        offset: (action.page - 1) * PerPageThen,
      },
    };
    const response = await graphqlRequest(GET_IDEATIONS_QUERY, variables);
    return response.getIdeations;
  }
);

export const getIdeationAsync = createAsyncThunk(
  "ideation/get",
  async (action, state) => {
    try {
      const variables = {
        input: action.ideationId,
      };
      const response = await graphqlRequest(GET_IDEATION_QUERY, variables);
      return response.getIdeation;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getExploreAsync = createAsyncThunk(
  "getExploreAsync/get",
  async (action, state) => {
    const variables = {
      topIdeasInIdeationInput: action.ideationId,
    };
    const response = await graphqlRequest(
      GET_TOP_IDEAS_IDEATION_QUERY,
      variables
    );
    return response.topIdeasInIdeation;
  }
);

export const createIdeationsAsync = createAsyncThunk(
  "ideations/post",
  async (action, state) => {
    const variables = {
      input: { ...action },
    };
    const response = await graphqlRequest(CREATE_IDEATION_MUTATION, variables);
    return response.createIdeation;
  }
);

export const updateIdeationsAsync = createAsyncThunk(
  "ideations/patch",
  async (action, state) => {
    const variables = {
      input: { id: action.ideationId, ...action.ideationData },
    };
    const response = await graphqlRequest(UPDATE_IDEATION_MUTATION, variables);
    return { data: response.updateIdeation, type: action.type };
  }
);

export const deleteIdeationAsync = createAsyncThunk(
  "ideation/delete",
  async (action, state) => {
    const variables = {
      input: action.ids,
    };
    const response = await graphqlRequest(DELETE_IDEATIONS_MUTATION, variables);
    return response.deleteIdeations;
  }
);

export const createIdeationQuestionAsync = createAsyncThunk(
  "question/post",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.post(
      `${BASE_URL}/ideation-questions`,
      {
        ...action,
      },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const createIdeationIdeaAsync = createAsyncThunk(
  "createIdeas/post",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.post(
      `${BASE_URL}/idea/default`,
      { ...action },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

//------------------------------------------------------------------

export const getPreviewIdeationAsync = createAsyncThunk(
  "previewIdeation/get",
  async (action, state) => {
    const response = await axios.get(
      `${BASE_URL}/ideation/preview/${action.ideationId}?sessionUserId=${action.sessionUserId}`
    );
    return response.data;
  }
);

export const getDraftIdeationAsync = createAsyncThunk(
  "draftIdeation/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios.get(
      `${BASE_URL}/ideation/preview-draft/${action.ideationId}?orgID=${user.orgID}`
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const createIdeaAsync = createAsyncThunk(
  "createIdeaAsync/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const response = await axios.post(`${BASE_URL}/idea`, { ...action });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);
export const voteIdeaAsync = createAsyncThunk(
  "voteIdeaAsync/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const response = await axios.post(`${BASE_URL}/ideas-vote`, {
      ...action,
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return action;
  }
);

export const commentIdeaAsync = createAsyncThunk(
  "commentIdeaAsync/post",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.post(
      `${BASE_URL}/ideas-comment`,
      { ...action },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const userVoteSuccessAsync = createAsyncThunk(
  "userVoteSuccessAsync/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const response = await axios.post(`${BASE_URL}/ideation/confirm`, {
      ...action,
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const addNewParticipantAsync = createAsyncThunk(
  "addNewParticipantAsync/patch",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.patch(
      `${BASE_URL}/ideation/participants/${action.ideationId}`,
      {},
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const ideationSlice = createSlice({
  name: "ideation",
  initialState,
  reducers: {
    resetIdeation: () => initialState,
    sortIdeation: (state, action) => {
      state.ideations = action.payload;
    },
    changeIdeation: (state, action) => {
      state.ideation[action.payload.title] = action.payload.value;
    },
    addNewDraftIdea(state, action) {
      state.ideas = [action.payload, ...state.ideas];
    },
    addNewIdeaComment: (state, action) => {
      const ideasIndex = state.ideas.findIndex(
        (idea) => idea.id === action.payload.id
      );
      const copyData = state.ideation.defaultIdeas;

      const draftIdeasIndex = copyData.findIndex(
        (idea) => idea.id === action.payload.id
      );
      if (ideasIndex >= 0) {
        state.ideas[ideasIndex].comments.unshift(action.payload);
      }

      if (draftIdeasIndex >= 0) {
        state.ideation.defaultIdeas[draftIdeasIndex].comments.unshift(
          action.payload
        );
      }
    },
    addNewVote: (state, action) => {
      state.ideasVotes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIdeationsAsync.pending, (state, action) => {
        state.ideationsLoading = true;
        state.errors.ideations = null;
      })
      .addCase(getIdeationsAsync.fulfilled, (state, action) => {
        state.ideationsLoading = false;
        state.ideations = action.payload.ideations;
        state.ideationCount = action.payload?.count;
        state.ideationPage = action.meta?.arg?.page;
      })
      .addCase(getIdeationsAsync.rejected, (state, action) => {
        state.initiativeError = action.payload?.message;
        state.ideationsLoading = false;
      })
      .addCase(getIdeationAsync.pending, (state, action) => {
        state.ideationLoading = true;
        state.errors.ideation = null;
      })
      .addCase(getIdeationAsync.fulfilled, (state, action) => {
        state.ideation = action.payload;
        state.ideationLoading = false;
      })
      .addCase(getIdeationAsync.rejected, (state, action) => {
        state.ideationLoading = false;
        state.errors.ideation = action.payload?.message;
      })

      .addCase(getExploreAsync.pending, (state, action) => {
        state.exploreLoading = true;
        state.errors.explore = null;
      })
      .addCase(getExploreAsync.fulfilled, (state, action) => {
        state.explore = action.payload;
        state.exploreLoading = false;
      })
      .addCase(getExploreAsync.rejected, (state, action) => {
        state.exploreLoading = false;
        state.errors.explore = "Something went wrong";
      })

      .addCase(createIdeationsAsync.pending, (state, action) => {
        state.ideationsLoading = true;
      })
      .addCase(createIdeationsAsync.fulfilled, (state, action) => {
        state.ideationsLoading = false;
        state.ideation = action.payload;
      })
      .addCase(createIdeationsAsync.rejected, (state, action) => {
        state.ideationsLoading = false;
      })
      .addCase(updateIdeationsAsync.fulfilled, (state, action) => {
        if (action?.payload?.type) {
          state.ideation = {
            ...state.ideation,
            [action.payload?.type]: action.payload.data[action.payload.type],
          };
        }
      })
      .addCase(deleteIdeationAsync.fulfilled, (state, action) => {
        let ideationCopy = strongCopyData(state.ideations);

        state.ideations = ideationCopy.filter(
          (ideation) =>
            ideation.id !== action.meta.arg.ids.find((el) => el === ideation.id)
        );
      })
      .addCase(getPreviewIdeationAsync.pending, (state, action) => {
        state.ideationLoading = true;
      })
      .addCase(getPreviewIdeationAsync.fulfilled, (state, action) => {
        const questions = action.payload.questions;
        const combineQuestions = [];
        for (let i = 0; i < questions.length; i++) {
          combineQuestions.push(...questions[i].ideas);
        }
        state.ideation = action.payload;
        state.ideas = combineQuestions;
        state.ideationLoading = false;
      })
      .addCase(getPreviewIdeationAsync.rejected, (state, action) => {
        state.errors.previewIdeation = "Something went wrong";
        state.ideationLoading = false;
      })
      .addCase(getDraftIdeationAsync.pending, (state, action) => {
        state.ideationLoading = true;
      })
      .addCase(getDraftIdeationAsync.fulfilled, (state, action) => {
        const questions = action.payload.questions;
        const combineQuestions = [];
        for (let i = 0; i < questions.length; i++) {
          combineQuestions.push(...questions[i].ideas);
        }
        state.ideation = action.payload;
        state.ideas = combineQuestions;
        state.ideationLoading = false;
      })
      .addCase(getDraftIdeationAsync.rejected, (state, action) => {
        state.errors.draftIdeation = "Something went wrong";
        state.ideationLoading = false;
      })

      .addCase(createIdeaAsync.pending, (state, action) => {
        state.createIdeaLoading = true;
      })
      .addCase(createIdeaAsync.fulfilled, (state, action) => {
        state.ideas = [action.payload, ...state.ideas];
        state.createIdeaLoading = false;
      })
      .addCase(createIdeaAsync.rejected, (state, action) => {
        state.createIdeaLoading = false;
      })

      .addCase(voteIdeaAsync.pending, (state, action) => {
        state.voteIdeaLoading = true;
      })
      .addCase(voteIdeaAsync.fulfilled, (state, action) => {
        state.voteIdeaLoading = false;
      })
      .addCase(voteIdeaAsync.rejected, (state, action) => {
        state.voteIdeaLoading = false;
        state.requestErrorMessage = "Something went wrong";
      });
  },
});

export const {
  resetIdeation,
  sortIdeation,
  changeIdeation,
  addNewDraftIdea,
  addNewIdeaComment,
  addNewVote,
} = ideationSlice.actions;

//Selectors

export const getIdeations = (state) => state.ideation.ideations;
export const getIdeationsLoading = (state) => state.ideation.ideationsLoading;
export const getIdeationCount = (state) => state.ideation.ideationCount;
export const getIdeationPage = (state) => state.ideation.ideationPage;
export const getIdeation = (state) => state.ideation.ideation;
export const getIdeationLoading = (state) => state.ideation.ideationLoading;
export const getExplore = (state) => state.ideation.explore;
export const getExploreLoading = (state) => state.ideation.exploreLoading;
export const getIdeas = (state) => state.ideation.ideas;
export const getIdeasVotes = (state) => state.ideation.ideasVotes;
export const getCreateIdeaLoading = (state) => state.ideation.createIdeaLoading;
export const getVoteIdeaLoading = (state) => state.ideation.voteIdeaLoading;
export const getErrors = (state) => state.ideation.errors;
export default ideationSlice.reducer;
