import React from "react";
import "./NoData.scss";
import { ReactComponent as NoDataIcon } from "../../../Assets/Icons/NoData.svg";
import { useTranslation } from "react-i18next";

const NoData = () => {
  const { t } = useTranslation();

  return (
    <div className={"no-data-content"}>
      <NoDataIcon />
      <p> {t("noData", { ns: ["global"] })}</p>
    </div>
  );
};

export default NoData;
