export const TEAMSVIEW_GET_CUSTOM_FIELDS_QUERY = `query {
  customFields {
    id
    name
    options {
      id
      customFieldId
      customFieldName
      name
    }
  }
}
`;
