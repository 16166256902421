import axios from "axios";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL, TOKEN_NAME } from "../../Constants/Api";
import { setCookies } from "../../Utils/cookies";

function Auth() {
  let query = useQuery();
  const navigate = useNavigate();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const code = query.get("code");

  useEffect(() => {
    let host =
      "www.empower.questionpro.com" ||
      "empower.questionpro.com" ||
      "www.empower-frontend-us-dev.herokuapp.com" ||
      "empower-frontend-us-dev.herokuapp.com";
    if (
      host === window.location.host &&
      window.location.protocol !== "https:"
    ) {
      window.location.protocol = "https";
    }
    axios
      .get(`${BASE_URL}/authorization/callback/generateToken?code=${code}`)
      .then((res) => {
        if (res.data.access_token) {
          setCookies(TOKEN_NAME, res.data.access_token);
        }
      })
      .then(() => {
        navigate("/workforces");
      });
  }, [code, navigate]);

  return <div></div>;
}

export default Auth;
