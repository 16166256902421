import parse from "html-react-parser";
import deepcopy from "deepcopy";

const checkValidation = (object) => {
  let errors = {};
  let error = false;
  for (const property in object) {
    if (typeof object[property] === "string" && !object[property].trim()) {
      errors[property] = true;
      error = true;
    }

    if (typeof object[property] === "object" && !object[property].length) {
      errors[property] = true;
      error = true;
    }
  }
  return { errors, error };
};

const filterOrAddItem = ({ item, oldData }) => {
  if (
    oldData?.some(
      (data) =>
        (data?.emailAddress ||
          data?.id ||
          data?.answerID ||
          data.departmentID) ===
        (item?.emailAddress || item?.id || item?.answerID || item.departmentID)
    )
  ) {
    return oldData.filter(
      (data) =>
        (data?.emailAddress ||
          data?.id ||
          data?.answerID ||
          data.departmentID) !==
        (item?.emailAddress || item?.id || item?.answerID || item.departmentID)
    );
  } else {
    return [...oldData, item];
  }
};

const getHighlightedText = ({ keyword, input }) => {
  if (keyword && keyword.length > 0) {
    let searched = keyword.trim();
    let text = input;

    const regex = new RegExp(
      `(<)(script[^>]*>[^<]*(?:<(?!\\/script>)[^<]*)*<\\/script>|\\/?\\b[^<>]+>|!(?:--\\s*(?:(?:\\[if\\s*!IE]>\\s*-->)?[^-]*(?:-(?!->)-*[^-]*)*)--|\\[CDATA[^\\]]*(?:](?!]>)[^\\]]*)*]])>)|(${searched})`,
      "gi"
    );
    const highlight = text.replace(regex, function (match, p1, p2, p3) {
      return p3 ? "<mark>" + p3 + "</mark>" : match;
    });

    return parse(highlight);
  } else {
    return parse(input);
  }
};

const getPageContentByKey = ({ content, key, defaultPage }) => {
  return content[key] ? content[key] : defaultPage;
};

const initiativeSortYear = (initiatives, goal) => {
  const sortInitiatives = [...initiatives].sort(function (a, b) {
    if (a.goal < b.goal) {
      return -1;
    }
    if (a.goal > b.goal) {
      return 1;
    }
    return 0;
  });

  let sortData = [sortInitiatives[0]];

  const newData = [];
  const unclassified = [];

  for (let i = 1; i < sortInitiatives.length; i++) {
    if (sortInitiatives[i].goal === "Unclassified") {
      unclassified.push(sortInitiatives[i]);
    } else {
      if (sortInitiatives[i].goal === sortData[0].goal) {
        sortData.push(sortInitiatives[i]);
      } else {
        newData.push(sortData);
        sortData = [];
        sortData.push(sortInitiatives[i]);
      }
    }
  }

  return [...newData, sortData, unclassified];
};

const strongCopyData = (data) => data && deepcopy(data);

const tableSort = (data, name, type) => {
  return type === "up"
    ? data.sort((a, b) => (a[name] < b[name] ? 1 : b[name] < a[name] ? -1 : 0))
    : data.sort((a, b) => (a[name] > b[name] ? 1 : b[name] > a[name] ? -1 : 0));
};

const triggerClassName = (param1, param2, className) => {
  if (param1 === param2) {
    return className;
  }
  return "";
};

const getClassNameByParam = (param, className) => {
  if (param) {
    return className;
  }
  return "";
};

const filterSameInitiativesOnTags = ({ selectData, allData }) => {
  let data = [];
  selectData?.forEach((selectTag) => {
    const item = allData?.find((tag) => tag.id === selectTag.id);
    data = [...data, ...item?.initiatives];
  });

  const newGoalData = new Set(data);
  return [...newGoalData];
};

const getInitiativeStatusPageCount = ({ initiativesCount, status }) => {
  return Number.isInteger(initiativesCount[`${status.toLowerCase()}Count`])
    ? initiativesCount[`${status.toLowerCase()}Count`]
    : 1;
};

const getPaginationDataAfterDeleteOrCreate = ({
  numberOfPages,
  currentPageDataCount,
  currentPage,
}) => {
  if (currentPage < numberOfPages && numberOfPages > 1) {
    return { page: currentPage };
  }

  if (currentPageDataCount === 0 && numberOfPages > 1) {
    return { page: currentPage - 1 };
  }

  return { page: null };
};

const findAndReturnWordOfArrayInString = (wordArr, string) => {
  return string.split("/").find((str) => wordArr.includes(str));
};

const secondsToHM = (s) => {
  const hours = Math.floor((s % (3600 * 24)) / 3600);
  const minutes = Math.floor((s % 3600) / 60);
  const seconds = Math.floor((s % 3600) % 60);
  if (hours && minutes) {
    return `${hours} hours ${minutes} minutes`;
  }
  if (minutes || seconds > 30) {
    return `${minutes || 1} minutes`;
  }
  return `No login`;
};

const getInitials = (people) => {
  if (people?.firstName && people?.lastName)
    return (
      people?.firstName?.charAt(0).toUpperCase() +
      people?.lastName?.charAt(0).toUpperCase()
    );
  if (people?.username) {
    var names = people.username.split(" ");
    return (
      names[0]?.charAt(0).toUpperCase() +
      (names.length > 1 ? names[1]?.charAt(0).toUpperCase() : "")
    );
  }
  return people?.emailAddress?.charAt(0).toUpperCase() || "";
};

export {
  checkValidation,
  filterOrAddItem,
  getHighlightedText,
  getPageContentByKey,
  initiativeSortYear,
  strongCopyData,
  tableSort,
  triggerClassName,
  getClassNameByParam,
  filterSameInitiativesOnTags,
  getInitiativeStatusPageCount,
  getPaginationDataAfterDeleteOrCreate,
  findAndReturnWordOfArrayInString,
  secondsToHM,
  getInitials,
};
