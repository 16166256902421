import { BrowserRouter } from "react-router-dom";
import { Routers } from "./Routes/index";
import "./Translation/i18n";
import "./Assets/styles/base.scss";
import { indexDB } from "./IndexDB/IndexDB";

function App() {
  indexDB();

  return (
    <BrowserRouter>
      <Routers />
    </BrowserRouter>
  );
}

export default App;
