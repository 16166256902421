import "./IdeationResultsDefaultsIdea.scss";
import IdeationResultsComments from "../IdeationResultsComments/IdeationResultsComments";
import { useTranslation } from "react-i18next";

const IdeationResultsDefaultsIdea = ({ defaultIdea }) => {
  const { t } = useTranslation();

  return (
    <div className={"ideation-default-idea"}>
      <div className={"ideation-default-idea--votes"}>
        <div className={"ideation-default-idea--counts-block"}>
          <span className={"ideation-default-idea--counts"}>
            {defaultIdea?.votesCount}
          </span>
          <span className={"ideation-default-idea--title"}>
            {defaultIdea?.votesCount > 1
              ? `${t("votes", { ns: ["global"] })}`
              : `${t("vote", { ns: ["global"] })}`}
          </span>
        </div>
        <div className={"ideation-default-idea--column"} />

        <div className={"ideation-default-idea--title-block"}>
          {defaultIdea?.text}
        </div>
      </div>
      <IdeationResultsComments idea={defaultIdea} />
    </div>
  );
};

export default IdeationResultsDefaultsIdea;
