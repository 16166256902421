export const UPDATE_TASK_MUTATION = `
    mutation updateTask($input: UpdateTaskInput!) {
  updateTask(updateTaskInput: $input) {
    id
    initiativeId
    name
    description
    dueDate
    completed_at
    status
    people {
      emailAddress
      color
    }
    responsible {
      emailAddress
      color
    }
  }
}
`;
