import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRequestErrorMessage,
  getRequestSuccessesMessage,
  resetStore,
} from "../../../Store/slices/successOrError/successOrError";
import toast, { Toaster } from "react-hot-toast";

const ShowToasterMessage = () => {
  const dispatch = useDispatch();

  const requestSuccessesMessage = useSelector(getRequestSuccessesMessage);
  const requestErrorMessage = useSelector(getRequestErrorMessage);

  useEffect(() => {
    if (requestSuccessesMessage) {
      toast.success(requestSuccessesMessage, {
        style: {
          background: "#DFF2BF",
          color: "#227700",
        },
      });
      dispatch(resetStore());
    }
    if (requestErrorMessage) {
      toast.error(requestErrorMessage, {
        style: {
          background: "#DFF2BF",
          color: "#227700",
        },
      });
      dispatch(resetStore());
    }
  }, [dispatch, requestErrorMessage, requestSuccessesMessage]);

  return <Toaster position="top-center" reverseOrder={false} />;
};

export default ShowToasterMessage;
