export const UPDATE_ORGANIZATION_SETTINGS_MUTATION = `mutation createOrUpdateSettings($data: OrganizationSettingsInput!) {
  createOrUpdateSettings(data: $data) {
    surveyID
    warningPeriod
    errorPeriod
    workforceID
    orgID
  }
}
`;
