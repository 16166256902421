import React from "react";
import { TableData, TableRow } from "@questionpro/shared-components";
import IdeationResultsComments from "../IdeationResultsComments/IdeationResultsComments";

const IdeationResultsCrowdsourcesIdea = ({ crowdsourcesIdea }) => {
  return (
    <TableRow className={"idea-flow-crowdsources-idea"}>
      <TableData>
        <div>{crowdsourcesIdea.text}</div>
        <IdeationResultsComments idea={crowdsourcesIdea} />
      </TableData>
      <TableData>{crowdsourcesIdea.votesCount}</TableData>
    </TableRow>
  );
};

export default IdeationResultsCrowdsourcesIdea;
