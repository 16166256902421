// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.base-modal {
  padding: 0 !important;
}
.base-modal-header {
  position: relative;
  height: 40px;
  text-align: center;
  line-height: 40px;
  text-transform: capitalize;
  color: #545e6b;
  background-color: #e8e8e8;
  font-size: 16px;
}
.base-modal-header--close {
  position: absolute;
  top: 3px;
  right: 13px;
  cursor: pointer;
}
.base-modal .react-responsive-modal-closeButton {
  top: 12px !important;
  right: 12px !important;
}

.react-responsive-modal-modal {
  max-width: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Reusable/BaseModal/BaseModal.scss","webpack://./src/Assets/styles/variables.scss"],"names":[],"mappings":"AAGA;EACE,qBAAA;AAFF;AAGE;EACE,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,0BAAA;EACA,cCXc;EDYd,yBAAA;EACA,eCFgB;ADCpB;AAEI;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,eAAA;AAAN;AAGE;EACE,oBAAA;EACA,sBAAA;AADJ;;AAKA;EACE,2BAAA;AAFF","sourcesContent":["@import \"src/Assets/styles/variables\";\n@import \"src/Assets/styles/mixins\";\n\n.base-modal {\n  padding: 0 !important;\n  &-header {\n    position: relative;\n    height: 40px;\n    text-align: center;\n    line-height: 40px;\n    text-transform: capitalize;\n    color: $BASE-GREY-COLOR;\n    background-color: #e8e8e8;\n    font-size: $MODAL-MIDDLE-SIZE;\n    &--close {\n      position: absolute;\n      top: 3px;\n      right: 13px;\n      cursor: pointer;\n    }\n  }\n  .react-responsive-modal-closeButton {\n    top: 12px !important;\n    right: 12px !important;\n  }\n}\n\n.react-responsive-modal-modal {\n  max-width: unset !important;\n}\n","$BASE-GREY-COLOR: #545e6b;\n$BASE-LIGHT-GREY-COLOR: #9b9b9b;\n$BASE-BLUE-COLOR: #1b87e6;\n$BASE-DARK-BLUE-COLOR: #54a5ec;\n$BASE-BG-COLOR: #f5f5f5;\n$BASE-WHITE-COLOR: #ffffff;\n$BASE-BORDER-COLOR: #d8d8d8;\n$BASE-HEADER-HEIGHT: 48px;\n$BASE-MOBILE-HEADER-HEIGHT: 100px;\n$BASE-NAVBAR-WIDTH: 168px;\n$BASE-LARGE-SIZE: 24px;\n$MODAL-MIDDLE-SIZE: 16px;\n$MODAL-Regular-SIZE: 18px;\n$BASE-FONT-SIZE: 12px;\n$BASE-ERROR-COLOR: #e53251;\n$BASE-SUCCESS-COLOR: #3fca5a;\n$BASE-NORMAL-COLOR: #f0b400;\n$MAIN-GRAY-COLOR: #454545;\n$MAIN-BLUE-COLOR: #1b3380;\n$MAIN-BORDER-COLOR: #ddd;\n\n$large-l: 1440px;\n$large: 1024px;\n$medium: 768px;\n$small-L: 590px;\n$small: 425px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
