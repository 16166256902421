export const BULK_CREATE_TASKS_MUTATION = `
    mutation bulkCreateTasks($createBulkTaskInput: CreateBulkTaskInput!) {
  bulkCreateTasks(createBulkTaskInput: $createBulkTaskInput) {
    id
    initiativeId
    name
    description
    dueDate
    completed_at
    status
  }
}
    `;
