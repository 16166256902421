import React, { useState } from "react";
import "./BaseHeader.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as QSLogo } from "../../Assets/Icons/main-logo.svg";
import { ReactComponent as LogoArrow } from "../../Assets/Icons/logo-arrow.svg";
import { ReactComponent as RightArrow } from "../../Assets/Icons/RightArrow.svg";
import ProductSwitcher from "./ProductSwitcher/ProductSwitcher";
import OutsideClickHandler from "react-outside-click-handler";
import { UserBar } from "./Userbar/UserBar";
import { DropdownComponent } from "../Reusable/DropdownComponent";
import { Helmet } from "react-helmet";
import {
  changeWorkforceName,
  getSelectedWorkforceName,
  getWorkforces,
  setSelectWorkforce,
} from "../../Store/slices/user";
import { getIdeation } from "../../Store/slices/ideation";
import { abortRequest, resetInitiatives } from "../../Store/slices/initiatives";
import { resetInitiativeAnalytics } from "../../Store/slices/initiativeAnalytics";
import { useTranslation } from "react-i18next";

const BaseHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation(["global"]);

  const selectedWorkforceName = useSelector(getSelectedWorkforceName);
  const workforces = useSelector(getWorkforces);
  const ideation = useSelector(getIdeation);

  const [openSwitcher, setOpenSwitcher] = useState(false);

  const goSelectWorkforce = (workforce) => {
    if (workforce.workforceName !== selectedWorkforceName) {
      dispatch(abortRequest());
      dispatch(resetInitiativeAnalytics());
      dispatch(resetInitiatives());
      dispatch(changeWorkforceName(workforce.workforceName));
      dispatch(setSelectWorkforce(workforce));
      navigate({
        pathname: "/home",
      });
    }
  };

  return (
    <header className={`base-header ${openSwitcher ? "base-header-open" : ""}`}>
      <Helmet>
        <title>Empower: {selectedWorkforceName || ""}</title>
        <meta charSet="utf-8" />
      </Helmet>
      <div className={"base-header-wrapper"}>
        <div className={"base-header-wrapper-workforces-block"}>
          <DropdownComponent
            value={
              <div className={"base-header-wrapper-workforce-name"}>
                {selectedWorkforceName || "select Folder Name"}
              </div>
            }
            data={workforces}
            select={goSelectWorkforce}
            isBorderBottom={false}
          />
          <div className={"base-header-ideation-block"}>
            {ideation?.initiative?.name && (
              <>
                <RightArrow fill={"#ffffff"} />
                <p className={"base-header-ideation-name"}>
                  {ideation.initiative.name}
                </p>
              </>
            )}
          </div>
        </div>

        <div className={"base-header-wrapper-actions"}>
          <UserBar />
        </div>
      </div>
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpenSwitcher(false);
        }}
      >
        <div
          className={"base-header-logo"}
          onClick={() => {
            setOpenSwitcher((prevState) => !prevState);
          }}
        >
          <QSLogo width={16} height={16} />
          <h1 className={"base-header-logo-title"}>{t("empower")}</h1>
          <LogoArrow className="base-header-logo-arrow" />
        </div>

        <ProductSwitcher isOpen={openSwitcher} />
      </OutsideClickHandler>
    </header>
  );
};

export default BaseHeader;
