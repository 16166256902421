import React, { useCallback, useMemo, useState } from "react";
import "./IdeationSetup.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ReactComponent as Close } from "../../../Assets/Icons/Close.svg";
import { TextInput } from "@questionpro/shared-components";
import CustomSwitch from "../../Reusable/CustomSwitch/CustomSwitch";
import IdeationSetupIdea from "../IdeationSetupIdea/IdeationSetupIdea";
import CustomFileUploader from "../../Reusable/CustomFileUplader/CustomFileUploader";
import uuid from "react-uuid";
import { FileUploader } from "react-drag-drop-files";
import { DropdownComponent } from "../../Reusable/DropdownComponent";
import { debeounced400 } from "../../../Utils/debounce";
import { uploadDocumentToAWS } from "../../../Utils/evaporate";
import { getCurrentUser } from "../../../Store/slices/user";
import {
  changeIdeation,
  createIdeationIdeaAsync,
  createIdeationQuestionAsync,
  getIdeation,
  updateIdeationsAsync,
} from "../../../Store/slices/ideation";
import LoadingPage from "../../../Pages/LoadingPage";
import { AWS_FILE_URL } from "../../../Constants/Api";
import { useTranslation } from "react-i18next";
import ErrorLabel from "../../Reusable/ErrorLabel/ErrorLabel";

const IdeationSetup = () => {
  const { initID } = useParams();
  const dispatch = useDispatch();
  const ideation = useSelector(getIdeation);
  const user = useSelector(getCurrentUser);
  const { t } = useTranslation();

  const votes = useMemo(
    () => [
      { id: 1, value: 1 },
      { id: 2, value: 2 },
      { id: 3, value: 3 },
      { id: 4, value: 4 },
      { id: 5, value: 5 },
      { id: 6, value: 6 },
      { id: 7, value: 7 },
      { id: 8, value: 8 },
      { id: 9, value: 9 },
      { id: 10, value: 10 },
    ],
    []
  );

  const [allowIdeas, setAllowIdeas] = useState(ideation?.allowIdeas);

  const [allowDefaultIdeas, setAllowDefaultIdeas] = useState(
    ideation?.allowDefaultIdeas
  );
  const [allowIdeasComments, setAllowIdeasComments] = useState(
    ideation?.allowIdeasComments
  );

  const [title, setTitle] = useState(ideation?.name);
  const [introduction, setIntroduction] = useState(ideation?.introduction);
  const [question, setQuestion] = useState(ideation?.questions[0].question);
  const [ideas, setIdeas] = useState(
    ideation?.defaultIdeas?.length
      ? ideation?.defaultIdeas
      : [
          {
            text: "",
            username: `${user?.firstName}${user?.lastName}`,
            id: uuid(),
          },
        ]
  );
  const [deletedIdeas, setDeletedIdeas] = useState([]);
  const [file, setFile] = useState(ideation.logo || null);
  const [fileLoading, setFileLoading] = useState(false);

  const fileTypes = useMemo(() => ["JPG", "PNG", "JPEG", "SVG"], []);

  const onFileUpload = useCallback(
    async (file) => {
      try {
        setFileLoading(true);
        const uploadFile = await uploadDocumentToAWS({
          file,
          id: initID,
        });
        dispatch(
          updateIdeationsAsync({
            ideationData: { logo: uploadFile.fileName },
            ideationId: ideation.id,
          })
        );
        setFile(uploadFile.fileName);
        setFileLoading(false);
      } catch (e) {
        setFileLoading(false);
      }
    },
    [dispatch, ideation.id, initID]
  );

  const removeIdeationLogo = useCallback(async () => {
    try {
      setFileLoading(true);
      await dispatch(
        updateIdeationsAsync({
          ideationData: { logo: null },
          ideationId: ideation.id,
        })
      );
      setFile(null);
      setFileLoading(false);
    } catch (e) {
      setFileLoading(false);
    }
  }, [dispatch, ideation.id]);

  const handleChange = useCallback(
    (name, value) => {
      name === "name" ? setTitle(value) : setIntroduction(value);
      dispatch(changeIdeation({ title: name, value }));
      debeounced400(() => {
        dispatch(
          updateIdeationsAsync({
            ideationData:
              name === "name"
                ? { name: value || "Untitled" }
                : { [name]: value },
            ideationId: ideation.id,
          })
        );
      });
    },
    [dispatch, ideation.id]
  );

  const handleCreateQuestion = useCallback(
    (e) => {
      setQuestion(e.target.value);
      dispatch(
        changeIdeation({
          title: "questions",
          value: [
            {
              ...ideation?.questions[0],
              question: e.target.value,
            },
          ],
        })
      );
      debeounced400(() => {
        dispatch(
          createIdeationQuestionAsync({
            ideationId: ideation.id,
            questions: [
              { id: ideation?.questions[0].id, question: e.target.value },
            ],
            deletedQuestions: [],
          })
        );
      });
    },
    [dispatch, ideation.id, ideation?.questions]
  );

  const questionsSwitch = useCallback(() => {
    setAllowIdeas(!allowIdeas);
    dispatch(
      updateIdeationsAsync({
        ideationData: { allowIdeas: !allowIdeas },
        type: "allowIdeas",
        ideationId: ideation?.id,
      })
    );
  }, [allowIdeas, dispatch, ideation?.id]);

  const ideasSwitch = useCallback(() => {
    setAllowDefaultIdeas(!allowDefaultIdeas);
    dispatch(
      updateIdeationsAsync({
        ideationData: { allowDefaultIdeas: !allowDefaultIdeas },
        type: "allowDefaultIdeas",
        ideationId: ideation?.id,
      })
    );
  }, [allowDefaultIdeas, dispatch, ideation?.id]);

  const commentsSwitch = useCallback(() => {
    setAllowIdeasComments(!allowIdeasComments);
    dispatch(
      updateIdeationsAsync({
        ideationData: { allowIdeasComments: !allowIdeasComments },
        type: "allowIdeasComments",
        ideationId: ideation?.id,
      })
    );
  }, [allowIdeasComments, dispatch, ideation?.id]);

  const saveNewIdeas = useCallback(
    (data) => {
      dispatch(changeIdeation({ title: "defaultIdeas", value: ideas }));
      dispatch(
        createIdeationIdeaAsync({
          ideationId: ideation?.id,
          ideas,
          deletedIdeas: data ? data : deletedIdeas,
        })
      );
    },
    [deletedIdeas, dispatch, ideation?.id, ideas]
  );

  const addIda = useCallback(
    (index) => {
      if (ideas.every((idea) => idea.text.length)) {
        setIdeas([
          ...ideas.slice(0, index),
          {
            text: "",
            username: `${user?.firstName}${user?.lastName}`,
            id: uuid(),
            focus: true,
          },
          ...ideas.slice(index, ideas.length),
        ]);
      }
    },

    [ideas, user?.firstName, user?.lastName]
  );
  const removeIda = useCallback(
    (id) => {
      if (ideas.length > 1) {
        setIdeas(ideas.filter((idea) => idea.id !== id));
        setDeletedIdeas([...deletedIdeas, id]);
        saveNewIdeas([...deletedIdeas, id]);
      }
    },
    [ideas, deletedIdeas, saveNewIdeas]
  );

  const handleChangeIdeaTitle = useCallback(
    ({ id, text }) => {
      const updateIdea = ideas.map((idea) =>
        idea.id === id ? { ...idea, text: text } : idea
      );
      setIdeas(updateIdea);
    },
    [ideas]
  );

  const changeVoteCount = useCallback(
    (e) => {
      dispatch(
        updateIdeationsAsync({
          ideationData: { votesPerParticipant: e.value },
          ideationId: ideation?.id,
        })
      );
    },
    [dispatch, ideation?.id]
  );

  return (
    <div className={"idea-flow-setup"}>
      <div className={"idea-flow-setup--header"}>
        <div className={"idea-flow-setup--header-block-1"}>
          <div className={"idea-flow-setup--title-block"}>
            <label className={"idea-flow-setup--title"}>
              {t("title", { ns: ["ideation"] })}
            </label>
            <TextInput
              value={title}
              maxLength={"120"}
              placeholder={`${t("ideationTitlePlaceholder", {
                ns: ["ideation"],
              })}`}
              disabled={
                ideation?.status === `${t("publish", { ns: ["ideation"] })}`
              }
              onChange={(e) => handleChange("name", e.target.value)}
            />
            <div
              className={"idea-flow-setup--title-text-limit"}
            >{`${title?.length}/120`}</div>
            {!title.length ? (
              <ErrorLabel title={`${t("title", { ns: ["ideation"] })}`} />
            ) : null}
          </div>

          <div className={"idea-flow-setup--title-block"}>
            <label className={"idea-flow-setup--title"}>
              {t("introduction", { ns: ["ideation"] })}
            </label>
            <TextInput
              multiple={true}
              value={introduction}
              maxLength={"255"}
              placeholder={`${t("ideationDescPlaceholder", {
                ns: ["ideation"],
              })}`}
              className={"idea-flow-setup--introduction"}
              disabled={
                ideation?.status === `${t("publish", { ns: ["ideation"] })}`
              }
              onChange={(e) => handleChange("introduction", e.target.value)}
            />
            <div
              className={"idea-flow-setup--title-text-limit"}
            >{`${introduction?.length}/255`}</div>
          </div>
        </div>

        <div className={"idea-flow-setup--header-block-2"}>
          <label className={"idea-flow-setup--header-block-2--title"}>
            {t("logoPlaceholder", { ns: ["ideation"] })}
          </label>

          {fileLoading ? (
            <LoadingPage />
          ) : (
            <>
              {file ? (
                <div className={"idea-flow-setup--header-block-2--img-block"}>
                  <img
                    src={`${AWS_FILE_URL}/${file}`}
                    alt="logo"
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
              ) : (
                <FileUploader
                  children={<CustomFileUploader fileTypes={fileTypes} />}
                  multiple={false}
                  disabled={
                    ideation?.status === `${t("publish", { ns: ["ideation"] })}`
                  }
                  handleChange={onFileUpload}
                  name="file"
                  types={fileTypes}
                />
              )}

              {file && (
                <div className={"idea-flow-setup--header-block-2--file"}>
                  <span
                    className={"idea-flow-setup--header-block-2--file-name"}
                  >{`${file}`}</span>
                  {ideation?.status !==
                    `${t("publish", { ns: ["ideation"] })}` && (
                    <button
                      aria-label={"remove logo"}
                      onClick={removeIdeationLogo}
                    >
                      <Close />
                    </button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className={"idea-flow-setup--switch-block"}>
        <CustomSwitch
          isChecked={allowDefaultIdeas || false}
          handleChange={ideasSwitch}
          disabled={
            ideation?.status === `${t("publish", { ns: ["ideation"] })}`
          }
          label={`${t("promotedIdeas", { ns: ["ideation"] })}`}
        />
      </div>
      <div className={"idea-flow-setup--ideas-block"}>
        <p className={"idea-flow-setup--sub-title"}>
          {t("piDescriptionText", { ns: ["ideation"] })}
        </p>
        {allowDefaultIdeas
          ? ideas?.map((idea, index) => (
              <IdeationSetupIdea
                idea={idea}
                key={idea.id}
                index={index}
                disabled={
                  ideation?.status === `${t("publish", { ns: ["ideation"] })}`
                }
                addIda={addIda}
                removeIda={removeIda}
                handleChangeIdeaTitle={handleChangeIdeaTitle}
                saveNewIdeas={saveNewIdeas}
              />
            ))
          : null}
      </div>
      <div className={"idea-flow-setup--switch-block"}>
        <CustomSwitch
          isChecked={allowIdeas || false}
          handleChange={questionsSwitch}
          disabled={
            ideation?.status === `${t("publish", { ns: ["ideation"] })}`
          }
          label={`${t("crowdsourceRequest", { ns: ["ideation"] })}`}
        />
      </div>
      <div className={"idea-flow-setup--question-block"}>
        <p className={"idea-flow-setup--sub-title"}>
          {t("crDescriptionText", { ns: ["ideation"] })}
        </p>

        {allowIdeas ? (
          <>
            <TextInput
              disabled={
                ideation?.status === `${t("publish", { ns: ["ideation"] })}`
              }
              value={question}
              maxLength={"255"}
              placeholder={"Enter a question here"}
              onChange={handleCreateQuestion}
            />
            <div
              className={"idea-flow-setup--question-text-limit"}
            >{`${question?.length}/255`}</div>
          </>
        ) : null}
      </div>
      <div className={"idea-flow-setup--switch-block"}>
        <CustomSwitch
          isChecked={allowIdeasComments || false}
          handleChange={commentsSwitch}
          disabled={
            ideation?.status === `${t("publish", { ns: ["ideation"] })}`
          }
          label={`${t("comments", { ns: ["ideation"] })}`}
        />
      </div>
      <p className={"idea-flow-setup--sub-title"}>
        {t("commentsDescriptionText", { ns: ["ideation"] })}
      </p>
      <div className={"idea-flow-setup--vote-block"}>
        <label className={"idea-flow-setup--title"}>
          {t("votesText", { ns: ["ideation"] })}
        </label>
        <div className={"idea-flow-setup--vote-dropdown-block"}>
          <DropdownComponent
            data={votes || []}
            defaultValue={ideation?.votesPerParticipant || "Select vote"}
            disabled={
              ideation?.status === `${t("publish", { ns: ["ideation"] })}`
            }
            select={changeVoteCount}
          />
        </div>
      </div>
    </div>
  );
};

export default IdeationSetup;
