export const GET_STATUS_SUMMARY = `
    query statusSummary($statusSummaryInput: StatusSummaryInput!) {
  statusSummary(statusSummaryInput: $statusSummaryInput) {
    result {
      month
      date
      completed
      active
    }
    count
  }
}
`;
