import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET_STATUS_SUMMARY } from "../../../gql/graphqlQuery/get-status-summary.generated";
import graphqlRequest from "../../../graphqlClient";

const initialState = {
  statusSummary: null,
  statusSummaryLoading: false,
  statusSummaryError: "",
};

export const getStatusSummaryAsync = createAsyncThunk(
  "statusSummary/post",
  async (action, state) => {
    const variables = {
      statusSummaryInput: { ...action },
    };
    const response = await graphqlRequest(GET_STATUS_SUMMARY, variables);
    return response.statusSummary;
  }
);

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    resetAnalytics: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStatusSummaryAsync.pending, (state, action) => {
        state.statusSummaryLoading = true;
        state.statusSummaryError = "";
      })
      .addCase(getStatusSummaryAsync.fulfilled, (state, action) => {
        state.statusSummary = action.payload?.result;
        state.statusSummaryLoading = false;
      })
      .addCase(getStatusSummaryAsync.rejected, (state, action) => {
        state.statusSummaryLoading = false;
        state.statusSummaryError = "Something went wrong";
      });
  },
});

export const { resetAnalytics } = analyticsSlice.actions;
//Selectors
export const getStatusSummary = (state) => state.analytics.statusSummary;
export const getStatusSummaryLoading = (state) =>
  state.analytics.statusSummaryLoading;
export const getStatusSummaryError = (state) =>
  state.analytics.statusSummaryError;

export default analyticsSlice.reducer;
