import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { ADMIN_TOKEN_NAME, PORTAL_TOKEN_NAME } from "../../Constants/Api";
import LoadingPage from "../../Pages/LoadingPage";
import {
  getCurrentUser,
  getWorkforceAsync,
  getUserAsync,
  getUserErrors,
  getUserLoading,
  getWorkforcesLoading,
  getProductSwitcherAccessAsync,
  getProductSwitcherWorkforcePermission,
  getProductSwitcherEmpowerPermission,
  getSelectedLanguage,
} from "../../Store/slices/user";
import BaseHeader from "../../Components/Header/BaseHeader";
import ShowToasterMessage from "../../Components/Reusable/ShowToasterMessage/ShowToasterMessage";
import NoPermissionWorkforce from "../../Components/Reusable/NoPermission/NoPermissionWorkforce";
import NoPermissionEmpower from "../../Components/Reusable/NoPermission/NoPermissionEmpower";
import { useTranslation } from "react-i18next";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { enGB, es, de, ar } from "date-fns/locale";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/de";
import "moment/locale/ar";
import { getCookies, removeCookies } from "../../Utils/cookies";
import axios from "axios";
import {
  disconnectSocket,
  initiateSocketConnection,
} from "../../socketio.service";
import { getIsAdminUI, getLoginUrl } from "../../Utils";

const BaseLayout = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const isAdminUI = getIsAdminUI()
  const token = isAdminUI ? getCookies(ADMIN_TOKEN_NAME) : getCookies(PORTAL_TOKEN_NAME);

  const currentUser = useSelector(getCurrentUser);
  const selectedLanguage = useSelector(getSelectedLanguage);
  const userLoading = useSelector(getUserLoading);
  const productSwitcherWorkforcePermission = useSelector(
    getProductSwitcherWorkforcePermission
  );
  const productSwitcherEmpowerPermission = useSelector(
    getProductSwitcherEmpowerPermission
  );
  const workforcesLoading = useSelector(getWorkforcesLoading);
  const errors = useSelector(getUserErrors);

  axios.interceptors.request.use(function (config) {
    config.headers = {
      ...config.headers,
      "Cache-Control": "max-age=31536000",
    };

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  useEffect(() => {
    if (pathname?.length > 1 && pathname.includes("initiative")) {
      localStorage.setItem("pathname", JSON.stringify(pathname));
    }
    const fetchData = () => {
      if (token) {
        dispatch(getUserAsync());
      }
      if (!token) {
        setTimeout(() => {
          const url = getLoginUrl();
          window.location.href = url;
          // redirectToLogin();
        }, 4000);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (currentUser?.primaryUserAPIKey) {
        await dispatch(getProductSwitcherAccessAsync());
        // await dispatch(getWorkforceAsync());
        localStorage.setItem("user", JSON.stringify(currentUser));
      }
    })();
  }, [currentUser, dispatch]);

  useEffect(() => {
    switch (selectedLanguage) {
      case "en":
        moment.locale("en-GB");
        registerLocale("enGB", enGB);
        setDefaultLocale("enGB");
        break;
      case "es":
        moment.locale("es");
        registerLocale("es", es);
        setDefaultLocale("es");
        break;
      case "de":
        moment.locale("de");
        registerLocale("de", de);
        setDefaultLocale("de");
        break;
      case "ar":
        moment.locale("ar");
        registerLocale("ar", ar);
        setDefaultLocale("ar");
        break;
      default:
        moment.locale("en-GB");
        registerLocale("enGB", enGB);
        setDefaultLocale("enGB");
        break;
    }

    i18n.changeLanguage(selectedLanguage);
  }, [i18n, selectedLanguage]);

  // useEffect(() => { /* Test if socket is actually used */
  //   if (token) {
  //     initiateSocketConnection();
  //   }
  //   return () => {
  //     disconnectSocket();
  //   };
  // }, [token]);

  if (errors.user || errors.workforce) {
    return (
      <>
        <BaseHeader />
        <div className={"error-message"}>{errors.user || errors.workforce}</div>
      </>
    );
  }

  if (userLoading) {
    return <LoadingPage />;
  }

  if (!productSwitcherWorkforcePermission) {
    return <NoPermissionWorkforce />;
  }

  if (!productSwitcherEmpowerPermission) {
    return <NoPermissionEmpower />;
  }

  return (
    <>
      <ShowToasterMessage />
      <>
        <BaseHeader />
        <main className="main-container">
          <Outlet />
        </main>
      </>
    </>
  );
};

export default BaseLayout;