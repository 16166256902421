import React, { useState } from "react";
import { ReactComponent as UnLock } from "../../../../Assets/Icons/Unlock.svg";
import {
  productSwitcherColors,
  productSwitcherHoverColors,
} from "../../../../Constants/ProductSwitcherColors";

const ProductSwitcherMenuHoveredItem = ({ parentItem, item }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <li
      className={"product-switcher--hover--item--li"}
      style={{
        borderColor: hovered
          ? productSwitcherHoverColors[parentItem?.name]
          : productSwitcherColors[parentItem?.name],
      }}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {item?.active ? (
        <a className={"product-switcher--hover--item--link"} href={item?.link}>
          <div className={"product-switcher--hover--item--icon"}>
            <img
              width={16}
              height={16}
              src={item?.logo}
              color={"#f10000"}
              alt={item?.name}
            />
          </div>
          <span className={"product-switcher--hover--item--name"}>
            {item?.name}
          </span>
        </a>
      ) : (
        <div className={"product-switcher--hover--item--lock"}>
          <UnLock />
          <p>Upgrade to unlock</p>
        </div>
      )}
    </li>
  );
};

export default ProductSwitcherMenuHoveredItem;
