import "./BaseModal.scss";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { ReactComponent as Close } from "../../../Assets/Icons/Close.svg";

const BaseModal = ({
  children,
  visible,
  onCloseModal,
  isLoading,
  headerTitle = "",
  hideHeader,
  headerClassName = "",
}) => {
  return (
    <Modal
      open={visible}
      onClose={onCloseModal}
      closeOnOverlayClick={!isLoading}
      center
      classNames={{
        modal: `base-modal`,
      }}
    >
      {!hideHeader ? (
        <div className={`base-modal-header ${headerClassName}`}>
          {headerTitle}
          <div className={"base-modal-header--close"} onClick={onCloseModal}>
            <Close />
          </div>
        </div>
      ) : null}
      <div className="base-modal-content">{children}</div>
    </Modal>
  );
};

export default BaseModal;
