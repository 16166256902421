import BaseModal from "../BaseModal/BaseModal";
import "./NoPermission.scss";
import { ReactComponent as Workforce } from "../../../Assets/Icons/Workforce.svg";
import { Button } from "@questionprodev/shared-components";
import React from "react";
import { removeCookies } from "../../../Utils/cookies";
import { TOKEN_NAME } from "../../../Constants/Api";
import { useTranslation } from "react-i18next";

const NoPermissionWorkforce = () => {
  const { t } = useTranslation(["home", "globalk"]);

  const logout = () => {
    removeCookies(TOKEN_NAME);
    window.location.href = "https://www.questionpro.com/a/logout.do";
  };

  return (
    <BaseModal visible={true} hideHeader={true} onCloseModal={() => {}}>
      <div className={"no-permission"}>
        <div className={"no-permission--header"}>
          <p className={"no-permission--header--title"}>
            {t("upgradeTo")} <span>{t("workforce")} </span>{" "}
            {t("licenseToUnlock")}
          </p>
        </div>
        <div className={"no-permission--main"}>
          <div className={"no-permission--main--block-1"}>
            <h2 className={"no-permission--main--title"}>{t("empower")} </h2>
            <p className={"no-permission--main--description"}>
              {t("noPermissionDescription")}
            </p>
            <div className={"no-permission--main--img"}>
              <Workforce />
            </div>
          </div>
          <div className={"no-permission--main--block-2"}>
            <h2 className={"no-permission--main--title"}>{t("workforce")} </h2>
            <p className={"no-permission--main--request-pricing"}>
              {t("requestPricing")}
            </p>
            <p className={"no-permission--main--features"}>{t("features")} </p>
            <ul>
              <li className={"no-permission--main--li"}>
                {t("employeeSurveys")}
              </li>
              <li className={"no-permission--main--li"}>{t("360feedback")}</li>
              <li className={"no-permission--main--li"}>
                {t("workforceAnalytics")}
              </li>
              <li className={"no-permission--main--li"}>{t("support")}</li>
            </ul>

            <Button variant="contained" onClick={logout}>
              {t("logout", { ns: ["global"] })}
            </Button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default NoPermissionWorkforce;
