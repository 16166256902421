import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/IndexDB";
import { PerPageThen } from "../../../Constants/PerPage";

let controller;

export const getInitiativesTableAsync = createAsyncThunk(
  "initiatives/table",
  async ({ page, ...rest }, state) => {
    controller = new AbortController();
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.get(`${BASE_URL}/initiative/table`, {
      signal: controller.signal,
      headers: {
        "x-workforce": workforceId,
      },
      params: {
        offset: (page - 1) * PerPageThen,
        ...rest,
        limit: PerPageThen,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getInitiativesOwnersAsync = createAsyncThunk(
  "initiatives/owners",
  async (action, thunkApi) => {
    controller = new AbortController();
    const workforceId = thunkApi.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.get(`${BASE_URL}/initiative/owners`, {
      signal: controller.signal,
      headers: {
        "x-workforce": workforceId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getOwnerInitiative = createAsyncThunk(
  "owner/initiatives",
  async (action, thunkApi) => {
    controller = new AbortController();
    const workforceId = thunkApi.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.get(`${BASE_URL}/initiative/grid`, {
      params: {
        ...action,
      },
      signal: controller.signal,
      headers: {
        "x-workforce": workforceId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getDeletedInitiatives = createAsyncThunk(
  "initiativeCard/getDeleted",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.get(`${BASE_URL}/initiative/recycle-bin`, {
      headers: {
        "x-workforce": workforceId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const deleteAllDeletedInitiativesAsync = createAsyncThunk(
  "recycleBinInitiatives/delete",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.delete(
      `${BASE_URL}/initiative/recycle/empty-recycle-bin`,
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const createInitiativeAsync = createAsyncThunk(
  "initiative/create",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const lang = state.getState().user?.selectedLanguage;
    const startTime = new Date().getTime();
    const response = await axios.post(
      `${BASE_URL}/initiative`,
      { ...action },
      {
        headers: {
          "x-workforce": workforceId,
          "x-lang": lang,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const updateInitiativeAsync = createAsyncThunk(
  "initiative/update",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const lang = state.getState().user?.selectedLanguage;
    const startTime = new Date().getTime();
    const response = await axios.patch(
      `${BASE_URL}/initiative/${action.initID}`,
      { ...action.initiative },
      {
        headers: {
          "x-workforce": workforceId,
          "x-lang": lang,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const deleteInitiativeAsync = createAsyncThunk(
  "initiative/delete",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const lang = state.getState().user?.selectedLanguage;
    const startTime = new Date().getTime();
    const response = await axios.delete(
      `${BASE_URL}/initiative/${action.initiativeId}`,
      {
        headers: {
          "x-workforce": workforceId,
          "x-lang": lang,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const restoreInitiativeAsync = createAsyncThunk(
  "initiative/recover",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.patch(
      `${BASE_URL}/initiative/recover/${action.initiativeId}`,
      {},
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getInitiativeFiltersAsync = createAsyncThunk(
  "filters/get",
  async (action, { getState }) => {
    const workforceId = getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.get(`${BASE_URL}/initiative/filters`, {
      headers: {
        "x-workforce": workforceId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const initialState = {
  initiativesLoading: false,

  initiativeFilters: {},
  initiativeFiltersLoading: false,

  initiativesOwners: [],
  initiativesOwnersLoading: false,
  initiativesGridPage: 1,
  initiativesByOwnerEmail: {},
  initiativesLoadingByOwnerEmail: {},

  initiativesTable: {},

  initiativesTableLoading: false,
  initiativesCounts: 0,
  initiatives: [],
  deletedInitiativesLoading: false,

  initFilterTags: [],
  initFilterGoals: [],

  error: {},
};

const initiativesSlice = createSlice({
  name: "initiatives",
  initialState,
  reducers: {
    resetInitiatives(state) {
      state.initiativesOwners = [];
      state.initiativesOwnersLoading = false;
      state.initiativesByOwnerEmail = {};
      state.initiativesLoadingByOwnerEmail = {};
      state.initiativesTable = {};
      state.initiativesTableLoading = false;
      state.initiativesCounts = 0;
      state.initiativeFilters = {};
    },
    sortInitiatives: (state, action) => {
      const { data, page } = action.payload;
      state.initiativesTable[page] = data;
    },
    addFilterTag(state, action) {
      state.initFilterTags = action.payload;
    },
    addFilterGoal(state, action) {
      state.initFilterGoals = action.payload;
    },
    abortRequest() {
      if (controller) {
        controller.abort();
      }
    },
    setInitiativeCount(state, action) {
      state.initiativesCounts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitiativesTableAsync.pending, (state, action) => {
        state.error = {};
        state.initiativesTableLoading = true;
      })
      .addCase(getInitiativesTableAsync.fulfilled, (state, action) => {
        const page = action.meta.arg.page;
        state.error = {};
        state.initiativesTableLoading = false;
        state.initiativesCounts = action.payload?.count;

        if (action.payload?.initiatives) {
          state.initiativesTable = {
            [page]: action.payload?.initiatives,
          };
        }
      })
      .addCase(getInitiativesTableAsync.rejected, (state, action) => {
        if (action.error.message !== "canceled") {
          state.initiativesTableLoading = false;

          state.error.initiativesTable =
            action.error.message || `Something went wrong`;
        }
      })
      .addCase(getInitiativesOwnersAsync.pending, (state, action) => {
        state.initiativesOwnersLoading = true;
      })
      .addCase(getInitiativesOwnersAsync.fulfilled, (state, action) => {
        state.initiativesOwnersLoading = false;
        state.initiativesOwners = [...action.payload?.owners];
      })
      .addCase(getInitiativesOwnersAsync.rejected, (state, action) => {
        state.initiativesOwnersLoading = false;
        state.error.initiativesOwners =
          action.error.message || `Something went wrong`;
      })
      .addCase(getOwnerInitiative.pending, (state, action) => {
        const email = action.meta.arg.ownerEmailAddress;

        state.initiativesLoadingByOwnerEmail[email] = true;
      })
      .addCase(getOwnerInitiative.rejected, (state, action) => {
        const email = action.meta.arg.ownerEmailAddress;
        state.initiativesLoadingByOwnerEmail[email] = false;
      })
      .addCase(getOwnerInitiative.fulfilled, (state, action) => {
        const email = action.meta.arg.ownerEmailAddress;
        const offset = action.meta.arg?.offset || 0;

        state.initiativesByOwnerEmail[email] = !offset
          ? [action.payload.initiatives]
          : [
              ...state.initiativesByOwnerEmail[email],
              action.payload.initiatives,
            ];
        state.initiativesLoadingByOwnerEmail[email] = false;
      })

      .addCase(getDeletedInitiatives.pending, (state, action) => {
        state.error = {};
        state.initiativesLoading = true;
      })
      .addCase(getDeletedInitiatives.fulfilled, (state, action) => {
        state.initiatives = action.payload.initiatives;
        state.initiativesStatusCount = action.payload.counts;
        state.initiativesLoading = false;
      })
      .addCase(getDeletedInitiatives.rejected, (state, action) => {
        state.initiativesLoading = false;
        state.error.initiatives = `Something went wrong`;
      })
      .addCase(deleteAllDeletedInitiativesAsync.pending, (state, action) => {
        state.error = {};
        state.deletedInitiativesLoading = true;
      })
      .addCase(deleteAllDeletedInitiativesAsync.fulfilled, (state, action) => {
        state.initiatives = [];
        state.initiativesStatusCount.deletedCount = 0;
        state.deletedInitiativesLoading = false;
      })
      .addCase(deleteAllDeletedInitiativesAsync.rejected, (state, action) => {
        state.deletedInitiativesLoading = false;
        state.error.initiatives = `Something went wrong`;
      })
      .addCase(updateInitiativeAsync.fulfilled, (state, action) => {
        const index = state.initiatives.findIndex(
          (item) => item.id === action.payload.data.id
        );
        state.initiatives[index] = action.payload.data;
      })
      .addCase(deleteInitiativeAsync.fulfilled, (state, action) => {
        state.initiatives = state.initiatives.filter(
          (initiative) => initiative.id !== action.payload.id
        );
        state.initiativesStatusCount = action.payload.counts;
      })
      .addCase(restoreInitiativeAsync.fulfilled, (state, action) => {
        state.initiatives = state.initiatives.filter(
          (initiative) => initiative.id !== action.payload.id
        );
        state.initiativesStatusCount = action.payload.counts;
      })
      .addCase(getInitiativeFiltersAsync.pending, (state, action) => {
        state.initiativeFiltersLoading = true;
      })
      .addCase(getInitiativeFiltersAsync.fulfilled, (state, action) => {
        state.initiativeFilters = action.payload;
        state.initiativeFiltersLoading = false;
      })
      .addCase(getInitiativeFiltersAsync.rejected, (state, action) => {
        state.initiativeFiltersLoading = false;
      });
  },
});

export const {
  resetInitiatives,
  sortInitiatives,
  addFilterTag,
  addFilterGoal,
  abortRequest,
  setInitiativeCount,
} = initiativesSlice.actions;

//Selectors
//Grid view
export const getInitiativesOwners = (state) =>
  state.initiatives.initiativesOwners;
export const getInitiativesOwnersLoading = (state) =>
  state.initiatives.initiativesOwnersLoading;
//table view
export const getInitiativesCount = (state) =>
  state.initiatives.initiativesCounts;
export const getInitiativesTable = (state) =>
  state.initiatives.initiativesTable;
export const getInitiativesTableLoading = (state) =>
  state.initiatives.initiativesTableLoading;
export const getInitiativesTablePage = (state) =>
  state.initiatives.initiativesTablePage;
export const getInitiativesTableError = (state) =>
  state.initiatives.error.initiativesTable;
//grid view
export const getInitiativesByOwnerEmail = (state) =>
  state.initiatives.initiativesByOwnerEmail;
export const getInitiativesLoadingByOwnerEmail = (state) =>
  state.initiatives.initiativesLoadingByOwnerEmail;

export const getInitiativesLoading = (state) =>
  state.initiatives.initiativesLoading;
export const getDeletedInitiativesLoading = (state) =>
  state.initiatives.deletedInitiativesLoading;

export const getInitiativeFilters = (state) =>
  state.initiatives.initiativeFilters;
export const getInitiativeFiltersLoading = (state) =>
  state.initiatives.initiativeFiltersLoading;

export const getInitFilterTags = (state) => state.initiatives.initFilterTags;
export const getInitFilterGoals = (state) => state.initiatives.initFilterGoals;

export const getInitiativesError = (state) => state.initiatives.error;

export default initiativesSlice.reducer;
