// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-label {
  display: inline-block;
  color: #e53251;
  font-size: 12px;
  margin: 6px 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/Reusable/ErrorLabel/ErrorLabel.scss","webpack://./src/Assets/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,cCUiB;EDTjB,eCQe;EDPf,aAAA;AADF","sourcesContent":["@import \"src/Assets/styles/variables\";\n\n.error-label {\n  display: inline-block;\n  color: $BASE-ERROR-COLOR;\n  font-size: $BASE-FONT-SIZE;\n  margin: 6px 0;\n}\n","$BASE-GREY-COLOR: #545e6b;\n$BASE-LIGHT-GREY-COLOR: #9b9b9b;\n$BASE-BLUE-COLOR: #1b87e6;\n$BASE-DARK-BLUE-COLOR: #54a5ec;\n$BASE-BG-COLOR: #f5f5f5;\n$BASE-WHITE-COLOR: #ffffff;\n$BASE-BORDER-COLOR: #d8d8d8;\n$BASE-HEADER-HEIGHT: 48px;\n$BASE-MOBILE-HEADER-HEIGHT: 100px;\n$BASE-NAVBAR-WIDTH: 168px;\n$BASE-LARGE-SIZE: 24px;\n$MODAL-MIDDLE-SIZE: 16px;\n$MODAL-Regular-SIZE: 18px;\n$BASE-FONT-SIZE: 12px;\n$BASE-ERROR-COLOR: #e53251;\n$BASE-SUCCESS-COLOR: #3fca5a;\n$BASE-NORMAL-COLOR: #f0b400;\n$MAIN-GRAY-COLOR: #454545;\n$MAIN-BLUE-COLOR: #1b3380;\n$MAIN-BORDER-COLOR: #ddd;\n\n$large-l: 1440px;\n$large: 1024px;\n$medium: 768px;\n$small-L: 590px;\n$small: 425px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
