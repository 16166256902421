import InitiativeMobileBar from "./InitiativeMobileBar/InitiativeMobileBar";
import InitiativeDesktopBar from "./InitiativeDesktopBar/InitiativeDesktopBar";
import { useWindowSize } from "../../Hooks/useWindowSize";

const InitiativeBar = () => {
  const size = useWindowSize();
  return (
    <>{size[0] > 768 ? <InitiativeDesktopBar /> : <InitiativeMobileBar />}</>
  );
};

export default InitiativeBar;
