import { GraphQLClient } from "graphql-request";
import { ADMIN_TOKEN_NAME, BACKEND_URL, PORTAL_TOKEN_NAME, REFRESH_TOKEN, TOKEN_NAME } from "./Constants/Api";
import { getCookies, removeCookies, setCookies } from "./Utils/cookies";
import { getRefreshTokenAsync } from "./Store/slices/user";
import { getIsAdminUI, getLoginUrl } from "./Utils";
import axios from "axios";

const client = new GraphQLClient(BACKEND_URL);
// const client = new GraphQLClient("http://172.16.0.131:5009");
const isAdminUI = getIsAdminUI();

const requestInterceptor = async (query, variables, headers = {}) => {
  const token = isAdminUI ? getCookies(ADMIN_TOKEN_NAME) : getCookies(PORTAL_TOKEN_NAME);
  headers["authorization"] = `Bearer ${token}`;
  headers["isAdminUI"] = isAdminUI;
  return { query, variables, headers };
};

// Response Interceptor
const responseInterceptor = async (response) => {
  return response;
};

const errorResponseInterceptor = async (data) => {
  const response = data?.response;
  if (response?.errors) {
    const error = response?.errors[0];
    if (
      error.message === "invalid_token" &&
      error?.extensions?.originalError?.statusCode === 401
    ) {
      const refreshToken = getCookies(REFRESH_TOKEN);
      const panelId = +(getCookies("panelId") || "");

      if (refreshToken && panelId) {
        try {
          const data = await graphqlRequest(
            getRefreshTokenAsync({
              refreshTokenInput: { refreshToken, panelId },
            })
          );
          if (data?.refreshTokens) {
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + data?.refreshTokens?.access_token;
            setCookies(REFRESH_TOKEN, data?.refreshTokens?.refresh_token);
            setCookies(PORTAL_TOKEN_NAME, data?.refreshTokens?.access_token);
            setCookies(TOKEN_NAME, data?.refreshTokens?.access_token);
          }
          return Promise.reject("Not Authorized");
        } catch (e) {
          console.log(e, "e");
          // localStorage.removeItem('accessToken');
          removeCookies("token");
          removeCookies("panelId");
          removeCookies(REFRESH_TOKEN);
          const url = getLoginUrl();
          window.location.href = url;
          return Promise.reject("Not Authorized");
        }
      } else {
        // localStorage.removeItem('accessToken');
        removeCookies("token");
        removeCookies("panelId");
        removeCookies(REFRESH_TOKEN);
        const url = getLoginUrl();
        window.location.href = url;
      }
    }
  } else if (response?.data.errors) {
    console.log(response?.data.errors, "errors");
  }
};

// Wrapped GraphQL request function
const graphqlRequest = async (query, variables = {}, headers = {}) => {
  const interceptedRequest = await requestInterceptor(
    query,
    variables,
    headers
  );

  try {
    const response = await client.request(
      interceptedRequest.query,
      interceptedRequest.variables,
      interceptedRequest.headers
    );

    return await responseInterceptor(response);
  } catch (error) {
    return await errorResponseInterceptor(error);
  }
};
export default graphqlRequest;
