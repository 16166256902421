import BaseModal from "../BaseModal/BaseModal";
import "./NoPermission.scss";
import { ReactComponent as Workforce } from "../../../Assets/Icons/Workforce.svg";
import { useTranslation } from "react-i18next";

const NoPermissionEmpower = () => {
  const { t } = useTranslation(["home"]);

  return (
    <BaseModal visible={true} hideHeader={true} onCloseModal={() => {}}>
      <div className={"no-permission"}>
        <div className={"no-permission--header"}>
          <p className={"no-permission--header--title"}>{t("noAccess")}</p>
        </div>
        <div className={"no-permission--main no-permission--empower-main"}>
          <div className={"no-permission--empower-main--block-1"}>
            <h2 className={"no-permission--main--title"}>
              {t("empowerText1")}
            </h2>
            <p className={"no-permission--main--description"}>
              {t("empowerText2")}
            </p>
            <p className={"no-permission--main--description"}>
              {t("contactSupport")}
            </p>
          </div>
          <div className={"no-permission--empower-main--block-2"}>
            <Workforce />
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default NoPermissionEmpower;
