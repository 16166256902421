import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/IndexDB";

const initialState = {
  settings: {},
  settingsLoading: true,
  settingsError: "",

  goals: [],
  goalsLoading: true,
  goalsCount: 0,
  goalsError: "",
};

export const getSettingsAsync = createAsyncThunk(
  "setting/get",
  async (action, state) => {
    try {
      const workforceId = state.getState().user?.selectedWorkforce.workforceID;
      const startTime = new Date().getTime();
      const response = await axios.get(`${BASE_URL}/organization/settings`, {
        headers: {
          "x-workforce": workforceId,
        },
      });
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const updateSettingsAsync = createAsyncThunk(
  "setting/post",
  async (payload, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const startTime = new Date().getTime();
    const response = await axios.post(
      `${BASE_URL}/organization/settings`,
      {
        ...payload,
      },
      {
        headers: {
          "x-workforce": workforceId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const createGoalAsync = createAsyncThunk(
  "createGoal/post",
  async (action, state) => {
    try {
      const workforceId = state.getState().user?.selectedWorkforce.workforceID;
      const lang = state.getState().user?.selectedLanguage;
      const orgID =
        (!action?.generalGoal && state.getState().user.user.orgID) || null;

      const startTime = new Date().getTime();
      const response = await axios.post(
        `${BASE_URL}/goals`,
        {
          ...action,
          orgID,
        },
        {
          headers: {
            "x-workforce": workforceId,
            "x-lang": lang,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const deleteGoalAsync = createAsyncThunk(
  "deleteGoal/delete",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const lang = state.getState().user?.selectedLanguage;
    const startTime = new Date().getTime();
    const response = await axios.delete(`${BASE_URL}/goals/${action.goalID}`, {
      headers: {
        "x-workforce": workforceId,
        "x-lang": lang,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const updateGoalAsync = createAsyncThunk(
  "updateGoal/patch",
  async (action, state) => {
    const workforceId = state.getState().user?.selectedWorkforce.workforceID;
    const lang = state.getState().user?.selectedLanguage;
    const orgID = state.getState().user.user.orgID.toString();
    const startTime = new Date().getTime();
    const response = await axios.patch(
      `${BASE_URL}/goals/${action.goalID}`,
      {
        ...action,
        orgID,
      },
      {
        headers: {
          "x-workforce": workforceId,
          "x-lang": lang,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    resetSettings: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSettingsAsync.pending, (state, action) => {
        state.goalsError = "";
        state.goalsLoading = true;
        state.settingsLoading = true;
      })
      .addCase(getSettingsAsync.fulfilled, (state, action) => {
        state.settings = {
          errorPeriod: action?.payload?.errorPeriod,
          warningPeriod: action?.payload?.warningPeriod,
        };
        state.goals = action.payload.goals;
        state.goalsLoading = false;
        state.settingsLoading = false;
      })
      .addCase(getSettingsAsync.rejected, (state, action) => {
        state.goalsError = `${action?.payload?.statusCode} - ${action?.payload?.message}`;
        state.settingsError = `${action?.payload?.statusCode} - ${action?.payload?.message}`;
        state.goalsLoading = false;
        state.settingsLoading = false;
      })
      .addCase(createGoalAsync.fulfilled, (state, action) => {
        state.goals = [action.payload.data, ...state.goals];
      })
      .addCase(deleteGoalAsync.fulfilled, (state, action) => {
        state.goals = state.goals.filter(
          (goal) => goal.id !== action.payload.id
        );
      });
  },
});

export const { resetSettings } = settingSlice.actions;

//Selectors
export const getSettings = (state) => state.setting.settings;
export const getSettingsLoading = (state) => state.setting.settingsLoading;
export const getSettingsError = (state) => state.setting.settingsError;

export const getGoals = (state) => state.setting.goals;
export const getGoalsLoading = (state) => state.setting.goalsLoading;
export const getGoalsError = (state) => state.setting.goalsError;

export const getTags = (state) => state.setting.tags;
export const getTagsLoading = (state) => state.setting.tagsLoading;
export const getTagsError = (state) => state.setting.tagsError;

export default settingSlice.reducer;
