export const BASE_URL = process.env.REACT_APP_API;
export const AUTH_URL = process.env.REACT_APP_AUTHORIZATION_URL;
export const CALLBACK_URL = process.env.REACT_APP_CALLBACK_URL;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const IMAGE_URL = process.env.REACT_APP_API_IMAGE;
export const IMAGE_URL_STOCK = `${process.env.REACT_APP_API_IMAGE}stock/`;
export const AWS_SINGER = process.env.REACT_APP_SINGER_URL;
export const AWS_KEY = process.env.REACT_APP_AWS_KEY;
export const AWS_BUCKET = process.env.REACT_APP_BUCKET;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const AWS_FILE_URL = process.env.REACT_APP_AWS_FILE_URL;
export const ADMIN_USER_EMAIL = process.env.REACT_APP_GENERIC_TEMPLATE_CREATOR;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_ENDPOINT;
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
export const TOKEN_NAME = "empower-token";
