import { useEffect, useRef, useState } from "react";
import "./IdeationSetupIdea.scss";
import { TextInput } from "@questionpro/shared-components";
import { ReactComponent as Plus } from "../../../Assets/Icons/Plus.svg";
import { ReactComponent as Minus } from "../../../Assets/Icons/Minus.svg";

const IdeationSetupIdea = ({
  idea,
  index,
  disabled,
  addIda,
  removeIda,
  handleChangeIdeaTitle,
  saveNewIdeas,
}) => {
  let inputQuestionRef = useRef(null);
  const [title, setTitle] = useState(idea.text);

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
    handleChangeIdeaTitle({ id: idea.id, text: e.target.value });
  };

  useEffect(() => {
    if (idea.focus) {
      inputQuestionRef?.current?.focus();
    }
  }, [idea.focus, inputQuestionRef]);

  return (
    <div className={"idea-flow-idea"}>
      <TextInput
        placeholder={`Idea ${index + 1}`}
        refObject={inputQuestionRef}
        value={title}
        disabled={disabled}
        onChange={handleChangeTitle}
        onBlur={() => (title.length ? saveNewIdeas() : {})}
      />

      <button
        className="idea-flow-idea--btn"
        disabled={disabled}
        onClick={() => addIda(index + 1)}
      >
        <Plus />
      </button>

      <button
        className="idea-flow-idea--btn"
        onClick={() => removeIda(idea.id)}
        disabled={disabled}
      >
        <Minus />
      </button>
    </div>
  );
};

export default IdeationSetupIdea;
