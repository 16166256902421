export const TEAMSVIEW_GET_FILTERS_QUERY = `query getOrCreateFilter($type: String!){
  getOrCreateFilter(type: $type){
    id
    userId
    userEmail
    workforceID
    type
    value
  }
}`;
